import "./TopNavbar.css";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  HomeOutlined,
  HomeTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Space, message } from "antd";
import "antd/dist/antd.min.css";
import { Link, useNavigate } from "react-router-dom";
import history from "../../../utils/history";
// import HomePage from "../../afterLogin/HomePage";
import IconsNotification from "../../IconsNotification/IconsNotification";
import { MenuFoldOutlined } from "@ant-design/icons";
import Expand from "../../expand";
import { useEffect, useState } from "react";
import { MdWavingHand } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { selectorUserDetail } from "../../../redux-core/userManagement/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { userDetails } from "../../../redux-core/userManagement/actions";
import UserModal from "./UserModal";
import { MdLogout } from "react-icons/md";
import { FaInfo } from "react-icons/fa";
import {
  Collapse,
  Dropdown,
  Tooltip,
  Ripple,
  initTE,
  Modal,
} from "tw-elements";
import { TiHomeOutline } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import { Showlogout } from "../../popupmodal";
import { IoIosCall } from "react-icons/io";
import UserManual from "../../modal/UserManual";

// import { Showlogout } from "../popupmodal";
const { Header } = Layout;

const actionDispatch = (dispatch) => ({
  getUsers: (orgId) => dispatch(userDetails(orgId)),
});

function TopNavbar(props) {
  const { getUsers } = actionDispatch(useDispatch());
  const getUserList = useSelector(selectorUserDetail);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userID = logindetails.organisation.userID;
  useEffect(() => {
    initTE({ Collapse, Dropdown, Tooltip, Ripple, Modal });
    console.log(getUsers);
    console.log(getUserList);
    console.log(logindetails);
    console.log(orgId);
    console.log(userID);
  }, []);

  const [madalVisible, setModalVisible] = useState(false);
  let navigate = useNavigate();
  const homePageHandler = () => {
    navigate("/home");
    history.push("/home");
  };
  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <IconsNotification />
      </Menu.Item>
    </Menu>
  );

  const menuu = (
    <Menu>
      <Menu.Item key={2}>
        <UserModal />
      </Menu.Item>
    </Menu>
  );

  // const showUserProfileModal = async () => {
  //   // setModalVisible(true);
  //  await getUsers(orgId);
  // };

  // const handleUserProfileCancel = () => {
  //   setModalVisible(false);
  // };

  // logout logic

  const handleLogout = () => {
    Showlogout({ onConfirm: () => onConfirm([]) });
  };

  const onConfirm = () => {
    onLogOut();
  };

  const onLogOut = () => {
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("refreshToken");
    navigate("/login");
    history.push("/login");
    document.location.reload(true);
    message.success("Logout Succesfull");
  };
  return (
    <nav className="flex-no-wrap relative h-[90px] flex w-full items-center justify-between bg-[#F5F5F5] py-2  w-fit   lg:flex-wrap lg:justify-start lg:py-4">
      <div className="flex w-full flex-wrap items-center justify-between px-3">
        {/* <!-- Collapsible navigation container --> */}
        <div
          className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
          id="navbarSupportedContent12"
          data-te-collapse-item
        >
          {/* <!-- Left navigation links --> */}
          <div className=" flex flex-col ">
            <div className=" font-montserrat text-xl font-semibold flex text-black ">
              Welcome {logindetails?.user?.name}{" "}
              <span>
                <MdWavingHand className=" ml-2 text-[#FEDB5B]" size={22} />
              </span>
            </div>
            <div className=" font-montserrat text-[#051A1A] text-sm ">
              Here’s what’s happening with your store today.
            </div>
          </div>
        </div>

        {/* <!-- Right elements --> */}
        <div className="relative   w-fit mr-10   px-3 py-2 flex items-center">
          {/* ..... */}

          {/* ..... */}
          <a
            href="mailto:abc@gmail.com"
            className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
          >
            <FaInfo
              size={22}
              className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
            />
          </a>
          <a
            href="mailto:abc@gmail.com"
            className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
          >
            <IoIosMail
              size={25}
              className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
            />
          </a>
          <a
            href="tel:+54685575"
            className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
          >
            <IoIosCall
              size={25}
              className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
            />
          </a>
          <Link to={"/"}>
            <div className="p-2 rounded-full w-fit bg-white flex justify-center items-center">
              <TiHomeOutline
                size={25}
                className="w-fit text-richblack-400 hover:bg-top_nav_blue-5 hover:text-richblack-50 rounded-md cursor-pointer"
              />
            </div>
          </Link>

          

          {/* <Link */}
          {/* // className="h-[50px] w-[50px] rounded-full  bg-white p-2 rounded-full  flex justify-center items-center "
            to={"/"}
          > */}

          {/* </Link> */}

          {/* <!-- Container with two dropdown menus --> */}
          <div
            className="relative "
            data-te-dropdown-ref
            data-te-dropdown-alignment="end"
          >
            {/* <!-- First dropdown trigger --> */}
            <a
              className="hidden-arrow mr-4 text-white flex items-center rounded-md text-secondary-500 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-secondary-400 disabled:text-black/30 motion-reduce:transition-none"
              href="#"
              id="dropdownMenuButton1"
              role="button"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
            >
              {/* <!-- Dropdown trigger icon --> */}
              <span className="[&gt;svg]:w-5 flex items-center justify-center">
                <div className=" p-2 bg-white rounded-full">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 hover:bg-top_nav_blue-5 hover:text-richblack-50 rounded-md text-richblack-400 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </span>
              {/* <!-- Notification counter --> */}
              <span className="absolute -mt-4 ml-2.5 rounded-full bg-danger px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white">
                10
              </span>
            </a>

            {/* <!-- First dropdown menu --> */}
            <ul
              className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base  dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
              aria-labelledby="dropdownMenuButton1"
              data-te-dropdown-menu-ref
            >
              {/* <!-- First dropdown menu items --> */}
              <li>
                <a
                  className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  href="#"
                  data-te-dropdown-item-ref
                >
                  Action
                </a>
              </li>
              <li>
                <a
                  className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  href="#"
                  data-te-dropdown-item-ref
                >
                  Another action
                </a>
              </li>
              <li>
                <a
                  className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  href="#"
                  data-te-dropdown-item-ref
                >
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          {/* <!-- Second dropdown container --> */}
          <div
            className="relative  "
            data-te-dropdown-ref
            data-te-dropdown-alignment="end"
          >
            {/* <!-- Second dropdown trigger --> */}
            <a
              className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
              href="#"
              id="dropdownMenuButton2"
              role="button"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
            >
              {/* <!-- User avatar --> */}

              <a
                href="#"
                class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip"
                data-te-html="true"
                data-te-ripple-init
                data-te-ripple-color="light"
                title={`<div style="text-align: left;">ORG Name: ${logindetails?.organisation?.orgName}</div><div style="text-align: left;">Name: ${logindetails?.user?.name}</div><div style="text-align: left;">Email: ${logindetails?.user?.email}</div><div style="text-align: left;">Phone: ${logindetails?.user?.phone}</div><div style="text-align: left;">Role: ${logindetails?.user?.role}</div>`}
              ></a>
            </a>
          </div>
          <div class="inline-block h-[45px]  w-[1px] self-stretch bg-richblack-200 opacity-100 dark:opacity-50"></div>
          <div
            className="relative "
            data-te-dropdown-ref
            data-te-dropdown-alignment="end"
          >
            {/* <!-- First dropdown trigger --> */}
            <a
              className="hidden-arrow mr-4 ml-3 text-white flex items-center rounded-md text-secondary-500 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-secondary-400 disabled:text-black/30 motion-reduce:transition-none"
              href="#"
              id="dropdownMenuButton1"
              role="button"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
            >
              {/* <!-- Dropdown trigger icon --> */}

              {/* <img
                src={`https://api.dicebear.com/5.x/initials/svg?seed=${logindetails?.user?.name}`}
                className="rounded-full  border-richblack-5 border"
                style={{ height: "40px", width: "40px" }}
                alt=""
                loading="lazy"
              /> */}
              <div
                className="rounded-full bg-top_nav_blue-500 text-white items-center justify-center flex font-semibold text-xl mb-1  border-richblack-5 border"
                style={{ height: "40px", width: "40px" }}
              >
                {logindetails?.user?.name
                  .split(" ")
                  .map((name) => name[0])
                  .join("")
                  .toUpperCase()
                  .substring(0, 2)}
              </div>
            </a>
            {/* <!-- First dropdown menu --> */}
            <ul
              className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-2l border-none bg-white bg-clip-padding text-left text-base  dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
              aria-labelledby="dropdownMenuButton1"
              data-te-dropdown-menu-ref
            >
              {/* <!-- First dropdown menu items --> */}
              <li>
                <a
                  className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  href="#"
                  data-te-dropdown-item-ref
                >
                  <button
                    onClick={handleLogout}
                    type="button"
                    class="inline-block rounded-full border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                    data-te-ripple-init
                  >
                    Logout
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="h-[1.5px]  mt-2  w-full flex justify-center items-center  bg-richblack-200 border-0 "></hr>
    </nav>
  );
}

export default TopNavbar;

// {/* <Header className="site-layout-background" style={{ padding: 0 }}>
// <div className="main-div">
//   <div
//     className="menuicon"
//     style={{ display: "inline", float: "left" }}
//   >
//     {props.collapsed ? (
//       <>
//         <Space>
//           {/* <Tooltip title="Minimize"> */}
//           <MenuFoldOutlined onClick={props.toggle} />
//           {/* </Tooltip> */}
//           <span
//             style={{ fontSize: "13px", cursor: "pointer" }}
//             onClick={props.toggle}
//           >
//             Minimize
//           </span>
//         </Space>
//         &emsp;
//       </>
//     ) : (
//       ""
//     )}
//     <div
//       className={props.collapsed ? "" : "expand"}
//       style={{ display: "inline" }}
//     >
//       <Expand />
//     </div>
//   </div>
//   <span className="title__settings">
//     {props.name === "QCC"
//       ? "Quality Control Checklist"
//       : props.name === "Drawings"
//       ? "Documents"
//       : props.name === "Risk Management"
//       ? "Progress"
//       : props.name}
//   </span>

//   <div
//     className="home-app"
//     style={{ display: "inline", float: "right" }}
//   >
//     {logindetails.user.role === "SUPERADMIN" ? (
//       <>
//         <Tooltip title="Home">
//           <HomeOutlined onClick={homePageHandler} />
//         </Tooltip>
//       </>
//     ) : (
//       <>
//         <Dropdown overlay={menuu} overlayStyle={{ width: 310 }}>
//           <UserOutlined style={{ cursor: "pointer" }} />
//         </Dropdown>
//         <Tooltip title="Home">
//           <HomeOutlined onClick={homePageHandler} />
//         </Tooltip>

//         <Dropdown overlay={menu} overlayStyle={{ width: 250 }}>
//           <AppstoreOutlined style={{ cursor: "pointer" }} />
//         </Dropdown>
//       </>
//     )}
//   </div>
// </div>
// </Header> */}
