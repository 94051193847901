import { Image, Space, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectorGrnItemsRecRejQty } from "../../../../redux-core/materials/selectors";

function ShowPoNumberWiseItemsForm() {
  const getGrnitemsRcvRejQty = useSelector(selectorGrnItemsRecRejQty);

  const data = [];
  for (let i = 0; i < getGrnitemsRcvRejQty?.length; i++) {
    data.push({
      id: getGrnitemsRcvRejQty[i].id,
      sl_no: getGrnitemsRcvRejQty[i].sl_no,
      item_code: getGrnitemsRcvRejQty[i].item_code,
      material_name: getGrnitemsRcvRejQty[i].material_name,
      hsn_code: getGrnitemsRcvRejQty[i].hsn_code,
      rcv_qty: getGrnitemsRcvRejQty[i].rcv_qty,
      quality_check_image: getGrnitemsRcvRejQty[i].quality_check_image,
      accept_qty: getGrnitemsRcvRejQty[i].accept_qty,
      reject_qty: getGrnitemsRcvRejQty[i].reject_qty,
    });
  }

  const columns = [
    {
      title: "ID.",
      dataIndex: "id",
    },
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },

    {
      title: "Item No.",
      dataIndex: "item_code",
    },
    {
      title: "Material",
      dataIndex: "material_name",
    },
    {
      title: "HSN Code",
      dataIndex: "hsn_code",
    },

    {
      title: "Rcv Qty",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quality Inspection Record Document",
      dataIndex: "quality_check_image",
      render: (photo) => (
        <Space size="middle">
          <Image
            src={photo}
            alt="Quality Inspection Photo"
            className="checklist-image"
          />
        </Space>
      ),
    },
    {
      title: "Accepted Quantity",
      dataIndex: "accept_qty",
    },
    {
      title: "Rejected Quantity",
      dataIndex: "reject_qty",
    },
  ];
  return (
    <>
      <Table
        className="custom-table"
        columns={columns}
        pagination={false}
        dataSource={data}
      />
    </>
  );
}

export default ShowPoNumberWiseItemsForm;
