import React, { useState } from "react";
import { Table } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector } from "react-redux";
import { selectorGetSiteTransferItems } from "../../../../redux-core/materials/selectors";

function ViewTransferItemsFroms() {
  const GetSiteTransferItem = useSelector(selectorGetSiteTransferItems);

  const data = [];

  for (let i = 0; i < GetSiteTransferItem.length; i++) {
    data.push({
      // id:GetSiteTransferItem[i].id,
      id: i + 1,
      Item_name: GetSiteTransferItem[i].Item_name,
      item_code: GetSiteTransferItem[i].item_code,
      current_qty: GetSiteTransferItem[i].current_qty,
      transfer_qty: GetSiteTransferItem[i].transfer_qty,
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Name.",
      dataIndex: "Item_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "This Time Availble Qty",
      dataIndex: "current_qty",
    },
    {
      title: "Transfer Qty",
      dataIndex: "transfer_qty",
    },
  ];

  return (
    <>
      <div className="add-file">
        <Table
          scroll={{ x: "70vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      </div>
    </>
  );
}

export default ViewTransferItemsFroms;
