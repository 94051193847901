import { Button, Modal, Table } from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectorGetAllIssueData } from "../../../../redux-core/materials/selectors";
import { getIssueNumberWiseIssueReport } from "../../../../redux-core/materials/actions";
import ShowItemIssueReportForm from "./ShowItemIssueReportForm";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getIssueNumberWiseIssueReport: (issueNumber) =>
    dispatch(getIssueNumberWiseIssueReport(issueNumber)),
});

function ShowIssueReportForm() {
  const { getIssueNumberWiseIssueReport } = actionDispatch(useDispatch());
  const getAllIssueData = useSelector(selectorGetAllIssueData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tableData = [];
  let issueData = {};

  for (let i = 0; i < getAllIssueData.length; i++) {
    const currentIssue = getAllIssueData[i];
    const issueNumber = currentIssue.issue_number;
    const recieversName = currentIssue.reciever_name;
    // let dueDate = new Date(currentIssue.issue_date);
    // dueDate.setDate(dueDate.getDate() + 1);

    let dueDate = new Date(currentIssue.issue_date);
    const timeZoneOffset = dueDate.getTimezoneOffset(); // Get the time zone offset in minutes
    dueDate.setMinutes(dueDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    const formattedDate = dueDate.toISOString().slice(0, 10);

    if (issueData.hasOwnProperty(issueNumber)) {
      // If the issue already exists in issueData, update the receiversName if it's not empty.
      if (recieversName) {
        issueData[issueNumber].reciever_name = recieversName;
      }
    } else {
      // If the issue does not exist in issueData, create a new entry.
      issueData[issueNumber] = {
        id: currentIssue.id,
        issue_number: issueNumber,
        issued_agency_name: currentIssue.issued_agency_name,
        reciever_name: currentIssue.reciever_name,
        issue_date:moment(currentIssue.issue_date).format("DD-MM-YYYY") ,
      };
    }
  }

  // Now, tableData will have unique issues based on the issue_number field.
  for (const issueNumber in issueData) {
    tableData.push(issueData[issueNumber]);
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Issue No. Wise report",
      dataIndex: "issue_number",
      render: (record) => {
        return (
          <Button
            className="button-61"
            onClick={() => HandelCreateIssueReportIssueNumberWise(record)}
          >
            Item Issue Statement
          </Button>
        );
      },
    },
    {
      title: "Issue Number",
      dataIndex: "issue_number",
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
    },
    {
      title: "Item issued to (Agency Name)",
      dataIndex: "issued_agency_name",
    },
    {
      title: "Recievers Name",
      dataIndex: "reciever_name",
    },
  ];
  const HandelCreateIssueReportIssueNumberWise = (value) => {
    getIssueNumberWiseIssueReport(value);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Table
        scroll={{ x: "100vw" }}
        columns={columns}
        pagination={10}
        dataSource={tableData}
      />
      <Modal
        visible={isModalOpen}
        footer={null}
        onCancel={closeModal}
        width={1000}
      >
        <ShowItemIssueReportForm />
      </Modal>
    </>
  );
}

export default ShowIssueReportForm;
