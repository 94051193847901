/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  delectRequestForInspection,
  findStageDetailById,
  findTaskDetailById,
  getAllActivities,
  getRequestForInspection,
  updateRequestForInspection,
} from "../../../../redux-core/qcc/actions";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  selectorActivityDetail,
  selectorGateRequestForInspection,
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../../redux-core/qcc/selectors";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { ShowDeleteConfirm } from "../../../popupmodal";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import {
  getAllAssignProjectId,
  userDetails,
} from "../../../../redux-core/userManagement/actions";
import { getProjectIdByUsers } from "../../../../redux-core/risk/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import {
  selectorGetAllUserWiseAssignProjects,
  selectorUserDetail,
} from "../../../../redux-core/userManagement/selectors";
import { selectorUsersProjectIdWiseState } from "../../../../redux-core/risk/selectors";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getRequestForInspection: (orgId) => dispatch(getRequestForInspection(orgId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getProjectIdByUsers: (projectId, orgID) =>
    dispatch(getProjectIdByUsers(projectId, orgID)),
  getActivities: (orgId) => dispatch(getAllActivities(orgId)),
  updateRequestForInspection: (updateData) =>
    dispatch(updateRequestForInspection(updateData)),
  delectRequestForInspection: (delectid) =>
    dispatch(delectRequestForInspection(delectid)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
});
const { Option } = Select;
function ShowRfiTable() {
  const {
    getRequestForInspection,
    getAllProj,
    getAllProjDetails,
    getAllAssignProjectId,
    getProjectIdByUsers,
    getActivities,
    updateRequestForInspection,
    delectRequestForInspection,
    getUsers,
    taskById,
    getStageById
  } = actionDispatch(useDispatch());
  const loginDetails = useSelector(makeSelectLoginDetail);
  const getAllRequestForInpection = useSelector(
    selectorGateRequestForInspection
  );
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [keys, setKeys] = useState([]);
  const [activedata, setActivedata] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [form] = Form.useForm();
  const allProjects = useSelector(makeSelectAllProj);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [selectedProjectId, setSelectedProjectId] = useState();
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const getUserProjectWise = useSelector(selectorUsersProjectIdWiseState);
  const allActvityData = useSelector(selectorActivityDetail);
  const [selectDate, setSelectDate] = useState();
  const [projectDetailId, setProjectDetailId] = useState();
  const [qcEngineerId, setQcEnginnerId] = useState();
  const [taskId, setTaskId] = useState();
  const [stageId,setStageId]=useState()
  const [rowId, setRowId] = useState();
  const allUsersData = useSelector(selectorUserDetail);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);

console.log("selectDate",selectDate)
  const qcEngineerList = allUsersData.filter(
    (item) => item.role === "QCENGINEER"
  );

  const qcEngineerUser = getUserProjectWise.filter((qcUser) =>
    qcEngineerList.some((engineer) => engineer.admin_id == qcUser.user_id)
  );

  useEffect(() => {
    getRequestForInspection(loginDetails.organisation.orgID);
    getAllProj(loginDetails.organisation.orgID, loginDetails.user.id);
    getAllAssignProjectId(
      loginDetails.organisation.orgID,
      loginDetails.user.id
    );
    getActivities(loginDetails.organisation.orgID);
    getUsers(loginDetails.organisation.orgID);
    taskById(loginDetails.organisation.orgID);
  }, []);

  // push project id
  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  //user id wise assign project
  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  // search function in select dropdown
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(
      projectId,
      loginDetails.organisation.orgID,
      loginDetails.user.id
    );
    getProjectIdByUsers(projectId, loginDetails.organisation.orgID);
  };

console.log("getAllRequestForInpection",getAllRequestForInpection)
  const Data = [];

  for (let i = 0; i < getAllRequestForInpection.length; i++) {
    // const RFIDate = new Date(getAllRequestForInpection[i]?.rfi_date);
    // const timeZoneOffsets = RFIDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // RFIDate.setMinutes(RFIDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedDate = RFIDate.toISOString().slice(0, 10);
    Data.push({
      sl_no: i + 1,
      id: getAllRequestForInpection[i]?.id,
      rfi_code: getAllRequestForInpection[i]?.rfi_code,
      rfi_date: moment(getAllRequestForInpection[i]?.rfi_date).format("DD-MM-YYYY"),
      project_name: getAllRequestForInpection[i]?.project_name,
      project_id: getAllRequestForInpection[i]?.project_id,
      block: getAllRequestForInpection[i]?.block,
      project_detail_id: getAllRequestForInpection[i]?.project_detail_id,
      name: getAllRequestForInpection[i]?.name,
      qc_engineer: getAllRequestForInpection[i]?.qc_engineer,
      description: getAllRequestForInpection[i]?.description,
      drawing_file: getAllRequestForInpection[i]?.drawing_file,
      task_id: getAllRequestForInpection[i]?.task_id,
      task: getAllRequestForInpection[i]?.task,
      stage_id: getAllRequestForInpection[i]?.stage_id,
      stage_name: getAllRequestForInpection[i]?.stage_name,
     
    
    });
  }

  const colunm = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },
    {
      title: "RFI Code",
      dataIndex: "rfi_code",
    },
    {
      title: "RFI Date",
      dataIndex: "rfi_date",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
    },
    {
      title: "Project Location",
      dataIndex: "block",
    },
    {
      title: "QC Engineer",
      dataIndex: "name",
    },
    {
      title: "Task",
      dataIndex: "task",
    },
    {
      title: "Stage",
      dataIndex: "stage_name",
    },
    {
      title: "Drawing",
      dataIndex: "drawing_file",
      render: (text, record) => (
        <>
          <Space size="middle">
            <Button onClick={() => HandelView(record.drawing_file)}>
              <EyeOutlined />
            </Button>
          </Space>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Edit">
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            </Tooltip>
            <Tooltip title="Delect">
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];


const HandelObserveModal=(value)=>{
  console.log("value",value)

}


  // drawing view modal open
  const HandelView = (value) => {
    setSelectedDrawing(value);
    setIsOpenModal(true);
  };
   

  
  // modal close function
  const handelCancel = () => {
    setIsOpenModal(false);
    setShowEditModal(false);
  };

  // Edit modal open Function
  const showModal = (data1) => {
    getStageById(loginDetails.organisation.orgID,data1.task_id)
    console.log("first",data1)
    getProjectIdByUsers(data1.project_id, loginDetails.organisation.orgID);
    setShowEditModal(true);
    setSelectDate(moment(data1.rfi_date));
    setProjectDetailId(data1.project_detail_id);
    setQcEnginnerId(data1.qc_engineer);
    setTaskId(data1.task_id);
    setStageId(data1.stage_id);
    setRowId(data1.id);
    form.setFieldsValue({
      project_id: data1.project_id,
      project_detail_id: data1.block,
      qc_engineer: data1.name,
      rfi_date: moment(data1.rfi_date,"DD-MM-YYYY"),
      description: data1.description,
      rfi_code: data1.rfi_code,
      task_id: data1.task,
      stage_id: data1.stage_name,
    });
  };

  // delect dunction
  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.id]) });
  };
  const onConfirm = async (key) => {
    delectRequestForInspection(key);
    getRequestForInspection(loginDetails.organisation.orgID);
  };

  // bulk delect function
  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  //row select value
  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };
  // for delect check box select
  const rowSelection = {
    selectedRowKeys: keys,
    onChange: onSelectChange,
  };

  //table pagination
  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };


  // edit form submit function
  const handelEditFromSubmit = async (value) => {
    const updateData = {
      id: rowId,
      project_id: value.project_id,
      project_detail_id: projectDetailId,
      qc_engineer: qcEngineerId,
      task_id: taskId,
      stage_id: stageId,
      rfi_date: moment(value.rfi_date).format("YYYY-MM-DD"),
      description: value.description,
    };
    let response = await updateRequestForInspection(updateData);
    console.log("updateData",updateData)
    console.log("response",response)
    if (response) {
      getRequestForInspection(loginDetails.organisation.orgID);
      setShowEditModal(false);
    }
  };

  const HandelDateSelect = (value, dateString) => {
    setSelectDate(dateString,"DD-MM-YYYY");
  };

  // Handel select task for stage found
  const HandelSelectTask=(value)=>{
    getStageById(loginDetails.organisation.orgID,value)
    setTaskId(value);
  }

  // handel stage function

  const HadelSelectStage=(value)=>{
    console.log("value",value)
    setStageId(value)
  }



  return (
    <>
      <div className="title-show-project">View Of Request for Inspection</div>
      <Table
        columns={colunm}
        scroll={{ x: "90vw" }}
        dataSource={Data}
        rowSelection={rowSelection}
        pagination={10}
        onChange={(pagination, filter, sorter, currentTable) =>
          onTableChange(filter, sorter, currentTable)
        }
        rowKey="id" // Set the rowKey to a unique identifier
      />

      <Modal
        title="Drawing Preview"
        visible={isOpenModal}
        footer={null}
        width={1500}
        onCancel={handelCancel}
      >
        <iframe
          className={"application/pdf"}
          width="100%"
          height="700"
          frameborder="10"
          src={selectedDrawing}
        />
      </Modal>
      <Modal
        title="Edit Request for Inspection"
        visible={showEditModal}
        footer={null}
        width={900}
        onCancel={handelCancel}
      >
        <Form
          name="edit_request_for_inspection"
          form={form}
          onFinish={handelEditFromSubmit}
        >
          <Form.Item name="rfi_code" label="RFI Code" className="input-field">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="project_id"
            label="Project"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select Project Name !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              onChange={(projectId) => handelProjectId(projectId)}
              placeholder="Search or select Project"
            >
              {loginDetails.user.role === "ADMIN"
                ? allProjects.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))
                : projectData.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="project_detail_id"
            label="Project Location"
            rules={[
              {
                required: true,
                message: "Please Select  Project Location !",
              },
            ]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select Project Location"
              onChange={(project_details_id) =>
                setProjectDetailId(project_details_id)
              }
            >
              {allProjDetails.map((getAllProjDetails) => (
                <Option
                  key={getAllProjDetails.project_details_id}
                  value={getAllProjDetails.project_details_id}
                >
                  {getAllProjDetails.block}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="qc_engineer"
            label="QC Engineer"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select QC Engineer !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select QC Engineer"
              onChange={(user_id) => setQcEnginnerId(user_id)}
            >
              {qcEngineerUser.map((item) => (
                <Option key={item.user_id} value={item.user_id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
              name="task_id"
              label="Task"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Task !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Task"
                onChange={HandelSelectTask}
              >
                {taskDetailsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.task}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="stage_id"
              label="Stage"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select Stage  !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Stage"
                onChange={HadelSelectStage}
              >
                {stageDetailsData.map((item) => (
                  <Option key={item.stage_id} value={item.stage_id}>
                    {item.stage_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          {/* <Form.Item
            name="activity_id"
            label="Activity"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select Activity !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select Activity"
              onChange={(parameter_id) => setActivityId(parameter_id)}
            >
              {allActvityData.map((item) => (
                <Option key={item.parameter_id} value={item.parameter_id}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="rfi_date"
            label="Date"
            className="firstRow1"
            rules={[
              {
                required: true,
                message: "Please Select Date !",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" onChange={HandelDateSelect} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            className="input-field"
            rules={[
              {
                pattern: /^(.{0,300})$/,
                message: "please enter less than 300 charactersonly",
              },
            ]}
          >
            <Input.TextArea
              style={{ maxHeight: "130px", minHeight: "100px" }}
            />
          </Form.Item>
          <Form.Item>
            <div className="btn-ehs">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <br></br>
      <br />
      {getAllRequestForInpection.length > 0 && (
        <Button
          style={{ display: activedata ? "" : "none" }}
          className="deleteallbutton copy"
          onClick={deleteSelected}
        >
          Delete All Selected
        </Button>
      )}
    </>
  );
}

export default ShowRfiTable;
