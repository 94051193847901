import React, { useCallback, useEffect, useState } from 'react'
import { Spin, Button, Card, Image  } from "antd";
import ListShowIndent from './Forms/ListShowIndent';
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import { makeSelectAllProj } from '../../../redux-core/settings/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectorGetAllUserWiseAssignProjects } from '../../../redux-core/userManagement/selectors';
import { getAllProj, getProjectByprojectId } from '../../../redux-core/settings/actions';
import { getAllAssignProjectId } from '../../../redux-core/userManagement/actions';

const actionDispatch= (dispatch) =>({
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getProjectByprojectId: (projectID) =>
   dispatch(getProjectByprojectId(projectID)),
   getAllAssignProjectId: (orgId, userID) =>
   dispatch(getAllAssignProjectId(orgId, userID)),
})

const ShowIndent = () => {
  const {  
    getProjectByprojectId,
    projects,
    getAllAssignProjectId,

  } = actionDispatch(useDispatch());
  const [content, setContent] = useState("1");
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
 const userId = logindetails.user.id;
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects)
  const [projectId, setProjectId] = useState();

  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(orgId, userId);
  }, [projectId]);


  const onAddPurchaseBtn = useCallback((projectID) => {
    setContent("2");
    setProjectId(projectID);
    getProjectByprojectId(projectID);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  const projectData = ProjID.flatMap((item) => {
    return allProjectsSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

  const backProject=()=>{
    setContent("1")
  }

  return(
    <>
    {logindetails.user.role === "ADMIN" ? (
      <span className="card-container">
        {content === "1"
          ? allProjectsSelectorData.map((data) => {
              return (
                <Card
                  className="projectCard"
                  // hoverable
                  style={{ width: 250, borderRadius: "5%" }}
                  key={data.project_id}
                  onClick={() => onAddPurchaseBtn(data.project_id)}
                >
                  <div className="sub-main">
                    <div className="top">
                      <span className="left__value">
                        <b>Project Name: </b>
                        {data.project_name}
                      </span>
                      <span className="right__icon">
                        <Image width={50} src={data.image} alt="No Image" />
                      </span>
                    </div>
                    <div className="bottom__title">
                      <b>Description: </b>
                      {data.description}
                    </div>
                  </div>
                </Card>
              );
            })
          : ""}
      </span>
    ) : (
      <span className="card-container">
        {content === "1"
          ? projectData.map((data) => {
              return (
                <Card
                  className="projectCard"
                  // hoverable
                  style={{ width: 250, borderRadius: "5%" }}
                  key={data.project_id}
                  onClick={() => onAddPurchaseBtn(data.project_id)}
                >
                  <div className="sub-main">
                    <div className="top">
                      <span className="left__value">
                        <b>Project Name: </b>
                        {data.project_name}
                      </span>
                      <span className="right__icon">
                        <Image width={50} src={data.image} alt="No Image" />
                      </span>
                    </div>
                    <div className="bottom__title">
                      <b>Description: </b>
                      {data.description}
                    </div>
                    {/* <div className="work_order">
                      <Button onClick={()=>onAddPurchaseBtn(data.project_id)} className="work_order_btn">Material Receipt</Button>
                   </div> */}
                  </div>
                </Card>
              );
            })
          : ""}
      </span>
    )}
 
    {content === "2" ? <ListShowIndent projectID={projectId}  backProject={backProject}/> : ""}
  </>
  )
}

export default ShowIndent