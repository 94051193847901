import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Table } from "antd";
import "../css/WithOutPoInvoceCreateFrom.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  makeSelectAllProj,
  selectorGetProjByProjId,
} from "../../../../redux-core/settings/selectors";
import {
  getCurrentStockProjectIdWise,
  createSiteTransfer,
  insertSiteTransferItemsInStock,
  insertSiteTransferItems,
  getAllSiteTransferData,
  updateStockInSiteTransferQty,
  getCountTarnsferNumber,
} from "../../../../redux-core/materials/actions";
import {
  selectorGetItemInCurrentStockProjectWise,
  selectorCountTransferNumber,
} from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getCurrentStockProjectIdWise: (projeID) =>
    dispatch(getCurrentStockProjectIdWise(projeID)),
  getAllSiteTransferData: (projeID) =>
    dispatch(getAllSiteTransferData(projeID)),
  createSiteTransfer: (transferData) =>
    dispatch(createSiteTransfer(transferData)),
  insertSiteTransferItems: (transferItems) =>
    dispatch(insertSiteTransferItems(transferItems)),
  insertSiteTransferItemsInStock: (transferItems) =>
    dispatch(insertSiteTransferItemsInStock(transferItems)),
  updateStockInSiteTransferQty: (transferItems) =>
    dispatch(updateStockInSiteTransferQty(transferItems)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountTarnsferNumber: (projeID) =>
    dispatch(getCountTarnsferNumber(projeID)),
});

function MaterialQtySiteTransferForm(props) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const {
    getAllProject,
    getCurrentStockProjectIdWise,
    createSiteTransfer,
    insertSiteTransferItems,
    getAllSiteTransferData,
    insertSiteTransferItemsInStock,
    updateStockInSiteTransferQty,
    getProjectByprojectId,
    getCountTarnsferNumber,
  } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCurrentProj, setsSlectedCurrentProj] = useState(null);
  const [selectedTransferProj, setSelectedTransferProj] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState();
  const [transferNumber, setTransferNumber] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countTransferNumber = useSelector(selectorCountTransferNumber);

  useEffect(() => {
    getAllProject(orgId, userId);
    getCurrentStockProjectIdWise(props.projectID);
    getProjectByprojectId(props.projectID);
    getCountTarnsferNumber(props.projectID);
  }, []);

  //--------write the functionality for give search option in select dropdown---------------------
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // ----------write here date picker Function for string to nemerial convert ------------
  const handleTransferDate = (value, dateString) => {
    //setSelectedDate(dateString);
  };
  //-------------------Handel submit action write here for form 1-----------------
  const onProjectSelect = async (value) => {
    setsSlectedCurrentProj(value.current_project_id);
    setSelectedTransferProj(value.transfer_project_id);
    setTransferNumber(value.transfer_number);
    setSelectedDate(value.transfer_date)
    const transferProject = {
      current_project_id: value.current_project_id,
      transfer_project_id: value.transfer_project_id,
      transfer_date: value.transfer_date,
      transfer_number: value.transfer_number,
      orgID: orgId,
      created_by: userId,
    };
    let response = await createSiteTransfer(transferProject);
    if (response) {
      if (response.success === true) {
        setIsDisable(true);
        setShowItems(true);
        getAllSiteTransferData(props.projectID);
      } else {
        setIsDisable(false);
        setShowItems(false);
      }
    }
  };
  const itemCode = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.item_code);

  const itemQty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.total_qty);

  const transferQty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.transfer_to_other_site);

  const itemName = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.Item_name);

  const itemId = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.id);

  const item_rate = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.item_rate);

  const transfer_value = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.transfer_value);

  // avarage of select item value
  const avarageValue = Number(item_rate?.[0]) / Number(itemQty[0]);

  //-------UseEffect use for input initialvalue
  useEffect(() => {
    form2.setFieldsValue({
      item_code: itemCode[0],
      total_qty: itemQty[0],
      transfer_number: transferNumber,
    });
  }, [itemCode[0], itemQty[0], transferNumber]);

  //-----------Handel Submit action write here for form 2-------------------
  const HandelSiteTransferItem = async (value) => {
    let transferItemValue = Number(avarageValue) * Number(value.transfer_qty);

    const transferItem = {
      current_project_id: selectedCurrentProj,
      transfer_project_id: selectedTransferProj,
      transfer_date: selectedDate,
      item_id: value.item_id,
      item_code: value.item_code,
      current_qty: value.total_qty,
      transfer_qty: value.transfer_qty,
      remark: value.remark,
      orgID: orgId,
      created_by: userId,
      transfer_number: transferNumber,
      item_rate: transferItemValue,
    };
    const response = await insertSiteTransferItems(transferItem);

    const Items = {
      item_code: itemCode[0],
      Item_name: itemName[0],
      project_id: selectedTransferProj,
    };

    const updateItems = {
      id: itemId[0],
      total_qty: Number(itemQty[0]) - Number(value.transfer_qty),
      transfer_to_other_site:
        Number(transferQty[0]) + Number(value.transfer_qty),
      item_rate: Number(item_rate?.[0]) - Number(transferItemValue),
      transfer_value: Number(transfer_value?.[0]) + Number(transferItemValue),
    };
    if (response) {
      if (response.success === true) {
        await insertSiteTransferItemsInStock(Items);
        await updateStockInSiteTransferQty(updateItems);
      }
    }
  };

  //reset all Input Field
  const resetAllValue = () => {
    form.resetFields();
    form2.resetFields();

    setIsDisable(false);
    setShowItems(false);
    getCountTarnsferNumber(props.projectID);
  };

  let count_number =
    countTransferNumber[0].no_of_Transfer_no === 0
      ? "001"
      : (Number(countTransferNumber[0].no_of_Transfer_no) + 1).toString().padStart(3, '0');
  const TRANSFER_NUMBER =
    "IST/" + getProjectByProjId[0].project_code + "/" + count_number;

  useEffect(() => {
    form.setFieldsValue({
      transfer_number: TRANSFER_NUMBER,
    });
  }, [TRANSFER_NUMBER]);

  return (
    <>
      <div className="add-file">
        <Form
          name="site_transfer"
          form={form}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onProjectSelect}
        >
          <Form.Item
            name="current_project_id"
            label="Current Project"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              // onChange={(itemID) => setSelectedItemId(itemID)}
              // filterOption={handleSearch}
              disabled={isDisable}
            >
              {allProjects
                .filter((item) => item.project_id === props.projectID)
                .map((item) => (
                  <Option key={item.project_id} value={item.project_id}>
                    {item.project_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="transfer_project_id"
            label="Tranfer Project"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              //  onChange={(projID) => setSelectedProjectId(projID)}
              filterOption={handleSearch}
              disabled={isDisable}
            >
              {allProjects.map((getAllProject) => (
                <Option
                  key={getAllProject.project_id}
                  value={getAllProject.project_id}
                >
                  {getAllProject.project_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="transfer_date"
            label="Transfer Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Date",
              },
            ]}
          >
            <DatePicker
              name="transfer_date"
              format="DD-MM-YYYY"
              onChange={handleTransferDate}
              placeholder="Enter Date"
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item
            name="transfer_number"
            label="Transfer Number Create"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Transfer Number Create",
              },
            ]}
            initialValue={TRANSFER_NUMBER}
          >
            <Input placeholder="Enter Transfer Number Create" disabled />
          </Form.Item>

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
                disabled={isDisable}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      {showItems && (
        <div className="add-file">
          <Form
            name="site_transfer_item"
            form={form2}
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={HandelSiteTransferItem}
          >
            <Form.Item
              name="item_id"
              label="Item Name"
              rules={[{ required: true, message: "Please select Item!" }]}
              className="input-field1"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Item Name"
                onChange={(itemID) => setSelectedItemId(itemID)}
                filterOption={handleSearch}
              >
                {getShowItemInCurrentStockProjectWise.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.Item_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="item_code"
              label="Item Code"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter item Code",
                },
              ]}
              initialValue={itemCode[0]}
            >
              <Input placeholder="Enter Item Code" disabled />
            </Form.Item>
            <Form.Item
              name="total_qty"
              label="Current QTY"
              className="input-field"
              initialValue={itemQty[0]}
            >
              <Input type="number" placeholder="Enter Item Qty" disabled />
            </Form.Item>
            <Form.Item
              name="transfer_qty"
              label="Transfer QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Transfer Item Qty",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const totalQty = getFieldValue("total_qty");
                    if (
                      !totalQty ||
                      parseFloat(value) <= parseFloat(totalQty)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Transfer Qty cannot exceed Current Qty"
                    );
                  },
                }),
              ]}
            >
              <Input type="number" placeholder="Enter Transfer Item  Qty" />
            </Form.Item>
            <Form.Item name="remark" label="Remark" className="input-field">
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
          <div className="btn-project">
            <Button
              onClick={() => resetAllValue()}
              className="btn-cancel"
              style={{ marginRight: 25 }}
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default MaterialQtySiteTransferForm;
