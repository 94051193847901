import { DatePicker, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import useSearch from "../../../hooks/useSearch";
import {
  getAllFinishDateMsProject,
  getAllProj,
  getAllStartDateMsProject,
  getAllUploadMmpFile,
} from "../../../redux-core/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorGetFinishDateWiseMsProject,
  selectorGetStartDateWiseMsProject,
  selectorGetUploadFile,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { Form, Button, Upload, Select } from "antd";
import "../contents/Forms/css/ShowMsProject.css";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getUploadMmp: (project_id, orgId) =>
    dispatch(getAllUploadMmpFile(project_id, orgId)),
  getAllStartDateMsProject: (startDate, endDate, project_id, orgId) =>
    dispatch(getAllStartDateMsProject(startDate, endDate, project_id, orgId)),
  getAllFinishDateMsProject: (startDate, endDate, project_id, orgId) =>
    dispatch(getAllFinishDateMsProject(startDate, endDate, project_id, orgId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
});

const ShowMsprojectFile = () => {
  const children = [];
  const getColumnSearchProps = useSearch();
  const {
    getUploadMmp,
    getAllStartDateMsProject,
    getAllFinishDateMsProject,
    getAllProj,
    getAllAssignProjectId,
  } = actionDispatch(useDispatch());
  const allUploadMmpFile = useSelector(selectorGetUploadFile);
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const { Option } = Select;
  const [selectStartDate, setSelectStartDate] = useState();
  const [selectFinishDate, setSelectFinishDate] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectRadioValue, setSelectRadioValue] = useState();
  const getStartDateMsProjectData = useSelector(
    selectorGetStartDateWiseMsProject
  );
  const getFinishDateMsProjectData = useSelector(
    selectorGetFinishDateWiseMsProject
  );
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );

  useEffect(() => {
    getAllProj(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

  //     console.log("projectData",projectData)
  //   console.log("allUploadMmpFile", allUploadMmpFile);
  // console.log("allProjSelectorData",allProjSelectorData)
  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }
  const handleViewMmpFile = async (value) => {
    // console.log("value", value);
    setSelectedProjectId(value);
  };

  const columns = [
    {
      title: "SL No.",
      dataIndex: "sl_no",
      //   ...getColumnSearchProps('sl_no'),
    },
    // {
    //     title: 'Project ID',
    //     dataIndex: 'project_id',
    //     ...getColumnSearchProps('project_id'),
    // },
    {
      title: "Task Name",
      dataIndex: "task_name",
      ...getColumnSearchProps("task_name"),
    },
    {
      title: "duration",
      dataIndex: "duration",
      ...getColumnSearchProps("duration"),
    },
    {
      title: "start",
      dataIndex: "start",
      ...getColumnSearchProps("start"),
    },
    {
      title: "finish",
      dataIndex: "finish",
      ...getColumnSearchProps("finish"),
      // width: '20%',
    },
    {
      title: "predecessors",
      dataIndex: "predecessors",
      ...getColumnSearchProps("predecessors"),
    },
    {
      title: "successors",
      dataIndex: "successors",
      ...getColumnSearchProps("successors"),
    },
    {
      title: "resources name",
      dataIndex: "resources_name",
      ...getColumnSearchProps("resources_name"),
    },
  ];

  let tableDataBySelected = [];

  if (selectRadioValue === "PROJECT") {
    tableDataBySelected = allUploadMmpFile;
  } else if (selectRadioValue === "START") {
    tableDataBySelected = getStartDateMsProjectData;
  } else if (selectRadioValue === "FINISH") {
    tableDataBySelected = getFinishDateMsProjectData;
  }
  console.log("tableDataBySelected--",tableDataBySelected)
  const data = [];
  for (let i = 0; i < tableDataBySelected.length; i++) {
    let startDate = new Date(tableDataBySelected[i].start);
    let finishDate = new Date(tableDataBySelected[i].finish);
    data.push({
      sl_no: i + 1,
      project_id: tableDataBySelected[i].project_id,
      task_name: tableDataBySelected[i].task_name,
      duration: tableDataBySelected[i].duration,
      start: startDate.toLocaleDateString("en-GB"),
      finish: finishDate.toLocaleDateString("en-GB"),
      predecessors: tableDataBySelected[i].predecessors,
      successors: tableDataBySelected[i].successors,
      resources_name: tableDataBySelected[i].resources_name,
    });
  }
  const HandelRadioValue = async (value) => {
    // console.log("Value", value.target.value);
    setSelectRadioValue(value.target.value);
    // console.log("first",selectStartDate,selectFinishDate,selectedProjectId)
    if (value.target.value === "PROJECT") {
      await getUploadMmp(selectedProjectId, logindetails.organisation.orgID);
    } else if (value.target.value === "START") {
      // console.log("START",selectStartDate,selectFinishDate,selectedProjectId)
      getAllStartDateMsProject(
        selectStartDate,
        selectFinishDate,
        selectedProjectId,
        logindetails.organisation.orgID
      );
    } else if (value.target.value === "FINISH") {
      // console.log("FINISH",selectStartDate,selectFinishDate,selectedProjectId)
      getAllFinishDateMsProject(
        selectStartDate,
        selectFinishDate,
        selectedProjectId,
        logindetails.organisation.orgID
      );
    }
  };

  const HandelStartDate = (value) => {
    console.log(moment(value).format("YYYY-MM-DD"))
   
    setSelectStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const HamdelFinishDate = (value) => [
    
    setSelectFinishDate(moment(value).format("YYYY-MM-DD")),
  ];

  return (
    <>
      <div className="title-show-project">Show Schedule</div>
      <br />
      <Form size="large">
        <Form.Item
          name="Project_id"
          label="Select Project"
          className="firstRow"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please Select The Project",
          //   },
          // ]}
        >
          <Select
            allowClear
            placeholder="Select Project Name"
            // disabled={isDisableInput}
            onChange={(project_id) => handleViewMmpFile(project_id)}
          >
            {logindetails.user.role === "ADMIN"
              ? allProjSelectorData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))
              : projectData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item label="From Start Date" className="secondRow">
          <DatePicker format="DD-MM-YYYY" onChange={HandelStartDate} />
        </Form.Item>
        <Form.Item label="To Start Date" className="thirdRow">
          <DatePicker format= "DD-MM-YYYY" onChange={HamdelFinishDate} />
        </Form.Item>
        <Radio.Group
          // defaultValue="c"
          onChange={HandelRadioValue}
          buttonStyle="solid"
          className="fourthRow"
        >
          <Radio.Button value="START" onClick={HandelRadioValue}>
            Start Date
          </Radio.Button>
          <Radio.Button value="FINISH" onClick={HandelRadioValue}>
            Finish Date
          </Radio.Button>
          <Radio.Button value="PROJECT" onClick={HandelRadioValue}>
            Project Wise
          </Radio.Button>
        </Radio.Group>
      </Form>

      <Table
        className="table"
        //   rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        //   onChange={(pagination, filter, sorter, currentTable) => onTableChange(filter, sorter, currentTable)}
        pagination={20}
        scroll={{ x: "100vw", y: 700 }}
      />
    </>
  );
};

export default ShowMsprojectFile;
