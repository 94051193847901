import React, { useEffect, useState } from "react";
import { Input, Button, Modal, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import {
  getIndentDetailsForm,
  getIndentForm,
  updateIndentStatus,
} from "../../../../redux-core/materials/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGetIndent,
  selectorGetIndentDetails,
} from "../../../../redux-core/materials/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const actionDispatch = (dispatch) => ({
  getIndentDetailsForm: (orgID, indent_header_id) =>
    dispatch(getIndentDetailsForm(orgID, indent_header_id)),
  getIndentForm: (orgID, project_id) =>
    dispatch(getIndentForm(orgID, project_id)),
  updateIndentStatus: (approveData) =>
    dispatch(updateIndentStatus(approveData)),
});

const ListShowIndent = (props) => {
  const { getIndentDetailsForm, getIndentForm, updateIndentStatus } =
    actionDispatch(useDispatch());

  const [buttonText, setButtonText] = useState("Check");
  const [isDisabled, setIsDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [storeRecordData, setStoreRecordData] = useState(null);
  const [isApproveClicked, setIsApproveClicked] = useState(false);
  const [buttonRemove, setButtonRemove] = useState(true);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const allSelectorGetIndent = useSelector(selectorGetIndent);
  const allSelectorGetIndentDetails = useSelector(selectorGetIndentDetails);
  // console.log("allSelectorGetIndent", allSelectorGetIndent)
  console.log("allSelectorGetIndentDetails", allSelectorGetIndentDetails);
  // console.log("props.projectID",props.projectID)
  useEffect(() => {
    getIndentForm(orgId, props.projectID);
  }, []);

  const tableData = [];

  if (logindetails.user.role === "SITEENGINEER") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      tableData.push({
        indent_header_id: allSelectorGetIndent[i].id,
        indent_no: allSelectorGetIndent[i].indent_no,
        indent_date: allSelectorGetIndent[i].indent_date.split('T')[0],
        work_order_no: allSelectorGetIndent[i].work_order_no,
        last_purchase_order_no: allSelectorGetIndent[i].last_purchase_order_no,
        last_purchase_order_date:
          allSelectorGetIndent[i].last_purchase_order_date.split('T')[0],
        is_approve: allSelectorGetIndent[i].is_approve,
      });
    }
  } else if (logindetails.user.role === "SITEINCHARGE") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      tableData.push({
        indent_header_id: allSelectorGetIndent[i].id,
        indent_no: allSelectorGetIndent[i].indent_no,
        indent_date: allSelectorGetIndent[i].indent_date.split('T')[0],
        work_order_no: allSelectorGetIndent[i].work_order_no,
        last_purchase_order_no: allSelectorGetIndent[i].last_purchase_order_no,
        last_purchase_order_date:
          allSelectorGetIndent[i].last_purchase_order_date.split('T')[0],
        is_approve: allSelectorGetIndent[i].is_approve,
      });
    }
  } else if (logindetails.user.role === "PROCUREMENTMANAGER") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      if (allSelectorGetIndent[i].is_approve === 1) {
        tableData.push({
          indent_header_id: allSelectorGetIndent[i].id,
          indent_no: allSelectorGetIndent[i].indent_no,
          indent_date: allSelectorGetIndent[i].indent_date.split('T')[0],
          work_order_no: allSelectorGetIndent[i].work_order_no,
          last_purchase_order_no:
            allSelectorGetIndent[i].last_purchase_order_no,
          last_purchase_order_date:
            allSelectorGetIndent[i].last_purchase_order_date.split('T')[0],
          is_approve: allSelectorGetIndent[i].is_approve,
        });
      }
    }
  } 
  //
// console.log("tableData", tableData)
  // Now, tableData will only be populated if the user's role is "SUPERVISOR"

  //

  const columns = [
    {
      title: "Indent No",
      dataIndex: "indent_no",
    },
    {
      title: "Date",
      dataIndex: "indent_date",
    },
    {
      title: "Work Order No",
      dataIndex: "work_order_no",
    },
    {
      title: "Last Purchase Order No",
      dataIndex: "last_purchase_order_no",
    },
    {
      title: "Last Purchase Order Date",
      dataIndex: "last_purchase_order_date",
    },
    {
      title: "Aprove Status",
      dataIndex: "is_approve",
     render: (record) => (record === 1 ? "Approved" : "Not Approved"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => viewModal(record)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },
    {
      title: "Sheet",
      dataIndex: "sheet",
    },
    {
      title: "Work Order Sheet Description",
      dataIndex: "description",
    },
    {
      title: "Work Order BOQ Item No.",
      dataIndex: "work_order_boq_item_no",
    },

    {
      title: "Item Description",
      dataIndex: "item_description",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "BOQ Quantity",
      dataIndex: "boq_quantity",
    },
    {
      title: "Item Specification",
      dataIndex: "item_specification",
    },
    {
      title: "Quantity Ordered so far",
      dataIndex: "quantity_ordered_so_far",
    },
    {
      title: "Quantity in stock",
      dataIndex: "quantity_in_stock",
    },
    {
      title: "Quantity required now",
      dataIndex: "quantity_required_now",
    },
    {
      title: "Required Date",
      dataIndex: "required_date",
    },
  ];

  const backToAllProject = () => {
    props.backProject();
  };

  const handleButtonText = () => {
    if (buttonText === "Check") {
      setButtonText("Checked");
      setIsDisabled(true);
    }
  };

  const tableData2 = [];
  for (let i = 0; i < allSelectorGetIndentDetails?.length; i++) {
    //BOQ Quantity //
    const boqQuantity = allSelectorGetIndentDetails?.[i]?.boq_quantity;
    const formattedBoqQuantity = boqQuantity === null ? "0" : boqQuantity;
// UOM //
    const uOM = allSelectorGetIndentDetails?.[i]?.uom;
    const formattedUom = uOM === null ? "0" : uOM;
// Quantity in Stock //
const quantitiStock = allSelectorGetIndentDetails?.[i]?.quantity_in_stock;
const formattedQuanitiStock = quantitiStock === null ? "0" : quantitiStock;

    tableData2.push({
      sl_no: i + 1,
      id: allSelectorGetIndentDetails?.[i]?.id,
      sheet: allSelectorGetIndentDetails?.[i]?.sheet,
      work_order_boq_item_no:
        allSelectorGetIndentDetails?.[i]?.work_order_boq_item_no,
      description: allSelectorGetIndentDetails?.[i]?.description,
      item_description:
        allSelectorGetIndentDetails?.[i]?.Item_name === null
          ? allSelectorGetIndentDetails?.[i]?.item_description
          : allSelectorGetIndentDetails?.[i]?.Item_name,
      uom:formattedUom,
      boq_quantity: formattedBoqQuantity,
      item_specification: allSelectorGetIndentDetails?.[i]?.item_specification,
      quantity_ordered_so_far:
        allSelectorGetIndentDetails?.[i]?.quantity_ordered_so_far,
      quantity_in_stock: formattedQuanitiStock,
      quantity_required_now:
        allSelectorGetIndentDetails?.[i]?.quantity_required_now,
      required_date: allSelectorGetIndentDetails?.[i]?.required_date.split('T')[0],
    });
  }
  const viewModal = (value) => {
    getIndentDetailsForm(orgId, value.indent_header_id);
    setStoreRecordData(value);
    setOpenModal(true);
  };
  console.log("storeRecordData", storeRecordData);

  const handleCancel = () => {
    setOpenModal(false);
  };

  const HandleApprove = async () => {
    const data = {
      id: storeRecordData?.indent_header_id,
      is_approve: 1,
    };
    await updateIndentStatus(data);
    setIsApproveClicked(true);
    setButtonRemove(false);
    console.log("data", data);
    getIndentForm(orgId, props.projectID);
  };

  return (
    <>
      <Button onClick={() => backToAllProject()}>All Projects</Button>
      <br /> <br />
      <div>
        <div className="title-show-project"> List of Indent</div>

        <br />
      </div>
      <Table
        scroll={{ x: "50vw" }}
        columns={columns}
        dataSource={tableData}
        pagination={10}
      />
      {/* view button */}
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={null}
        width={1700}
      >
        {logindetails.user.role === "SITEINCHARGE" ? (
          <div className="approve_btn">
            {buttonRemove && storeRecordData?.is_approve !== 1 ? (
              <Button
                className="button-29"
                onClick={HandleApprove}
                disabled={
                  storeRecordData?.is_approve === 1 || isApproveClicked === true
                    ? true
                    : false
                }
              >
                {storeRecordData?.is_approve === 1 || isApproveClicked === true
                  ? `Approved`
                  : `Approve`}
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <Table
          className="table"
          columns={columns2}
          dataSource={tableData2}
          pagination={10}
          scroll={{ x: "90vw" }}
        />
      </Modal>
    </>
  );
};

export default ListShowIndent;
