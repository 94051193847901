import React, { useEffect, useState } from "react";
import "./css/UsersShow.css";
import { Form, Input, Button, Select, Modal, Table } from "antd";
import { getAllTypeOfProject } from "../../../redux-core/settings/actions";
import { selectorGetTypeOfProject } from "../../../redux-core/settings/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import useSearch from "../../../hooks/useSearch";

const actionDispatch = (dispatch) => ({
  getAllTypesProject: (project_id, orgId) =>
    dispatch(getAllTypeOfProject(project_id, orgId)),
});

const TypesOfProjectTable = (props) => {
  const { getAllTypesProject } = actionDispatch(useDispatch());
  const getAllTypesOfProjects = useSelector(selectorGetTypeOfProject);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const [selectedKeys, setSelectedKeys] = useState();
  // const userId = logindetails.user.id;
  const getColumnSearchProps = useSearch();

  useEffect(() => {
    getAllTypesProject(props.projectID, orgId);
  }, []);

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      key: "sl_no",
      ...getColumnSearchProps("project_details_id"),
      width: "10%",
    },
    {
      title: "Project ID",
      dataIndex: "Project_id",
      key: "Project_id",
      ...getColumnSearchProps("project_details_id"),
      width: "10%",
    },
    {
      title: "Buildup Area",
      dataIndex: "buildup_area",
      key: "buildup_area",
      ...getColumnSearchProps("buildup_area"),
      width: "10%",
    },
    {
      title: "Total Buildup Area",
      dataIndex: "total_buildup_area",
      key : "total_buildup_area",
      ...getColumnSearchProps("total_buildup_area"),
      width: "10%",
    },
    {
      title: "Total Saleable Area",
      dataIndex: "total_saleable_area",
      key : "total_saleable_area",
      ...getColumnSearchProps("total_saleable_area"),
      width: "10%",
    },
    {
      title: "UOM",
      dataIndex: "select_UOM",
      key : "select_UOM",
      ...getColumnSearchProps("select_UOM"),
      width: "10%",
    },
    {
      title: "Basement",
      dataIndex: "basement",
      key : "basement",
      ...getColumnSearchProps("basement"),
      width: "10%",
    },
    {
      title: "Ground Level",
      dataIndex: "ground",
      key : "ground",
      ...getColumnSearchProps("ground"),
      width: "10%",
    },
    {
      title: "No of Floors",
      dataIndex: "floor",
      key : "floor",
      ...getColumnSearchProps("floor"),
      width: "10%",
    },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (text, record) => (
    //     <span>{`${record.description1 || ''} ${record.description2 || ''} ${record.description3 || ''} ${record.description4 || ''} ${record.description5 || ''}`}</span>
    //   ),
    // },
   
   
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width:"10%",
    //   render: ()=>{
    //     return(
    //       <div>
    //           <Button
    //           onClick={showButton}
    //           >
    //           Add More
    //           </Button>
    //       </div>
    //     )
    //   }
    // },
  ];


  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'description1',
        dataIndex: 'description1',
        key: 'description1',
      },
      {
        title: 'description2',
        dataIndex: 'description2',
        key: 'description2',
      },
      {
        title: 'description3',
        dataIndex: 'description3',
        key: 'description3',
      },
      {
        title: 'description4',
        dataIndex: 'description4',
        key: 'description4',
      },
      {
        title: 'description5',
        dataIndex: 'description5',
        key: 'description5',
      },
      {
        title: 'description6',
        dataIndex: 'description6',
        key: 'description6',
      },
      {
        title: 'description7',
        dataIndex: 'description7',
        key: 'description7',
      },
      {
        title: 'description8',
        dataIndex: 'description8',
        key: 'description8',
      },
      {
        title: 'description9',
        dataIndex: 'description9',
        key: 'description9',
      },
      {
        title: 'description10',
        dataIndex: 'description10',
        key: 'description10',
      },
      
    ];
  
    return <Table columns={columns} dataSource={[record]} pagination={false} />;
  };
  const data = [];
  for (let i = 0; i < getAllTypesOfProjects.length; i++) {
    data.push({
      key:getAllTypesOfProjects[i].id,
      sl_no: i + 1,
      Project_id: getAllTypesOfProjects[i].project_id,
      buildup_area: getAllTypesOfProjects[i].buildup_area,
      total_buildup_area: getAllTypesOfProjects[i].total_buildup_area,
      total_saleable_area: getAllTypesOfProjects[i].total_saleable_area,
      select_UOM: getAllTypesOfProjects[i].select_UOM,
      basement: getAllTypesOfProjects[i].basement,
      ground: getAllTypesOfProjects[i].ground,
      floor: getAllTypesOfProjects[i].floor,
      description1: getAllTypesOfProjects[i].description1,
      description2: getAllTypesOfProjects[i].description2,
      description3: getAllTypesOfProjects[i].description3,
      description4: getAllTypesOfProjects[i].description4,
      description5: getAllTypesOfProjects[i].description5,
      description6: getAllTypesOfProjects[i].description6,
      description7: getAllTypesOfProjects[i].description7,
      description8: getAllTypesOfProjects[i].description8,
      description9: getAllTypesOfProjects[i].description9,
      description10: getAllTypesOfProjects[i].description10,
    });
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
  };

  const defaultExpandedRowKeys = data
    .filter((record, index) => index % 2 === 0)
    .map((record) => record.key.toString());

  return (
    <>
      <div className="title-show-project"> Types Of Project</div>
      <Table
        dataSource={data}
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys, // Set the defaultExpandedRowKeys to the keys of the rows you want to expand initially
        }}
        style={{ marginTop: "20px" }}
        scroll={{ x: "90vw" }}
        // rowKey="key"
        // rowSelection={rowSelection}
      />
    </>
  );
};

export default TypesOfProjectTable;
