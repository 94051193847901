import { Button, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { createNewSignUp } from "../../redux-core/settings/actions";
import { useDispatch } from "react-redux";
import ButtonPrimary from "../button/ButtonPrimary";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  createNewSignUp: (newData) => dispatch(createNewSignUp(newData)),
});

function SignupForm() {
  const [form] = Form.useForm();
  const { createNewSignUp } = actionDispatch(useDispatch());

  const [formData, setFormData] = useState({
    organisation_name: "",
    admin_name: "",
    phone: "",
    email: "",
    address: "",
    pan: "",
    gst_number: "",
  });

  const submithandler = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());

    const data = {
      ...values,
      created_by: 102,
      orgID: 1,
    };

    if (data?.pan && data?.gst_number) {
      if (data?.pan != data?.gst_number.substring(2, 12)) {
        toast.error("GST number does not match PAN number.");
        return;
      }
    }
    console.log("Signup form data-----", data);

    // return;
    let response = await createNewSignUp(data);

    if (response.status==200) {
      console.log("res is------", response);
      setFormData({
        organisation_name: "",
        admin_name: "",
        phone: "",
        email: "",
        address: "",
        pan: "",
        gst_number: "",
      });
      toast.success("Signup request sent..")
    }
    else{
      toast.error("Something went wrong..")
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // console.log(" form - ", formData);
  };

  // Match GSTIN number with pan number with validation
  const validateGSTAndPAN = (rule, value, callback) => {
    const gstNumber = value;
    const panNumber = form.getFieldValue("pan"); // Assuming you have a reference to your form

    if (gstNumber && panNumber) {
      const gstSubstring = gstNumber.substring(2, 12);
      if (gstSubstring === panNumber) {
        callback();
      } else {
        callback("GSTIN number does not match PAN number.");
      }
    } else {
      callback();
    }
  };

  const HandelSubmit = async (value) => {
    // console.log("Value ::", value);

    const data = {
      ...value,
      created_by: 102,
      orgID: 1,
    };
    console.log("Signup form data-----", data);
    // return;
    let response = await createNewSignUp(data);

    if (response) {
      form.resetFields();
    }
    console.log("response", response);
  };

  const HandelSubmitFailed = (error) => {
    console.log("Error :: ", error);
  };

  return (
    <div className=" rounded-md ">
      <div className="font-montserrat     mb-3  font-semibold  text-2xl">
        Signup Form
      </div>

      {/* <Form
        className="  font-montserrat "
        form={form}
        name="signup_form"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={HandelSubmit}
        onFinishFailed={HandelSubmitFailed}
      >
        <Form.Item
          name="organisation_name"
          label="Organization Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Organization Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Organization Name" />
        </Form.Item>
        <Form.Item
          name="admin_name"
          label=" Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the  Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Contact Number"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter your Contact Number !",
            },
            {
              pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/, // Indian landline pattern
              // pattern: /^[\d]{10}$/,
              // message: "Contact number should be 10 digits",
              message: "Please Enter The Correct Contact Number",
            },
          ]}
        >
          <Input type="number" placeholder="Contact Number" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          className="input-field"
          rules={[
            {
              type: "email",
              message: "Please Enter valid E-mail !",
            },
            {
              required: true,
              message: "Please enter your E-mail !",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter your Address !",
            },
            {
              pattern: /^(.{0,300})$/,
              message: "please enter less than 300 characters only",
            },
          ]}
          className="input-field"
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item
          name="pan"
          label="PAN Number"
          rules={[
            { required: true, message: "Enter PAN Number!" },
            {
              pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
              message: "Please Valid PAN Number!",
            },
          ]}
          className="input-field"
        >
          <Input placeholder=" Pan Number" />
        </Form.Item>
        <Form.Item
          name="gst_number"
          label="GST Number"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter your GST Number !",
            },
            {
              pattern:
                "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
              message: "Please enter a valid GST Number !",
            },
            {
              validator: validateGSTAndPAN,
            },
          ]}
        >
          <Input placeholder="GST Number" />
        </Form.Item>
        <Form.Item>
          <div className="  flex mr-10 justify-end">
            
            <button
              // className="  flex justify-end "
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class=" rounded  bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form> */}

      {/* new form - shivam kashyap */}
      <div>
        <form
          className="font-montserrat gap-y-5 flex flex-col"
          onSubmit={submithandler}
        >
          {/* 1a */}
          <div className="flex justify-evenly ">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="organisation_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Organization Name
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Organization Name"
                  required
                  type="text"
                  id="organisation_name"
                  name="organisation_name"
                  value={formData.organisation_name}
                  onChange={handleInputChange}
                  pattern="[A-Za-z\s]+"
                  title="Please enter only text."
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* 2 */}
            <div className="">
              <label
                htmlFor="admin_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Name
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Name"
                  required
                  type="text"
                  pattern="[A-Za-z\s]+"
                  title="Please enter only text."
                  id="admin_name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 3  */}
            <div className="">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Contact Number
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Contact Number"
                  required
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  pattern="^\d{10}$"
                  title="Please enter a valid 10-digit phone number."
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          {/* 2a */}
          <div className="flex justify-evenly ">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Email
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Email"
                  required
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  title="Please enter a valid email address."
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* 2 */}
            <div className="">
              <label
                htmlFor="address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Address
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Address"
                  required
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 3  */}
            <div className="">
              <label
                htmlFor="pan"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                PAN Number
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter Pan Number"
                  required
                  type="text"
                  id="pan"
                  name="pan"
                  value={formData.pan}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          {/* 3a */}
          <div className="flex  justify-evenly  ">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="gst_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                GST Number
              </label>
              <div className="mt- w-[300px]">
                <input
                  placeholder="Enter GST Number"
                  required
                  type="text"
                  id="gst_number"
                  name="gst_number"
                  value={formData.gst_number}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="w-[300px]"></div>

            {/* 3  */}
            <div className="w-[300px] flex justify-end">
              <button
                // className="  flex justify-end "
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class=" rounded h-9 mt-4  bg-primary px-6  text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
