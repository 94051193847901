import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Select } from "antd";
import "../css/ShowMaterialStock.css";
import WithOutPoMaterialRecieveCreateFrom from "./WithOutPoMaterialRecieveCreateFrom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorGetInvoiceWithOutPo,
  selectorGetSiteTransferData,
  selectorGetReplacePoItemsData,
  selectorGetSiteTransferDataGrnCreate,
} from "../../../../redux-core/materials/selectors";
import MaterialQtySiteTransferForm from "./MaterialQtySiteTransferForm";
import { EyeOutlined } from "@ant-design/icons";
import ViewTransferItemsFroms from "./ViewTransferItemsFroms";
import {
  getAllSiteTransferItem,
  getPoReplacementItemItems,
  getSiteTransferDataTransferNumberWise,
  getCountGrnNumber,
  getCountTarnsferNumber,
  getAllInvoiceWithOutPo,
} from "../../../../redux-core/materials/actions";
import PoItemsReplaceFrom from "./PoItemsReplaceFrom";
import PoReplaceItemsFrom from "./PoReplaceItemsFrom";
import CreateSiteTransferItemGrn from "./CreateSiteTransferItemGrn";
import { selectorCountGrnNumber } from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";

const actionDispatch = (dispatch) => ({
  getAllSiteTransferItem: (transferNumber) =>
    dispatch(getAllSiteTransferItem(transferNumber)),
  getSiteTransferDataTransferNumberWise: (transferNumber) =>
    dispatch(getSiteTransferDataTransferNumberWise(transferNumber)),
  getPoReplacementItemItems: (replaceGrnNo) =>
    dispatch(getPoReplacementItemItems(replaceGrnNo)),
  getCountGrnNumber: (projectID) => dispatch(getCountGrnNumber(projectID)),
  getProjectByprojectId: (projectID) =>
    dispatch(getProjectByprojectId(projectID)),
  getCountTarnsferNumber: (projectID) =>
    dispatch(getCountTarnsferNumber(projectID)),
  getAllInvoiceWithOutPo: (projectID) =>
    dispatch(getAllInvoiceWithOutPo(projectID)),
});

const ShowReceiptMaterials = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siteTransferModalVisible, setSiteTransferModalVisible] =
    useState(false);
  const getInvoiceWithOutPo = useSelector(selectorGetInvoiceWithOutPo);
  const GetSiteTransferData = useSelector(selectorGetSiteTransferData);
  const showReplacePoItemsData = useSelector(selectorGetReplacePoItemsData);
  const GetSiteTransferDataForGrn = useSelector(
    selectorGetSiteTransferDataGrnCreate
  );
  const [showTransferItems, setShowTransferItems] = useState(false);
  const {
    getAllSiteTransferItem,
    getPoReplacementItemItems,
    getSiteTransferDataTransferNumberWise,
    getCountGrnNumber,
    getProjectByprojectId,
    getCountTarnsferNumber,
    getAllInvoiceWithOutPo,
  } = actionDispatch(useDispatch());
  const [showReplacement, setShowReplacement] = useState(false);
  const [showReplacementItem, setShowReplacementItem] = useState(false);
  const [showSiteTransferGrn, setShowSiteTransferGrn] = useState(false);
  const [passTransferNumber, setPassTransferNumber] = useState();
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [showUplodatPo, setShowUploadPo] = useState();

  useEffect(() => {
    getCountGrnNumber(props.projectID);
    getProjectByprojectId(props.projectID);
    getCountTarnsferNumber(props.projectID);
    getAllInvoiceWithOutPo(props.projectID);
  }, [props.projectID]);

  const TableSiteTransfer = [];
  for (let i = 0; i < GetSiteTransferDataForGrn?.length; i++) {
    let dueDate = new Date(GetSiteTransferDataForGrn[i].transfer_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    TableSiteTransfer.push({
      id: GetSiteTransferDataForGrn[i].id,
      project_name: GetSiteTransferDataForGrn[i].project_name,
      transfer_number: GetSiteTransferDataForGrn[i].transfer_number,
      transfer_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const Tabledata = [];
  for (let i = 0; i < GetSiteTransferData?.length; i++) {
    let dueDate = new Date(GetSiteTransferData[i].transfer_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    Tabledata.push({
      id: GetSiteTransferData[i].id,
      project_name: GetSiteTransferData[i].project_name,
      transfer_number: GetSiteTransferData[i].transfer_number,
      transfer_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const data = [];
  for (let i = 0; i < getInvoiceWithOutPo?.length; i++) {
    let dueDate = new Date(getInvoiceWithOutPo[i].invoice_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    data.push({
      id: getInvoiceWithOutPo[i].id,
      invoice_number: getInvoiceWithOutPo[i].invoice_number,
      invoice_date: dueDate.toLocaleDateString("en-GB"),
      suppiler_name: getInvoiceWithOutPo[i].suppiler_name,
      suppiler_number: getInvoiceWithOutPo[i].suppiler_number,
      supplier_address: getInvoiceWithOutPo[i].supplier_address,
      gst_number: getInvoiceWithOutPo[i].gst_number,
      taxable_value: getInvoiceWithOutPo[i].taxable_value,
      gst_value: getInvoiceWithOutPo[i].gst_value,
      total_value: getInvoiceWithOutPo[i].total_value,
      upload_invoice: getInvoiceWithOutPo[i]?.upload_invoice,
    });
  }

  const DataTable = [];
  for (let i = 0; i < showReplacePoItemsData?.length; i++) {
    let dueDate = new Date(showReplacePoItemsData[i].replace_date);
    // dueDate.setDate(dueDate.getDate() + 1);
    DataTable.push({
      id: showReplacePoItemsData[i].id,
      replace_grn_no: showReplacePoItemsData[i].replace_grn_no,
      po_number: showReplacePoItemsData[i].po_number,
      replace_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
    },
    {
      title: "Invoice",
      dataIndex: "upload_invoice",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => handePdfView(text)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "suppiler_name",
    },
    {
      title: "Invoice Taxable Amount",
      dataIndex: "taxable_value",
    },
    {
      title: "Invoice GST Amount",
      dataIndex: "gst_value",
    },
    {
      title: "Invoice Total Amount",
      dataIndex: "total_value",
    },
  ];

  const handePdfView = (value) => {
    setShowUploadPo(value);
    setOpenPdfModal(true);
  };

  const viewSiteTransferItems = (value) => {
    setShowTransferItems(true);
    getAllSiteTransferItem(value.transfer_number);
  };

  const columns2 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Transfer Items",
      dataIndex: "transfer_number",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => viewSiteTransferItems(record)}
            >
              View Transfer Items{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Transfer Number",
      dataIndex: "transfer_number",
    },
    {
      title: "Transfer Project",
      dataIndex: "project_name",
    },
    {
      title: "Transfer Date",
      dataIndex: "transfer_date",
    },
  ];
  const handelWithOutPoModalOpen = () => {
    setIsModalVisible(true);
  };

  const HandelSiteTransfer = () => {
    setSiteTransferModalVisible(true);
  };

  const HandelPoItemsReplace = () => {
    setShowReplacement(true);
  };

  const viewReplaceItems = (value) => {
    setShowReplacementItem(true);
    getPoReplacementItemItems(value.replace_grn_no);
  };

  const columns3 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "View Replace Item",
      dataIndex: "replace_grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => viewReplaceItems(record)}
            >
              View Replace Item{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Replace GRN Number",
      dataIndex: "replace_grn_no",
    },
    {
      title: "Replace Date",
      dataIndex: "replace_date",
    },
    {
      title: "Purchase Order Number",
      dataIndex: "po_number",
    },
  ];

  const HandelSiteTransferItemsGrn = (value) => {
    setShowSiteTransferGrn(true);
    getAllSiteTransferItem(value.transfer_number);
    getSiteTransferDataTransferNumberWise(value.transfer_number);
    setPassTransferNumber(value.transfer_number);
  };

  const columns4 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Transfer Items",
      dataIndex: "transfer_number",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => HandelSiteTransferItemsGrn(record)}
            >
              View Transfer Items{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Transfer Number",
      dataIndex: "transfer_number",
    },
    {
      title: "Recieve Project",
      dataIndex: "project_name",
    },
    {
      title: "Transfer Date",
      dataIndex: "transfer_date",
    },
  ];
  const modalClose = () => {
    setIsModalVisible(false);
    setSiteTransferModalVisible(false);
    setShowTransferItems(false);
    setShowReplacement(false);
    setShowReplacementItem(false);
    setShowSiteTransferGrn(false);
    setOpenPdfModal(false);
  };
  const { Option } = Select;
  const [cardType, setCardType] = useState("");
  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const backToAllProject=()=>{
    props.backProject()
  }

  return (
    <>
      <div className="title-show-project">
        Select the way of material manage Process
      </div>
      <Button style={{float:"right"}} onClick={() =>backToAllProject()}>All Projects</Button>
      <br />
      <Select
        onChange={handleCardTypeChange}
        className="input-field"
        placeholder="Select The Process"
      >
        <Option value="without_po">Local Purchase</Option>
        <Option value="item_replace">Po Items Replacement</Option>
        <Option value="site_transfer">Item Site Transfer</Option>
        <Option value="site_transfer_grn_create">
          Item Site Transfer GRN Create
        </Option>
      </Select>
      <div className="BtnContainer">
        {cardType === "without_po" && (
          <Button className="button-61" onClick={handelWithOutPoModalOpen}>
            <b>New Local Purchase Entry</b>
          </Button>
        )}
        {cardType === "item_replace" && (
          <Button className="button-61" onClick={HandelPoItemsReplace}>
            <b>Po Items Replacement </b>
          </Button>
        )}
        {cardType === "site_transfer" && (
          <Button className="button-61" onClick={HandelSiteTransfer}>
            <b>Item Site Transfer</b>
          </Button>
        )}
      </div>
      {cardType === "without_po" && (
        <Table
          scroll={{ x: "100vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      )}
      {cardType === "item_replace" && (
        <Table
          scroll={{ x: "70vw" }}
          columns={columns3}
          dataSource={DataTable}
          pagination={10}
        />
      )}
      {cardType === "site_transfer" && (
        <Table
          scroll={{ x: "60vw" }}
          columns={columns2}
          dataSource={Tabledata}
          pagination={10}
        />
      )}
      {cardType === "site_transfer_grn_create" && (
        <Table
          scroll={{ x: "60vw" }}
          columns={columns4}
          dataSource={TableSiteTransfer}
          pagination={10}
        />
      )}
      <Modal
        visible={
          isModalVisible === true
            ? isModalVisible
            : siteTransferModalVisible === true
            ? siteTransferModalVisible
            : showTransferItems === true
            ? showTransferItems
            : showReplacement === true
            ? showReplacement
            : showReplacementItem === true
            ? showReplacementItem
            : showSiteTransferGrn
        }
        onCancel={modalClose}
        // width={1950}
        width={
          isModalVisible === true
            ? 1950
            : siteTransferModalVisible === true
            ? 1050
            : showReplacement && 1050
        }
        footer={null}
      >
        {isModalVisible === true ? (
          <WithOutPoMaterialRecieveCreateFrom projectID={props.projectID} />
        ) : siteTransferModalVisible === true ? (
          <MaterialQtySiteTransferForm projectID={props.projectID} />
        ) : showTransferItems === true ? (
          <ViewTransferItemsFroms />
        ) : showReplacement === true ? (
          <PoItemsReplaceFrom projectID={props.projectID} />
        ) : showReplacementItem === true ? (
          <PoReplaceItemsFrom />
        ) : (
          showSiteTransferGrn && (
            <CreateSiteTransferItemGrn
              projectID={props.projectID}
              TransferNumber={passTransferNumber}
            />
          )
        )}
      </Modal>
      <Modal
        open={openPdfModal}
        footer={null}
        width={1500}
        onCancel={modalClose}
      >
        <iframe
          className={"application/pdf"}
          width="100%"
          height="700"
          frameborder="10"
          src={showUplodatPo}
        />
      </Modal>
    </>
  );
};

export default ShowReceiptMaterials;
