import "./css/ShowProject.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Image,
  Modal,
  Upload,
  message,
  Spin,
  DatePicker,
  Tooltip,
  InputNumber,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  EyeOutlined,
  PrinterOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  countAction,
  deleteByProjId,
  getAllProj,
  updateByProjId,
} from "../../../redux-core/settings/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { ShowDeleteConfirm } from "../../popupmodal";
import { PlusOutlined } from "@ant-design/icons";
import ShowProjectDetails from "./ShowProjectDetails";
import TypesOfProjectTable from "./TypesOfProjectTable";
import moment from "moment";
import AddProjectDetails from "./AddProjectDetails";

const actionDispatch = (dispatch) => ({
  delProjById: (id) => dispatch(deleteByProjId(id)),
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  updateProj: (newProj) => dispatch(updateByProjId(newProj)),
  count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const ShowProjects = () => {
  const getColumnSearchProps = useSearch();
  const [selectedKeys, setSelectedKeys] = useState();
  const [projRow, setProjRow] = useState({});
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isdetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isTypesModalVisible, setIsTypesModalVisible] = useState(false);

  const { delProjById, projects, updateProj, count } = actionDispatch(
    useDispatch()
  );
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [imagecard, setImagecard] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [activedata, setActivedata] = useState(true);
  const [passedProjectId, setPassedProjectId] = useState("");
  const [typesofProject, setTypesOfProject] = useState("");
  const Spindata = useSelector((item) => item.spinreducer);
  const [storeActualStartDate,setStoreActualStartDate]=useState()
  const [openProjectDetail,setOpenProjectDetail]=useState(false)
  const [passProjectId,setPassPojectId]=useState()
  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
  }, []);
  // modal create
  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    setVisible(false);
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
       width:"5%",
       key:"sl_no"
    },
    // {
    //   title: "Project ID",
    //   dataIndex: "project_id",
    //   ...getColumnSearchProps("project_id"),
    //   // width:"5%",
    // },
    {
      title: "Project Name",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
      // width: "15%",
    },
    {
      title: "Project Code",
      dataIndex: "project_code",
      ...getColumnSearchProps("project_code"),
      // width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      // width: "20%",
    },
    // {
    //   title: "Paying Company",
    //   dataIndex: "paying_company",
    //   ...getColumnSearchProps("paying_company"),
    //   // width: "10%",
    // },
    {
      title: "Project Start Date",
      dataIndex: "project_start_date",
      ...getColumnSearchProps("project_start_date"),
      // width: "10%",
    },
    {
      title: "Project End Date",
      dataIndex: "project_end_date",
      ...getColumnSearchProps("project_end_date"),
      // width: "10%",
    },
    {
      title: "Actual Start Date",
      dataIndex: "actual_start_date",
      ...getColumnSearchProps("actual_start_date"),
      // width: "10%",
    },
    {
      title: "Actual End Date",
      dataIndex: "actual_end_date",
      ...getColumnSearchProps("actual_end_date"),
      // width: "10%",
    },
    // {
    //   title: "Budget",
    //   dataIndex: "original_budget",
    //   ...getColumnSearchProps("original_budget"),
    //   // width: "10%",
    // },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          {" "}
          <Image src={text} style={{ width: "20px", height: "20px" }} />{" "}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Show Types of Project">
              <Button onClick={() => showTypesModal(record)}>
                <FileDoneOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Show Project Details">
              <Button onClick={() => showDetailModal(record)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="add project location">
              <Button onClick={() => openProjectDetailModal(record)}>
              <FormOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Edit">
              <Button onClick={() => showModal(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                onClick={() => deleteRow(record)}
                style={{ marginLeft: "5px" }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];


const openProjectDetailModal=(data2)=>{
setOpenProjectDetail(true)
setPassPojectId(data2.project_id)


}


  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
  };

  //---------------------- modal and forms ----------------

  const onEditProjectFinish = async (values) => {
    let data = {
      project_name: values.project_name,
      description: values.description,
      actual_start_date: values.actual_start_date ? moment(values.actual_start_date).format("YYYY-MM-DD")  : null,
      actual_end_date:values.actual_end_date ? moment(values.actual_end_date).format("YYYY-MM-DD") : null,
      original_budget: values.original_budget,
      orgID: logindetails.organisation.orgID,
      created_by: logindetails.user.id,
      project_id: projRow.project_id,
    };
    if (values.image && values.image.length < 1) {
      delete data.image;
    } else {
      data.image = values.image[0].size
        ? values.image
        : values.image[0].thumbUrl;
    }
 
    let response = await updateProj(data);
    if (response) {
      await setProjRow(data);
      await setIsModalVisible(false);
      await projects(logindetails.organisation.orgID, logindetails.user.id);
    }
  };

  const onEditProjectFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_Project_field = () => {
    form.setFieldsValue({
      project_name: projRow.project_name,
      actual_start_date:projRow.actual_start_date ===null ? "" :moment(projRow.actual_start_date) ,
      actual_end_date:projRow.actual_end_date ===null ? "" :moment(projRow.actual_end_date),
      image: projRow.image ? [{ thumbUrl: projRow.image }] : [],
      description: projRow.description,
      original_budget: projRow.original_budget,
    });
    {
      projRow.image == null ||
      projRow.image === undefined ||
      projRow.image === ""
        ? setImagecard(false)
        : setImagecard(true);
    }
  };

  const normFile = (e) => {
    if (e.fileList.length > 0) setImagecard(true);
    else setImagecard(false);
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const showModal = (data1) => {
   
    setProjRow(data1);
    setIsModalVisible(true);
    setStoreActualStartDate(moment(data1.actual_start_date))
    form.setFieldsValue({
      project_name: data1.project_name,
      description: data1.description,
      original_budget: data1.original_budget,
      //paying_company: data1.paying_company,
      actual_start_date:data1.actual_start_date ===null ? "" :moment(data1.actual_start_date, "DD-MM-YYYY") ,
      actual_end_date:data1.actual_end_date ===null ? "" :moment(data1.actual_end_date, "DD-MM-YYYY"),
      // original_budget: data1.original_budget,
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
    {
      data1.image == null || data1.image === undefined || data1.image === ""
        ? setImagecard(false)
        : setImagecard(true);
    }
  };

  /**
   * modal project details
   */
  const showTypesModal = (record) => {
    setIsTypesModalVisible(true);
    setTypesOfProject(record.project_id);
  };

  const showDetailModal = (record) => {
    setIsDetailModalVisible(true);
    setPassedProjectId(record.project_id);
  };

  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setIsModalVisible(false);
    setTypesOfProject(false);
    setIsTypesModalVisible(false);
    setOpenProjectDetail(false);
    clear_Project_field();
  };
  //------------------------project details ---------------------

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.project_id]) });
  };
  const onConfirm = async (key) => {
    await delProjById(key);
    await projects(logindetails.organisation.orgID, logindetails.user.id);
    await count(logindetails.organisation.orgID);
  };

  const deleteSelected = () => {
    if (selectedKeys === undefined)
      notification.open({
        message: "Select items to delete.",
      });
    else if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };
  const data = [];
  for (let i = 0; i < allProjectsSelectorData.length; i++) {
    let startDate = new Date(allProjectsSelectorData[i].project_start_date);
    startDate.setDate(startDate.getDate());
    let endDate = new Date(allProjectsSelectorData[i].project_end_date);
    endDate.setDate(endDate.getDate());
    let acStartDate = new Date(allProjectsSelectorData[i].actual_start_date);
    acStartDate.setDate(acStartDate.getDate());
    let acEndDate = new Date(allProjectsSelectorData[i].actual_end_date);
    acEndDate.setDate(acEndDate.getDate());
    
    data.push({
      sl_no: i + 1,
      // key: allProjectsSelectorData[i].project_id,
      project_id: allProjectsSelectorData[i].project_id,
      project_name: allProjectsSelectorData[i].project_name,
      project_code: allProjectsSelectorData[i].project_code,
      // project_name_ID:
      //   allProjectsSelectorData[i].project_name +
      //   "  -- " +
      //   allProjectsSelectorData[i].project_id,
      description: allProjectsSelectorData[i].description,
      //paying_company: allProjectsSelectorData[i].paying_company,
      project_start_date: moment(startDate.toISOString().slice(0, 10)).format("DD-MM-YYYY"),
      project_end_date: moment(endDate.toISOString().slice(0, 10)).format("DD-MM-YYYY"),
      actual_start_date: allProjectsSelectorData[i].actual_start_date === null ? allProjectsSelectorData[i].actual_start_date : moment(allProjectsSelectorData[i].actual_start_date).format("DD-MM-YYYY"),
      actual_end_date: allProjectsSelectorData[i].actual_end_date === null ? allProjectsSelectorData[i].actual_end_date : moment(allProjectsSelectorData[i].actual_end_date).format("DD-MM-YYYY"),
      original_budget: allProjectsSelectorData[i].original_budget,
      image: allProjectsSelectorData[i].image,
    });
  }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancelmodal = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };
  const handleActualEndDateChange = (endDateValue) => {
    console.log("endDateValue", endDateValue)
    const actualStartDate = form.getFieldValue("actual_start_date");

    if (
      actualStartDate &&
      endDateValue &&
      endDateValue.isSameOrBefore(actualStartDate, "day")
    ) {
      message.error(
        "Actual Project End date must be greater than to Actual project Start date"
      );
      form.setFieldsValue({ actual_end_date: null });
    }
  };

  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(Date.now(), "day");
  };
  const handleactualStartDateChange = (actualStartDate) => {
   
    const projectStartDate = storeActualStartDate;
  

    if (
      actualStartDate &&
      projectStartDate &&
      actualStartDate.isBefore(projectStartDate, "day")
    ) {
      message.error(
        "Actual Project start date must be greater than Project start date"
      );
      form.setFieldsValue({ actual_start_date: null });
      console.log("actualStartDate", actualStartDate)
    } 
  };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Of Projects </div>
        <br />
        <div className="btn__">
          {/* <Button className="pdf">Export to PDF</Button> */}

          <Button className="excel">
            <CSVLink
              filename={"Projects_Table.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>

          {/* <div>
            <Button type="primary" 
            style={{backgroundColor:"teal", borderRadius:"5px"}}
            onClick={() => window.print()}>
              {" "}
              <PrinterOutlined />
              Print
            </Button>
          </div> */}
          {/* <Button className="print">Print</Button> */}
          {/* <Button className="copy">Copy</Button> */}
        </div>

        {/*Show Project Details Modal  */}
        <div>
          <Modal
            visible={visible}
            onCancel={handleHideModal}
            footer={null}
            width="75%"
            style={{ top: 80 }}
          >
            <ShowProjectDetails onFinish={handleFinish} />
          </Modal>
        </div>

        <Table
          className="table"
          scroll={{ x: "130vw", y: 550 }}
          rowSelection={rowSelection}
          columns={columns}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          dataSource={data}
          pagination={10}
          rowKey="project_id"
          style={{ paddingTop: "10px" }}
        />
        {/* </div> */}
        <Modal
          width="95%"
          className="showTypesProjectModal"
          visible={isTypesModalVisible}
          onCancel={handleCancel}
          centered={true}
          footer={null}
        >
          <TypesOfProjectTable projectID={typesofProject} />
        </Modal>

        <Modal
          className="showProjectModal"
          visible={isdetailModalVisible}
          onCancel={handleCancel}
          centered={true}
          footer={null}
        >
          <ShowProjectDetails projectID={passedProjectId} />
        </Modal>


        <Modal
        visible={openProjectDetail}
        onCancel={handleCancel}
width={"80%"}
centered={true}
footer={null}
        >
<AddProjectDetails projectID={passProjectId} />
        </Modal>
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={700}
          centered={true}
          footer={null}
        >
          <div className="edit-project">Edit Project</div>
          <br />
          <Form
            form={form}
            name="add-project"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditProjectFinish}
            onFinishFailed={onEditProjectFinishFailed}
            //autoComplete="off"
          >
            <Form.Item
              name="project_name"
              label="Project Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter the Project Name !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 character only",
                },
              ]}
            >
              <Input
                placeholder="Project Name"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            {/* <Form.Item
              name="paying_company"
              label="Paying Company"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter the Paying Company !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 character only",
                },
              ]}
            >
              <Input
                placeholder="Paying Company"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item> */}
              <Form.Item
          name="actual_start_date"
          label="Actual Start Date"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select Actual Start Date !",
            },
          ]}
        >
          <DatePicker
           format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            onChange={handleactualStartDateChange}
          />
        </Form.Item>
            <Form.Item
              name="actual_end_date"
              label="Actual End Date"
              className="input-field"
              rules={[
                {
                  required: false,
                  message: "Please select Actual End Date !",
                },
              ]}
            >
              <DatePicker
               format="DD-MM-YYYY"
                //  disabledDate={disabledStartDate}
                onChange={handleActualEndDateChange}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="input-field"
              rules={[
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 character only",
                },
              ]}
            >
              <Input.TextArea
                style={{ maxHeight: "130px", minHeight: "130px" }}
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            {/* <Form.Item
          name="original_budget"
          label="Budget"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter the Original Budget !",
            },
            {
              pattern: /\d{5}/,
              message: "Value must be greater than equal to 10000",
            },
          ]}
        >

          <InputNumber type="number" style={{ width: "100%" }} />

        </Form.Item> */}

            <Form.Item
              name="image"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="input-field"
            >
              {/* action="/upload.do" */}
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                maxCount={"1"}
                onPreview={handlePreview}
                beforeUpload={(file, fileList) => {
                  return false;
                }}
              >
                {!imagecard && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
          name="original_budget"
          label="Budget"
          className="input-field"
          rules={[
            // {
            //   required: false,
            //   message: "Please enter the Original Budget !",
            // },
            {
              pattern: /\d{5}/,
              message: "Value must be greater than equal to 10000",
            },
          ]}
        >
          {/* <Space direction="vertical" style={{ width: '100%' }}> */}
          <InputNumber type="number" style={{ width: "100%" }} />

          {/* </Space> */}
        </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_Project_field} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
          <br />
        </Modal>
        {data.length > 0 && (
          <Button
            style={{
              display: activedata ? "" : "none",
              borderRadius: "5px",
              backgroundColor: "teal",
            }}
            className="deleteallbtn copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )}
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ShowProjects);
