import React from "react";

const Dashboard = () => {
  return (
    <div>
      <h1>
        <iframe
          // className="application/pdf"
          width="100%"
          height="800"
          frameborder="0"
          src="https://app.powerbi.com/view?r=eyJrIjoiZDlhZjE3NjQtMGUzZC00ZGUwLWFlZTktYjUxNGQxMTMxNWZlIiwidCI6IjcyODg4MDVjLTcyODQtNDI0Zi1iZjQ5LWJkMGU5MmMwM2JjNSJ9"
        ></iframe>
      </h1>
    </div>
  );
};

export default Dashboard;
