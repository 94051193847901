import "./css/UsersShow.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Input,
  notification,
  Form,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  countActionUserManagement,
  deleteUserById,
  updateUsersById,
  updateUsersPassword,
  userDetails,
} from "../../../redux-core/userManagement/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { selectorUserDetail } from "../../../redux-core/userManagement/selectors";
import { countAction, getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  updateUser: (id) => dispatch(updateUsersById(id)),
  updateUsersPassword: (id) => dispatch(updateUsersPassword(id)),
  delUser: (id) => dispatch(deleteUserById(id)),
  getProj: (orgID, userId) => dispatch(getAllProj(orgID, userId)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  count: (orgID) => dispatch(countAction(orgID)),
});

const UsersShow = () => {
  const getColumnSearchProps = useSearch();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState();
  const [userRow, setUserRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const allProjData = useSelector(makeSelectAllProj);
  const allUsersData = useSelector(selectorUserDetail);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const children = [];
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgID = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [activedata, setActivedata] = useState(true);
  const Spindata = useSelector((item) => item.spinreducer);
  const [resetModal, setResetModal] = useState(false);
  const [rowAdminId, setRowAdminId] = useState();
  const [rowName, setRowName] = useState();
  const [rowMail, setRowMail] = useState();

  const { getUsers, getProj, delUser, count, updateUser, updateUsersPassword } =
    actionDispatch(useDispatch());

  const options = [
    { value: "SITEENGINEER", label: "Site Engineer" },
    { value: "SITEINCHARGE", label: "Site InCharge" },
    { value: "SUPERVISOR", label: "Supervisor" },
    { value: "DRAWINGARCHITECTURE", label: "Drawing Architecture" },
    { value: "BILLINGENGINEER", label: "Billing Engineer" },
    { value: "QCENGINEER", label: "Quality Control Engineer" },
    { value: "PROCUREMENTMANAGER", label: "Procurement Manager" },
    { value: "PLANNINGENGINEER", label: "Planning Engineer" },
  ];

  useEffect(() => {
    getUsers(orgID);
  }, []);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
      width: "100px",
    },
    // {
    //   title: "User ID",
    //   dataIndex: "admin_id",
    //   ...getColumnSearchProps("admin_id"),
    // },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Address",
      dataIndex: "address",
      ...getColumnSearchProps("address"),
    },
    // {
    //   title: "Username",
    //   dataIndex: "username",
    //   ...getColumnSearchProps("username"),
    // },
    {
      title: "Role",
      dataIndex: "role",
      ...getColumnSearchProps("role"),
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "Reset Password",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => resetPasword(record)}>
              Reset
              <EllipsisOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "200px",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const resetPasword = (data) => {
    setResetModal(true);
    setRowAdminId(data.admin_id);
    setRowName(data.name);
    setRowMail(data.email);
  };
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });
  useEffect(() => {
    form2.setFieldsValue({
      name: rowName,
      email: rowMail,
    });
  }, [rowName, rowMail]);

  const onResetPasswordFinish = async (value) => {
    let data = {
      admin_id: rowAdminId,
      password: value.password,
    };
    const response = await updateUsersPassword(data);
    form2.resetFields();
    setResetModal(false);
  };
  const onResetPasswordFinishFailed = (error) => {
    console.log("ERROR", error);
  };

  const onEditUserFinish = async (values) => {
    let project_id = values.project_id.map(Number);
    let data = {
      project_id: project_id,
      name: values.name,
      phone: values.phone,
      address: values.address,
      email: values.email,
      password: values.password ? values.password : "", //for bug not showing so this one hard core send

      username: values.username,
      orgID: orgID,
      createdBy: userId,
      del_status: 0,
      admin_id: userRow.admin_id,
      role: values.role,
    };

    let response = await updateUser(data);
    if (response) {
      await setIsModalVisible(false);
      await getUsers(orgID);
    }
  };

  const onEditUserFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /**
   * modal form on edit
   * @param {*} data
   */
  const showModal = (data) => {
    getProj(orgID, userId);
    // setFilteredData([]);
    setUserRow(data);

    // Check if data.project_id is null or undefined, and handle the case
    if (data.project_id === null || data.project_id === undefined) {
      console.error("data.project_id is null or undefined");
      // You can choose to handle this case by showing an error message or taking appropriate action.
      // For example, you might return early to prevent the modal from visibleing:
      setIsModalVisible(true);
      setFilteredData([]);
      return;
    }

    const tempFilteredData = [];

    if (data.project_id) {
      const projID = data.project_id.split(",");
      projID.forEach((item) => {
        const matchingProject = allProjData.find(
          (project) => project.project_id == item
        );
        if (matchingProject) {
          tempFilteredData.push(`${matchingProject.project_id}`);
        }
      });
    }

    setFilteredData(tempFilteredData);
    setIsModalVisible(true);
    form.setFieldsValue({
      name: data.name,
      email: data.email,
      // username: data.username,
      //password: data.password,
      phone: data.phone,
      address: data.address,
      role: data.role,
      project_id: filteredData,

      // image: projRow.image
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      project_id: filteredData,
    });
  }, [filteredData]);

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (key) => {
    await delUser(key);
    await getUsers(orgID);
    await count(orgID);
  };

  const deleteSelected = () => {
    if (selectedKeys === undefined || selectedKeys.length === 0)
      notification.visible({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setResetModal(false);
  };

  const clear_fields = () => {
    getProj(orgID, userId);
    form.setFieldsValue({
      name: userRow.name,
      email: userRow.email,
      // username: userRow.username,
      // password: userRow.password,
      phone: userRow.phone,
      address: userRow.address,
      role: userRow.role,
      project_id: [userRow.project_id],
    });
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // for excel export
  const datatable = [];

  for (let i = 0; i < allUsersData.length; i++) {
    if (allUsersData[i].role !== "ADMIN") {
      datatable.push({
        sl_no: i + 1,
        // key: allUsersData[i].admin_id,
        admin_id: allUsersData[i].admin_id,
        name: allUsersData[i].name,
        email: allUsersData[i].email,
        phone: allUsersData[i].phone,
        address: allUsersData[i].address,
        // username: allUsersData[i].username,
        // project_id: allUsersData[i].project_id,
        role: allUsersData[i].role,
        project_name: allUsersData[i].project_name,
      });
    }
  }

  //  for table data
  const data = [];
  for (let i = 0; i < allUsersData.length; i++) {
    if (allUsersData[i].role !== "ADMIN") {
      data.push({
        sl_no: i + 1,
        key: allUsersData[i].admin_id,
        admin_id: allUsersData[i].admin_id,
        name: allUsersData[i].name,
        email: allUsersData[i].email,
        phone: allUsersData[i].phone,
        address: allUsersData[i].address,
        // username: allUsersData[i].username,
        project_id: allUsersData[i].project_id,
        role: allUsersData[i].role,
        project_name: allUsersData[i].project_name,
      });
    }
  }

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">User Details</div>
        <br />
        <div className="btn__">
          {/* <Button className="pdf">Export to PDF</Button> */}
          <Button className="excel">
            <CSVLink
              filename={"Users_Table.csv"}
              data={datatable}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          {/* <Button className="print">Print</Button>
                <Button className="copy">Copy</Button> */}
        </div>
        <Table
          className="table"
          scroll={{ x: "130vw", y: 550 }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          pagination={10}
        />
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={1100}
          centered={true}
          footer={null}
        >
          <div className="edit__user">Edit User Details</div>
          <br />
          <Form
            form={form}
            name="add-User"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditUserFinish}
            onFinishFailed={onEditUserFinishFailed}
          >
            <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter  name!",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "This input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter  E-mail!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            {/* <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid username!",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Username" />
            </Form.Item> */}

            {/* <Form.Item
                            name="password"
                            label="Password"
                            className="input-field"
                            hasFeedback
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item> */}

            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please enter  phone number!" },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter The Correct Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input type={"number"} placeholder="Phone number" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter  address!",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 charactera only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="role"
              label="User Role"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Role!",
                },
              ]}
            >
              <Select
                placeholder="Please select Role"
                value={options.value}
                options={options}
                defaultValue={options[""]}
              ></Select>
            </Form.Item>

            <Form.Item
              name="project_id"
              label="Select Projects"
              rules={[
                {
                  required: true,
                  message: "Please select one or more projects!",
                },
              ]}
              className="input-field"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Projects"
                onChange={handleChange}
              >
                {children}
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="btn-user">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_fields} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={resetModal}
          footer={null}
          onCancel={handleCancel}
          width={800}
        >
          <div className="edit__user">Reset User Password</div>
          <Form
            form={form2}
            name="reset_password"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            {/* <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
              initialValue={rowName}
            >
                
              <Input placeholder="Name" disabled/>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your E-mail!",
                },
              ]}
              initialValue={rowMail}
            >
              <Input placeholder="Email" disabled/>
            </Form.Item> */}
            <Form.Item
              name="password"
              label="Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/,
                  message:
                    "Password must contain at least  uppercase letter,  lowercase letter, and  number, and be at least 6 to 12 characters long",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <div className="btn-user">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <br />
        {data.length > 0 && (
          <Button
            style={{ display: activedata ? "" : "none" }}
            className="deleteallbutton copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )}
      </Spin>
    </>
  );
};

export default React.memo(UsersShow);
