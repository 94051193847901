import { Button, Select, Table } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectorgetAllGrnItemCodeWise,
  selectorGetItemCodeWiseRecivingProcess,
  selectorGetMaterialManagementData,
} from "../../../../redux-core/materials/selectors";

const { Option } = Select;
function ShowItemsWiseStockQtyFrom() {
  const getGrnItemCodeWise = useSelector(selectorgetAllGrnItemCodeWise);
  const getItemRecivingProcess = useSelector(
    selectorGetItemCodeWiseRecivingProcess
  );
  const [array, setArray] = useState("");
  const getMaterialManagementData = useSelector(
    selectorGetMaterialManagementData
  );

  const data = [];

  for (let i = 0; i < getItemRecivingProcess.length; i++) {
    data.push({
      item_code: getItemRecivingProcess[i].item_code,
      Item_name: getItemRecivingProcess[i].Item_name,
      accept_qty: getItemRecivingProcess[i].accept_qty,
      rcv_qty: getItemRecivingProcess[i].rcv_qty,
      issue_qty: getItemRecivingProcess[i].issue_qty,
      replace_qty: getItemRecivingProcess[i].replace_qty,
      site_transfer_qty: getItemRecivingProcess[i].site_transfer_qty,
      transfer_to_other_site: getItemRecivingProcess[i].transfer_to_other_site,
      without_po_qty: getItemRecivingProcess[i].without_po_qty,
      total_qty: getItemRecivingProcess[i].total_qty,
    });
  }

  //---------write the function for item code wise comes data  sum value shows--
  const tableData = [];
  let rcvQtySum = 0;
  let accQtySum = 0;
  for (let i = 0; i < getGrnItemCodeWise.length; i++) {
    const item = getGrnItemCodeWise[i];
    const grnDateObject = new Date(item.grn_date);
    tableData.push({
      index: i + 1,
      item_code: item.item_code,
      material_name: item.material_name,
      po_number: item.po_number,
      po_date: item.po_date,
      grn_no: item.grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      rcv_qty: item.rcv_qty,
      accept_qty: item.accept_qty,
    });
    rcvQtySum += item.rcv_qty;
    accQtySum += item.accept_qty;
  }

  // Add sum row
  tableData.push({
    index: "Total",
    rcv_qty: rcvQtySum,
    accept_qty: accQtySum,
  });

  // table colunm
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "index",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
    },
    {
      title: "PO Date",
      dataIndex: "po_date",
    },
    {
      title: "GRN NO.",
      dataIndex: "grn_no",
    },
    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
  ];

  const columns2 = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Material Name",
      dataIndex: "Item_name",
    },
    {
      title: "Receiving Quantity",
      dataIndex: "rcv_qty",
    },
    {
      title: "Accept Quantity",
      dataIndex: "accept_qty",
    },
    {
      title: "Issue Quantity",
      dataIndex: "issue_qty",
    },
    {
      title: "Local Purchase Receiving Quantity",
      dataIndex: "without_po_qty",
    },
    {
      title: "Replace Quantity",
      dataIndex: "replace_qty",
    },
    {
      title: "Other Site Receiving Quantity",
      dataIndex: "site_transfer_qty",
    },
    {
      title: "Other Site Transfer Quantity",
      dataIndex: "transfer_to_other_site",
    },
    {
      title: "Total Balance Quantity",
      dataIndex: "total_qty",
    },
  ];

  const columns3 = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
    },
    {
      title: "Po Number",
      dataIndex: "po_number",
    },
    {
      title: "GRN Number",
      dataIndex: "grn_no",
    },
    {
      title: "Receive Quantity",
      dataIndex: "rcv_qty",
    },
    {
      title: "Local Purchase Invoice Number",
      dataIndex: "invoice_number",
    },
    {
      title: "Local Purchase QTY",
      dataIndex: "qty",
    },
    {
      title: "Replace Item GRN Number ",
      dataIndex: "replace_grn_no",
    },
    {
      title: "Replace Item GRN Qty ",
      dataIndex: "replace_qty",
    },
    {
      title: "Site Transfer Number ",
      dataIndex: "transfer_number",
    },
    {
      title: "Site Transfer QTY ",
      dataIndex: "transfer_qty",
    },
  ];

  const [hideTable, setHideTable] = useState(false);
  const [cardType, setCardType] = useState("");
  const handleCardTypeChange = (value) => {
    setCardType(value);
    setHideTable(true);
  };

  return (
    <>
      <Select
        onChange={handleCardTypeChange}
        className="input-field"
        placeholder="Select The Process"
      >
        <Option value="Material_manegment">Material Management Data</Option>
        <Option value="Item_History">Po wise Item history</Option>
        <Option value="Other_material_received">
          Other Process Material Received QTY
        </Option>
      </Select>

      {cardType === "Item_History" && (
        <div>
          <div className="title-show-project">List of PO Wise Item History</div>

          <Table
            className="custom-table"
            dataSource={Array.isArray(array) ? array : tableData}
            columns={columns}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          />
        </div>
      )}

      {cardType === "Other_material_received" && (
        <div>
          <div className="title-show-project">
            List of Other Process Material Received QTY
          </div>

          <Table
            className="custom-table"
            dataSource={data}
            columns={columns2}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          />
        </div>
      )}
      {cardType === "Material_manegment" && (
        <div>
          <div className="title-show-project">
            List of Material Management Data
          </div>
          <Table
            className="custom-table"
            dataSource={getMaterialManagementData}
            columns={columns3}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          />
        </div>
      )}
    </>
  );
}

export default ShowItemsWiseStockQtyFrom;
