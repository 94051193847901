import React, { useState } from "react";
import { Button, Table, Modal, message } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorShowCurrentStock,
  selectorGetItemInCurrentStockProjectWise,
} from "../../../../redux-core/materials/selectors";
import {
  getItemCodeWiseGrn,
  getItemCodeWiseRecievedprocess,
  getStockManagementData,
} from "../../../../redux-core/materials/actions";
import useSearch from "../../../../hooks/useSearch";
import ShowItemsWiseStockQtyFrom from "./ShowItemsWiseStockQtyFrom";
import { CSVLink } from "react-csv";

const actionDispatch = (dispatch) => ({
  getItemCodeWiseGrn: (itemCode, approved, projectID) =>
    dispatch(getItemCodeWiseGrn(itemCode, approved, projectID)),
  getItemCodeWiseRecievedprocess: (itemCode, projectID) =>
    dispatch(getItemCodeWiseRecievedprocess(itemCode, projectID)),
  getStockManagementData: (itemCode, projectID) =>
    dispatch(getStockManagementData(itemCode, projectID)),
});

const ShowMaterialStock = (props) => {
  const currentStockShow = useSelector(selectorShowCurrentStock);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [array, setArray] = useState("");
  const {
    getItemCodeWiseGrn,
    getItemCodeWiseRecievedprocess,
    getStockManagementData,
  } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();

  //------------------------------------ write the for lop for table data show ---------------------------------
  const tableData = [];
  for (let i = 0; i < getShowItemInCurrentStockProjectWise.length; i++) {
    tableData.push({
      // id:getShowItemInCurrentStockProjectWise[i].id,
      id: i + 1,
      //item_id:getShowItemInCurrentStockProjectWise[i].item_id,
      item_code: getShowItemInCurrentStockProjectWise[i].item_code,
      Item_name: getShowItemInCurrentStockProjectWise[i].Item_name,

      rcv_qty:
        Number(getShowItemInCurrentStockProjectWise[i].rcv_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].without_po_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].site_transfer_qty),
      accept_qty:
        Number(getShowItemInCurrentStockProjectWise[i].accept_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].without_po_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].replace_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].site_transfer_qty),
      issue_qty: getShowItemInCurrentStockProjectWise[i].issue_qty,
      total_qty: getShowItemInCurrentStockProjectWise[i].total_qty,
      item_rate: getShowItemInCurrentStockProjectWise[i].item_rate,
      // without_po_qty: getShowItemInCurrentStockProjectWise[i].without_po_qty,
      // replace_qty: getShowItemInCurrentStockProjectWise[i].replace_qty,
      // site_transfer_qty: getShowItemInCurrentStockProjectWise[i].site_transfer_qty,
      // transfer_to_other_site: getShowItemInCurrentStockProjectWise[i].transfer_to_other_site,
    });
  }

  // let tableData = [];
  // let itemCodeMap = {};
  // for (let i = 0; i < currentStockShow.length; i++) {
  //   const currentItem = currentStockShow[i];
  //   const itemCode = currentItem.item_code;
  //   if (itemCodeMap.hasOwnProperty(itemCode)) {
  //     // Item code already exists, update the quantities
  //     itemCodeMap[itemCode].rcv_qty += currentItem.rcv_qty;
  //     itemCodeMap[itemCode].accept_qty += currentItem.accept_qty;
  //     itemCodeMap[itemCode].issue_qty += currentItem.issue_qty;
  //     itemCodeMap[itemCode].total_qty += currentItem.total_qty;
  //   } else {
  //     // Item code does not exist, add it to the map
  //     itemCodeMap[itemCode] = {
  //       //id: currentItem.id,
  //       id: i + 1,
  //       item_id: currentItem.item_id,
  //       item_code: itemCode,
  //       material_name: currentItem.material_name,
  //       hsn_code: currentItem.hsn_code,
  //       uom: currentItem.uom,
  //       rcv_qty: currentItem.rcv_qty,
  //       accept_qty: currentItem.accept_qty,
  //       issue_qty: currentItem.issue_qty,
  //       total_qty: currentItem.total_qty,
  //     };
  //   }
  // }
  // Convert the itemCodeMap to an array of objects
  // for (const itemCode in itemCodeMap) {
  //   tableData.push(itemCodeMap[itemCode]);
  // }
  //

  //-------------write the function for table data item code wise Grn data show ---------------

  const HandelOpenItemWiseGrn = (record) => {
    setIsModalOpen(true);
    getItemCodeWiseGrn(record, 1, props.projectID);
    getItemCodeWiseRecievedprocess(record, props.projectID);
    getStockManagementData(record, props.projectID);
  };

  //--modalclose function-----

  const modalClose = () => {
    setIsModalOpen(false);
  };

  //----table Colunm--------
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Receipts Statement",
      dataIndex: "item_code",
      render: (record) => {
        return (
          <Button
            className="button-61"
            onClick={() => HandelOpenItemWiseGrn(record)}
          >
            Item Receipts Statement
          </Button>
        );
      },
      width: 200,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
      ...getColumnSearchProps("Item_name"),
    },

    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
    {
      title: "Quantity Issued",
      dataIndex: "issue_qty",
    },
    {
      title: "Quantity Balance",
      dataIndex: "total_qty",
    },
    {
      title: "Total Amount",
      dataIndex: "item_rate",
    },

    // {
    //   title: "WithOut Po QTY",
    //   dataIndex: "without_po_qty",
    // },
    // {
    //   title: "Replace QTY",
    //   dataIndex: "replace_qty",
    // },
    // {
    //   title: "Recive Other Site QTY",
    //   dataIndex: "site_transfer_qty",
    // },
    // {
    //   title: "Transfer Other Site QTY",
    //   dataIndex: "transfer_to_other_site",
    // },
  ];

  const backToAllProject=()=>{
    props.backProject()
  }

  return (
    <>
      <div className="btn__">
        <Button className="excel">
          <CSVLink // CSV Of List Work Category
            filename={"Project Material Stock.csv"}
            data={tableData}
            className="excel"
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to Excel
          </CSVLink>
        </Button>
      </div>
      <div>

      <div className="title-show-project">Current Stock</div> <br />
      <Button onClick={() => backToAllProject()}>All Projects</Button>
      <br /> <br />

        <Table
          scroll={{ x: "100vw" }}
          columns={columns}
          dataSource={Array.isArray(array) ? array : tableData}
          pagination={10}
        />
      </div>
      <Modal
        width={1950}
        footer={null}
        visible={isModalOpen}
        onCancel={modalClose}
      >
        <ShowItemsWiseStockQtyFrom />
      </Modal>
    </>
  );
};

export default ShowMaterialStock;
