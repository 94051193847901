import React, { useEffect, useState } from 'react'
import "antd/dist/antd.min.css";
import CreateIndentForm from './Forms/CreateIndentForm';
import { Spin, Button, Card, Image  } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import { makeSelectAllProj } from '../../../redux-core/settings/selectors';
import { useCallback } from 'react';
import { getAllProj, getProjectByprojectId } from '../../../redux-core/settings/actions';
import { selectorGetAllUserWiseAssignProjects } from '../../../redux-core/userManagement/selectors';
import { getAllPurchaseOrders, getCurrentStockProjectIdWise } from '../../../redux-core/materials/actions';
import { getAllWorkOrders, getBOQExcelData, getBOQSheetNames } from '../../../redux-core/contracts/actions';
import { getAllAssignProjectId } from '../../../redux-core/userManagement/actions';
import { selectorAllWorkOrderDetails } from '../../../redux-core/contracts/selectors';

const actionDispatch= (dispatch) =>({
   projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
   getProjectByprojectId: (projectID) =>
    dispatch(getProjectByprojectId(projectID)),
    getAllPurchaseOrders: (projectID) =>
    dispatch(getAllPurchaseOrders(projectID)),
    getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
    getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
    getCurrentStockProjectIdWise: (projectID) => dispatch(getCurrentStockProjectIdWise(projectID)),
})

const CreateIndent = () => {
   const {  
      getProjectByprojectId,
      getAllPurchaseOrders,
      getAllWorkOrders,
      projects,
      getAllAssignProjectId,

    } = actionDispatch(useDispatch());
    const getAllAssignProjectIds = useSelector(
      selectorGetAllUserWiseAssignProjects
    );
   // const Spindata = useSelector((item) => item.spinreducer);
   const [content, setContent] = useState("1");
   const logindetails = useSelector(makeSelectLoginDetail);
   const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
   const allProjectsSelectorData = useSelector(makeSelectAllProj);
   const [projectId, setProjectId] = useState();
 
console.log("allProjectsSelectorData",allProjectsSelectorData)


  
  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(orgId, userId);
    getAllWorkOrders(projectId);
    getAllPurchaseOrders(projectId);
    getCurrentStockProjectIdWise(projectId);
  }, [projectId]);

    const ProjID = [];
    for (let i = 0; i < getAllAssignProjectIds.length; i++) {
      ProjID.push({
        Project_id: getAllAssignProjectIds[i].project_id,
      });
    }

    const projectData = ProjID.flatMap((item) => {
      return allProjectsSelectorData.filter(
        (items) => items.project_id == item.Project_id
      );
    });

   const backProject=()=>{
      setContent("1")
    }

    const onAddPurchaseBtn = useCallback((projectID) => {
      getAllWorkOrders(projectID);
      setContent("2");
      setProjectId(projectID);
      getProjectByprojectId(projectID);
      getAllPurchaseOrders(projectID);
      getCurrentStockProjectIdWise(projectID);
     
    }, []);
    

  return (
   <>
   {logindetails.user.role === "ADMIN" ? (
     <span className="card-container">
       {content === "1"
         ? allProjectsSelectorData.map((data) => {
             return (
               <Card
                 className="projectCard"
                 // hoverable
                 style={{ width: 250, borderRadius: "5%" }}
                 key={data.project_id}
                 onClick={() => onAddPurchaseBtn(data.project_id)}
               >
                 <div className="sub-main">
                   <div className="top">
                     <span className="left__value">
                       <b>Project Name: </b>
                       {data.project_name}
                     </span>
                     <span className="right__icon">
                       <Image width={50} src={data.image} alt="No Image" />
                     </span>
                   </div>
                   <div className="bottom__title">
                     <b>Description: </b>
                     {data.description}
                   </div>
                 </div>
               </Card>
             );
           })
         : ""}
     </span>
   ) : (
     <span className="card-container">
       {content === "1"
         ? projectData.map((data) => {
             return (
               <Card
                 className="projectCard"
                 // hoverable
                 style={{ width: 250, borderRadius: "5%" }}
                 key={data.project_id}
                 onClick={() => onAddPurchaseBtn(data.project_id)}
               >
                 <div className="sub-main">
                   <div className="top">
                     <span className="left__value">
                       <b>Project Name: </b>
                       {data.project_name}
                     </span>
                     <span className="right__icon">
                       <Image width={50} src={data.image} alt="No Image" />
                     </span>
                   </div>
                   <div className="bottom__title">
                     <b>Description: </b>
                     {data.description}
                   </div>
                   {/* <div className="work_order">
                     <Button onClick={()=>onAddPurchaseBtn(data.project_id)} className="work_order_btn">Material Receipt</Button>
                  </div> */}
                 </div>
               </Card>
             );
           })
         : ""}
     </span>
   )}

   {content === "2" ? <CreateIndentForm projectID={projectId}  backProject={backProject}/> : ""}
 </>
  )
}

export default CreateIndent