import React, { useCallback, useEffect, useState,useRef } from "react";
import { ContainerOutlined } from "@ant-design/icons";
import { Button, Card, Image } from "antd";
import "./css/MyProjects.css";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import ShowMaterialStock from "./Forms/ShowMaterialStock";
import { getCurrentStockProjectIdWise } from "../../../redux-core/materials/actions";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";

const { Meta } = Card;



const actionDispatch = (dispatch) => ({
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getCurrentStockProjectIdWise: (projectID) => dispatch(getCurrentStockProjectIdWise(projectID)),
  getAllAssignProjectId: (orgId,userID) => dispatch(getAllAssignProjectId(orgId,userID)),

});
const Throttle = (callback, delay) => {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(callback, delay);
  };
};

function StoreMaterial() {
  const [content, setContent] = useState("1");
  const [projectId, setProjectId] = useState();
  const logindetails = useSelector(makeSelectLoginDetail);
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const { projects, getCurrentStockProjectIdWise,getAllAssignProjectId } = actionDispatch(useDispatch());
  const getAllAssignProjectIds=useSelector(selectorGetAllUserWiseAssignProjects)
  


  const containerRef = useRef(null);
  useEffect(() => { 
    const handleResize = () => {   
     console.log('Container resized!');
    };
    // Create a ResizeObserver with throttled callback
    const resizeObserver = new ResizeObserver(Throttle(handleResize, 100));
    // Observe the container element
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    // Clean up the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Emp


  //------useEffect for project api triggering---------------
  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(logindetails.organisation.orgID, logindetails.user.id);
  }, []);



  // --------write the function here all card submit action --------
  const onAddPurchaseBtn = useCallback((projectID) => {
    setContent("2");
    setProjectId(projectID)
    getCurrentStockProjectIdWise(projectID)
  }, []);

  
  //select dropdown Assign project wise show 
  
  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id
    });
  }
  
  const projectData = ProjID.flatMap((item) => {
    return allProjectsSelectorData.filter((items) => items.project_id == item.Project_id);
  });
  
  const backProject=()=>{
    setContent("1")
  }
  
  return (
    <>
    {logindetails.user.role === "ADMIN" ? (
    <span className="card-container">
        {content === "1"
          ? allProjectsSelectorData.map((data) => {
            return (
              <Card
                className="projectCard"
                // hoverable
                style={{ width: 250, borderRadius: "5%" }}
                key={data.project_id}
                onClick={() => onAddPurchaseBtn(data.project_id)}
              >
                <div className="sub-main">
                  <div className="top">
                    <span className="left__value"><b>Project Name: </b>{data.project_name}</span>
                    <span className="right__icon"><Image width={50} src={data.image} alt="No Image" /></span>
                  </div>
                  <div className="bottom__title"><b>Description: </b>{data.description}</div>
                  {/* <div className="work_order">
                    <Button onClick={() => onAddPurchaseBtn(data.project_id)} className="work_order_btn">Show Material Stock</Button>
                  </div> */}

                </div>
              </Card>
            );
          })
          : ""}
      </span>
      ):(
<span className="card-container">
        {content === "1"
          ? projectData.map((data) => {
            return (
              <Card
                className="projectCard"
                // hoverable
                style={{ width: 250, borderRadius: "5%" }}
                key={data.project_id}
                onClick={() => onAddPurchaseBtn(data.project_id)}
              >
                <div className="sub-main">
                  <div className="top">
                    <span className="left__value"><b>Project Name: </b>{data.project_name}</span>
                    <span className="right__icon"><Image width={50} src={data.image} alt="No Image" /></span>
                  </div>
                  <div className="bottom__title"><b>Description: </b>{data.description}</div>
                  {/* <div className="work_order">
                    <Button onClick={() => onAddPurchaseBtn(data.project_id)} className="work_order_btn">Show Material Stock</Button>
                  </div> */}

                </div>
              </Card>
            );
          })
          : ""}
      </span>

      )}
      
      {content === "2" ? <ShowMaterialStock projectID={projectId} backProject={backProject} /> : ""}
    </>
  )
}
export default StoreMaterial;