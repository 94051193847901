import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Modal,
  InputNumber,
  Space,
  message,
  DatePicker,
  Card,
  Select,
  Table
} from "antd";
import {
  selectorAllBOQExcelData,
  selectorAllSheetNamesData,
} from "../../../../redux-core/contracts/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getBOQExcelData,
  getBOQSheetNames,
} from "../../../../redux-core/contracts/actions";
import { selectorGetItemInCurrentStockProjectWise } from "../../../../redux-core/materials/selectors";
import { getCurrentStockProjectIdWise, newIndentMaterialDetails } from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getAllBOQExcelData: (workOrderNumber, sheetName) =>
    dispatch(getBOQExcelData(workOrderNumber, sheetName)),

  getCurrentStockProjectIdWise: (projectID) =>
    dispatch(getCurrentStockProjectIdWise(projectID)),

    createIndentMaterialDetails: (data) => dispatch(newIndentMaterialDetails(data))
});

const AddCreateIntendForm = (props) => {
  const { getAllBOQExcelData, getCurrentStockProjectIdWise, createIndentMaterialDetails } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
   const orgId = logindetails.organisation.orgID;
   const userId = logindetails.user.id;
  // States //
  const [openNill, setOpenNill] = useState("");
  const [selectedBoqItem, setSelectedBoqItem] = useState();
const [selectAvailableDesc, setSelectAvailableDesc] = useState()
const [showIndentTable, setShowIndentTable] = useState(false)
const [ storeFormData, setStoreFormData] = useState([])
const [sendResponseDetails, setSendResponseDetails] = useState();

console.log("storeFormData", storeFormData)
  // Selectors //
  const boqExcelData = useSelector(selectorAllBOQExcelData);
  const boqSheetNames = useSelector(selectorAllSheetNamesData);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );

  useEffect(() => {
    getCurrentStockProjectIdWise(props.projectId);
    form.setFieldsValue({
      work_order_boq_item_no: selectedBoqItem?.sno,
      boq_quantity:selectedBoqItem?.quantity,
      uom:selectedBoqItem?.unit,
      quantity_in_stock: selectAvailableDesc?.total_qty,
      quantity_ordered_so_far: selectAvailableDesc?.accept_qty,

    });
  }, [props.projectId, selectedBoqItem, selectAvailableDesc]);
  console.log("selectedBoqItem?.sno", selectedBoqItem?.sno);


  const handelSheet = (value) => {
    getAllBOQExcelData(props.data.work_order_no, value);
  };

  const handelOpenNill = (values) => {
    setOpenNill(values);
    // getCurrentStockProjectIdWise(values)
  };

  const HandelBoqItemSelect = (valuess) => {
    const itemDetails = boqExcelData?.allBOQExcelData.find((items) => items.id == valuess)
    console.log("itemDetails",itemDetails)
    setSelectedBoqItem(itemDetails);
  }

  const handelAvailableStocks = (value) => {
    console.log("value}}}",value)
    const stockDetails = getShowItemInCurrentStockProjectWise?.find((items) => items.id == value);
  
    if (stockDetails) {
      console.log("stockDetails", stockDetails);
      setSelectAvailableDesc(stockDetails);
    } else {
      // Handle the case when getShowItemInCurrentStockProjectWise is empty or undefined
      console.log("Stock details not found");
      setSelectAvailableDesc(null); // You can set it to null or handle it differently
    }
  };
  console.log("props.sendResponse",props.sendResponse)

  const columns = [
    {
      title: "Work Order Sheet Name in BOQ",
      dataIndex: "sheet",
    },
    {
      title: "Work Order Item Description in BOQ",
      dataIndex: "work_order_sheet_description",
    },
    {
      title: "Work Order BOQ Item No.",
      dataIndex: "work_order_boq_item_no",
    },
    {
      title: "Is Item Available In Master List",
      dataIndex: "types_of_received_material",
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "BOQ Quantity",
      dataIndex: "boq_quantity",
    },
    {
      title: "Item Specification",
      dataIndex: "item_specification",
    },
    {
      title: "Quantity Ordered so far",
      dataIndex: "quantity_ordered_so_far",
    },
    {
      title: "Quantity in stock",
      dataIndex: "quantity_in_stock",
    },
    {
      title: "Quantity required now",
      dataIndex: "quantity_required_now",
    },
    {
      title: "Required Buy Date",
      dataIndex: "required_date",
    },
  ];

const onAddIndentFormFinish = async (value) =>{
 
  setShowIndentTable(true)
  
  let data = {
    ...value,
    ...{
    indent_header_id: props?.sendResponse?.id,
    project_id: props.projectId,
    orgID: orgId,
    created_by: userId,
    }
  }
  let response = await createIndentMaterialDetails(data)
  const itemName=getShowItemInCurrentStockProjectWise.find((item)=>item.id==value.item_description)
  console.log("itemName",itemName?.Item_name)
  const boqItemName=boqExcelData?.allBOQExcelData.find((item)=>item.id==value.work_order_sheet_description)
  console.log("boqItemName",boqItemName?.description)
  let TableData = {
    ...value,
    ...{
    indent_header_id: props?.sendResponse?.id,
    project_id: props.projectId,
    orgID: orgId,
    created_by: userId,
    item_description:itemName?.Item_name === undefined ? value.item_description : itemName?.Item_name,
    work_order_sheet_description:boqItemName?.description
    }
  }
  if(response){
    handelData([TableData])
    // setSendResponseDetails(response?.materials)
    clear_Project_field()
  }
  // setStoreFormData(value)
  console.log("showIndent", value)
  console.log("response", response)
}

const handelData = (responseData) =>{
  console.log("responsedata", responseData)
  if (!Array.isArray(responseData)) {
    return;
  }
for(let i = 0; i< responseData.length; i++){


  // console.log("hours",hours)
  



  let newData = responseData.map((item, index)=>({
  
    project_id:item.project_id,
    indent_header_id: item.indent_header_id,
    sheet: item.sheet,
    work_order_sheet_description: item.work_order_sheet_description,
    work_order_boq_item_no: item.work_order_boq_item_no,
    types_of_received_material: item.types_of_received_material,
    item_description: item.item_description,
    uom: item.uom=== null ? 0 : item.uom,
    boq_quantity: item.boq_quantity,
    item_specification: item.item_specification,
    quantity_ordered_so_far: item.quantity_ordered_so_far,
    quantity_in_stock: item.quantity_in_stock,
    quantity_required_now: item.quantity_required_now,
    required_date:item.required_date.toISOString().split('T')[0],
  }))
  setStoreFormData((prevData) => [...prevData, ...newData]);
  console.log("newData}}",newData)

}
}

const onAddIndentFinishFailed = (error) =>{
console.log("faild", error)
}

const clear_Project_field = () => {
form.setFieldsValue({
  sheet: "",
  work_order_sheet_description: "",
  work_order_boq_item_no: "",
  types_of_received_material: "",
  item_description: "",
  uom: "",
  boq_quantity: "",
  item_specification: "",
  quantity_ordered_so_far: "",
  quantity_in_stock: "",
  quantity_required_now: "",
  required_date: "",
})
}

  return (
    <>
    <Card hoverable style={{ borderTop: "1px solid black" }}>
      <Form
        form={form}
        name="add-project"
        size="large"
        onFinish={onAddIndentFormFinish}
          onFinishFailed={onAddIndentFinishFailed}
      >
        <Form.Item
          name="sheet"
          label="Work Order Sheet Name in BOQ"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Sheet !",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            onChange={(sheetname) => handelSheet(sheetname)}
          >
            {boqSheetNames.sheetNames.map((item) => (
              <option value={item.sheetname}>{item.sheetname}</option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="work_order_sheet_description"
          label="Work Order Item Description in BOQ"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Desription !",
            },
          ]}
        >
          <Select
            onChange={(id) => HandelBoqItemSelect(id)}
            allowClear
            style={{ width: "100%" }}
          >
            {boqExcelData?.allBOQExcelData?.map((item) => (
              <option value={item?.id}>{item?.description}</option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="work_order_boq_item_no"
          label="Work Order BOQ Item No."
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Work Order BOQ Item No. !",
            },
          ]}
          defaultValue={selectedBoqItem?.sno}
        >
          <Input disabled/>
        </Form.Item>
        

        <Form.Item
          name="types_of_received_material"
          label="Is Item Available In Master List"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Types of Received Materials !",
            },
          ]}
        >
          <Select
            placeholder="Select Item Description"
            onChange={handelOpenNill}
          >
            <option value="nill">NA</option>
            <option value="available">Available</option>
          </Select>
        </Form.Item>
        {openNill === "nill" &&
        
        <Form.Item
          name="item_description"
          label="Item Description"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select Item Description !",
            },
          ]}
        ><Input /></Form.Item>
        }
 {openNill === "available" && (
        <Form.Item
          name="item_description"
          label="Item Description"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select Item Description !",
            },
          ]}
        >

            <Select
              placeholder="Select Item Description"
              allowClear
              style={{ width: "100%" }}
              onChange={(id)=>handelAvailableStocks(id)}
            >
              {getShowItemInCurrentStockProjectWise.map((item) => (
                <option value={item.id}>{item.Item_name}</option>
              ))}
            </Select>
         
        </Form.Item>
 )}
        <Form.Item
          name="uom"
          label="UOM"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select UOM !",
            },
          ]}
          defaultValue={selectedBoqItem?.unit}
        >
         <Input disabled />
        </Form.Item>

        <Form.Item
          name="boq_quantity"
          label="BOQ Quantity"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select UOM !",
            },
          ]}
          defaultValue={selectedBoqItem?.quantity}
        >
          <Input  disabled/>
        </Form.Item>

        <Form.Item
          name="item_specification"
          label="Item Specification"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please select Item Specification !",
            },
          ]}
        >
       <Input />
        </Form.Item>

        <Form.Item
          name="quantity_ordered_so_far"
          label="Quantity Ordered so far"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter Quantity Ordered so far !",
            },
          ]}
          defaultValue={selectAvailableDesc?.accept_qty}
        >
          <Input placeholder="Enter Quantity Ordered so far" />
        </Form.Item>

        <Form.Item
          name="quantity_in_stock"
          label="Quantity in stock"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter Quantity in stock !",
            },
          ]}
          defaultValue={selectAvailableDesc?.total_qty}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="quantity_required_now"
          label="Quantity required now"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enterQuantity required now !",
            },
          ]}
        >
          <Input placeholder="Enter Quantity required now" />
        </Form.Item>

        <Form.Item
          name="required_date"
          label="Required Buy Date"
          className="firstRow"
          rules={[
            {
              required: true,
              message: "Please select Required Date !",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            // onChange={handleStartDateChange}
          />
        </Form.Item>

        <Form.Item>
          <div className="btn-project">
          <Button type="primary" htmlType="submit" className="btn-submit">
           <strong>Submit</strong>
         </Button>

            <Button
              onClick={clear_Project_field}
              className="btn-cancel"
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
    <div>
        {" "}
      {showIndentTable ? (
          <Table
          scroll={{ x: "50vw" }}
          columns={columns} 
         dataSource={storeFormData}
          pagination={10}
        />
        ) : (
          ""
        )}
      </div>
     
    </>
  );
};

export default AddCreateIntendForm;
