import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPurchaseOrders,
  getGrnQty,
  getGrnItemRcvRejQty,
  createPoReplacementItemData,
  getCurrentStockItemCodeWise,
  insertPoReplacementItems,
  updateReplaceItemQtyInStock,
  updateReplaceQtyInGrnTable,
  getPoReplacementItemData,
  createGrnNumbers,
} from "../../../../redux-core/materials/actions";
import {
  selectorGetPurchaseOrder,
  selectorGetGrnQty,
  selectorGrnItemsRecRejQty,
  selectorGetItemInCurrentStockItemCodeWise,
  selectorCountGrnNumber,
} from "../../../../redux-core/materials/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { getCountGrnNumber } from "../../../../redux-core/materials/actions";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";

const actionDispatch = (dispatch) => ({
  getAllPurchaseOrders: (projId) => dispatch(getAllPurchaseOrders(projId)),
  getGrnQty: (poNumber) => dispatch(getGrnQty(poNumber)),
  getGrnItemRcvRejQty: (grnNumber) => dispatch(getGrnItemRcvRejQty(grnNumber)),
  createPoReplacementItemData: (replaceData) =>
    dispatch(createPoReplacementItemData(replaceData)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
  insertPoReplacementItems: (replace) =>
    dispatch(insertPoReplacementItems(replace)),
  updateReplaceItemQtyInStock: (replace) =>
    dispatch(updateReplaceItemQtyInStock(replace)),
  updateReplaceQtyInGrnTable: (replace) =>
    dispatch(updateReplaceQtyInGrnTable(replace)),
  getPoReplacementItemData: (projectID) =>
    dispatch(getPoReplacementItemData(projectID)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountGrnNumber: (projeID) => dispatch(getCountGrnNumber(projeID)),
  createGrnNumbers: (grn) => dispatch(createGrnNumbers(grn)),
});

const { Option } = Select;
function PoItemsReplaceFrom(props) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const {
    getAllPurchaseOrders,
    getGrnQty,
    getGrnItemRcvRejQty,
    createPoReplacementItemData,
    getCurrentStockItemCodeWise,
    insertPoReplacementItems,
    updateReplaceItemQtyInStock,
    updateReplaceQtyInGrnTable,
    getPoReplacementItemData,
    getProjectByprojectId,
    getCountGrnNumber,
    createGrnNumbers,
  } = actionDispatch(useDispatch());
  const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
  const getAllGrnQty = useSelector(selectorGetGrnQty);
  const getGrnitemsRcvRejQty = useSelector(selectorGrnItemsRecRejQty);
  const getShowItemInCurrentStockItemCodeWise = useSelector(
    selectorGetItemInCurrentStockItemCodeWise
  );
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [poNumber, setPoNumber] = useState();
  const [replaceDate, setReplaceDate] = useState();
  const [selectGrn, setSelectGrn] = useState();
  const [selectItem, setSelectItem] = useState();
  const [replaceNumber, setReplaceNumber] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const [hideForm, setHideForm] = useState(false);

  //useEffect write

  useEffect(() => {
    getCountGrnNumber(props.projectID);
    getProjectByprojectId(props.projectID);
    getAllPurchaseOrders(props.projectID);
    getGrnQty(poNumber);
    getGrnItemRcvRejQty(selectGrn);
  }, [props.projectID, poNumber, selectGrn]);

  //search enegine function write here
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  // In Date picker dateString find
  const handleReplaceDate = (value, dateString) => {
    setReplaceDate(dateString);
  };

  // find the Item Code in Grn Item Reject table
  const itemCode = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.item_code);

  //find the Qty in Grn Item Reject table
  const rejQty = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.reject_qty);

  //find the Id in Grn Item Reject table
  const GrnId = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.id);

  // show grn table item rate
  const ItemRate = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.item_rate);

  //find item id in current stock by item code wise
  const StockItemId = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.id);

  //find item total qty in current stock by item code wise
  const StockItemQty = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.total_qty);

  //find item Replace qty in current stock by item code wise
  const StockReplaceItemQty = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.replace_qty);

  const StockItemValue = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.item_rate);

  const replace_value = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.replace_value);

  // useEffect Write For show initial Value

  useEffect(() => {
    form2.setFieldsValue({
      item_code: itemCode[0],
      reject_qty: rejQty[0],
    });
    getCurrentStockItemCodeWise(itemCode[0], props.projectID);
  }, [itemCode[0], rejQty[0]]);

  //   replacement data submit
  const HandelReplacementPo = async (value) => {
    setReplaceNumber(value.replace_grn_no);
    const replaceData = {
      po_number: poNumber,
      replace_date: value.replace_date,
      replace_grn_no: value.replace_grn_no,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };
    let response = await createPoReplacementItemData(replaceData);
    if (response) {
      setHideForm(true);
      setIsDisable(true);
    }

    const Grn_number_insert = {
      project_id: props.projectID,
      grn_number: value.replace_grn_no,
      grn_date: value.replace_date,
      orgID: orgId,
      created_by: userId,
    };
    await createGrnNumbers(Grn_number_insert);
  };

  const resetValue = () => {
    form2.setFieldsValue({
      item_id: "",
      item_code: "",
      reject_qty: "",
      replace_qty: "",
    });
  };

  // po item Replacement submit function
  const HandelReplacementItems = async (value) => {
    const itemRates = Number(ItemRate?.[0]) * Number(value.replace_qty);
    const updateItemStock = {
      id: StockItemId[0],
      replace_qty: Number(StockReplaceItemQty[0]) + Number(value.replace_qty),
      total_qty: Number(StockItemQty[0]) + Number(value.replace_qty),
      item_rate: Number(StockItemValue?.[0]) + Number(itemRates),
      replace_value: Number(replace_value?.[0]) + Number(itemRates),
    };

    const updateInGrn = {
      id: GrnId[0],
      reject_qty: Number(rejQty) - Number(value.replace_qty),
    };

    const addReplaceItem = {
      project_id: props.projectID,
      replace_grn_no: replaceNumber,
      grn_no: value.grn_no,
      item_id: selectItem,
      item_code: value.item_code,
      reject_qty: value.reject_qty,
      replace_qty: value.replace_qty,
      orgID: orgId,
      created_by: userId,
    };
    let response = await insertPoReplacementItems(addReplaceItem);
    if (response) {
      if (response.success === true) {
        await updateReplaceItemQtyInStock(updateItemStock);
        await updateReplaceQtyInGrnTable(updateInGrn);
      }
      resetValue();
      getPoReplacementItemData(props.projectID);
    }
  };
  const resetAllValue = () => {
    setHideForm(false);
    form.resetFields();
    form2.resetFields();
    setIsDisable(false);
    getCountGrnNumber(props.projectID);
  };

  let count_number =
    countGrnNumber[0].no_of_grn_no === 0
      ? "001"
      : (Number(countGrnNumber[0].no_of_grn_no) + 1).toString().padStart(3, '0');
  const GRN_NUMBER =
    "GRN" + "/" + getProjectByProjId[0].project_code + "/" + count_number;

  useEffect(() => {
    form.setFieldsValue({
      replace_grn_no: GRN_NUMBER,
    });
  }, [GRN_NUMBER]);

  return (
    <>
      <div className="add-file">
        <Form
          name="po_replacement"
          form={form}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={HandelReplacementPo}
        >
          <Form.Item
            name="po_number"
            label="Purchase Order Number"
            rules={[{ required: true, message: "Please select Po Number!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Po Number"
              onChange={(poNum) => setPoNumber(poNum)}
              filterOption={handleSearch}
              disabled={isDisable}
            >
              {getPurchaseOrder?.materials?.map((item) => (
                <Option key={item.id} value={item.po_number}>
                  {item.po_number}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="replace_date"
            label="Replace Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Replace Date",
              },
            ]}
          >
            <DatePicker
              name="replace_date"
              format="DD-MM-YYYY"
              onChange={handleReplaceDate}
              placeholder="Enter Date"
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="replace_grn_no"
            label="Replace GRN No."
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Grn Number Create",
              },
            ]}
            initialValue={GRN_NUMBER}
          >
            <Input placeholder="Enter Grn Number Create" disabled />
          </Form.Item>

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                disabled={isDisable}
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      {hideForm && (
        <div className="add-file">
          <Form
            name="replacement_item"
            form={form2}
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={HandelReplacementItems}
          >
            <Form.Item
              name="grn_no"
              label="GRN Number"
              rules={[{ required: true, message: "Please select GRN Number!" }]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select GRN Number"
                onChange={(grn) => setSelectGrn(grn)}
                filterOption={handleSearch}
              >
                {getAllGrnQty?.materials?.map((item) => (
                  <Option key={item.id} value={item.grn_no}>
                    {item.grn_no}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="item_id"
              label="Item Name"
              rules={[{ required: true, message: "Please select Item Name!" }]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Item Name"
                onChange={(item_id) => setSelectItem(item_id)}
                filterOption={handleSearch}
              >
                {getGrnitemsRcvRejQty?.map((item) => (
                  <Option key={item.id} value={item.item_id}>
                    {item.material_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="item_code"
              label="Item Code"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Item Code",
                },
              ]}
              initialValue={itemCode[0]}
            >
              <Input placeholder="Enter Item Code" disabled />
            </Form.Item>

            <Form.Item
              name="reject_qty"
              label="Reject QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Reject Qty",
                },
              ]}
              initialValue={rejQty[0]}
            >
              <Input placeholder="Enter Reject Qty" disabled />
            </Form.Item>
            <Form.Item
              name="replace_qty"
              label="Replace QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Replace Qty",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const rejectQty = getFieldValue("reject_qty");
                    if (
                      !rejectQty ||
                      parseFloat(value) <= parseFloat(rejectQty)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Replace Qty cannot exceed Reject Qty"
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Enter Replace Qty" />
            </Form.Item>
            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>

          <div className="btn-project">
            <Button
              onClick={() => resetAllValue()}
              className="btn-cancel"
              style={{ marginRight: 25 }}
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default PoItemsReplaceFrom;
