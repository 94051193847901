import "./css/TaskShow.css";
import {
  Button,
  Table,
  Input,
  notification,
  Form,
  Select,
  Modal,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  countActionQCC,
  delTaskById,
  getAllStages,
  getAllTasks,
  newStageCreate,
  updateTasksById,
} from "../../../redux-core/qcc/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";
import {
  selectorStageDetail,
  selectorTaskDetail,
} from "../../../redux-core/qcc/selectors";

import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  delTask: (id) => dispatch(delTaskById(id)),
  newStage: (stage) => dispatch(newStageCreate(stage)),
  updateTask: (newTask) => dispatch(updateTasksById(newTask)),
  getTasks: (orgID) => dispatch(getAllTasks(orgID)),

  getAllStages: (orgID) => dispatch(getAllStages(orgID)),
  count: (orgId) => dispatch(countActionQCC(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const TaskShow = () => {
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [filteractivity] = Form.useForm();
  const [formTaskDetails] = Form.useForm();
  const [taskRow, setTaskRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState();
  const {
    getAllStages,
    count,
    getTasks,
    newStage,
    updateTask,
    delTask,
    redirect,
  } = actionDispatch(useDispatch());
  const allTasksData = useSelector(selectorTaskDetail);

  const stageDetails = useSelector(selectorStageDetail);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [activedata, setActivedata] = useState(true);

  const data = [];
  const orgID = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const Spindata = useSelector((item) => item.spinreducer);
  const [searchid, setSearchid] = useState({
    min: "",
    max: "",
  });
  const [array, setArray] = useState("");

  useEffect(() => {
    getTasks(orgID);
    getAllStages(orgID);
  }, []);

  function handleProjChange(value) {
    formTaskDetails.setFieldsValue({
      stage_id: [],
    });

    // getStageById(parseInt(value), orgID);
  }

  const onEditStageDetailsFinish = async (values) => {
    let data = {
      ...values,
      orgID: orgID,
      createdBy: userId,
    };
    let response = await newStage(data);
    if (response) {
      await clear_field();
      getAllStages(orgID);
    }
  };

  const onEditStageDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field = () => {
    form.setFieldsValue({
      stage_name: "",
      project_id: [],
    });
  };

  const onEditTaskDetailsFinish = async (values) => {
    let data = {
      stage_id: Array.isArray(values.stage_id)
        ? values.stage_id
        : [values.stage_id],
      stage_name: values.stage_name,
      task_name: values.task_name,
      orgID: orgID,
      createdBy: userId,
      level: 4,
      task_id: taskRow.task_id,
    };
    let response = await updateTask(data);
    if (response) {
      await setIsModalVisible(false);
      await getTasks(orgID);
    }
  };

  const onEditTaskDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field2 = () => {
    formTaskDetails.setFieldsValue({
      task_name: taskRow.task_name,

      stage_id: taskRow.stage_id,
    });
  };

  const showModal = (data) => {
    const tempFilteredData = [];
    if (data.stage_id) {
      const stageID = data.stage_id.split(",");
      stageID.forEach((item) => {
        const matchingStage = stageDetails.stage.find(
          (items) => items.stage_id == item
        );
        if (matchingStage) {
          tempFilteredData.push(`${matchingStage.stage_id}`);
        }
      });
    }

    setTaskRow(data);
    setIsModalVisible(true);
    formTaskDetails.setFieldsValue({
      task_name: data.task_name,

      stage_id: tempFilteredData,
    });
  };
  useEffect(() => {}, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // form.setFieldsValue({
  //     stage_name: taskRow.stage_name,
  //     // project_id: filteredData
  // })

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
    },
    {
      title: "Task ID",
      dataIndex: "task_id",
      ...getColumnSearchProps("task_id"),
    },
    {
      title: "Task Name",
      dataIndex: "task_name",
      ...getColumnSearchProps("task_name"),
    },
    {
      title: "Stage ID",
      dataIndex: "stage_id",
      ...getColumnSearchProps("stage_id"),
    },

    {
      title: "Stage Name",
      dataIndex: "stage_name",
      ...getColumnSearchProps("stage_name"),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };
  const dataTable = [];
  for (let i = 0; i < allTasksData.length; i++) {
    dataTable.push({
      sl_no: i + 1,
      // key: allTasksData[i].id,
      task_id: allTasksData[i].id,
      task_name: allTasksData[i].task,
      stage_id: allTasksData[i].stage_id,
      stage_name: allTasksData[i].stage_name,
    });
  }

  for (let i = 0; i < allTasksData.length; i++) {
    data.push({
      sl_no: i + 1,
      key: allTasksData[i].id,
      task_id: allTasksData[i].id,
      task_name: allTasksData[i].task,
      stage_id: allTasksData[i].stage_id,
      stage_name: allTasksData[i].stage_name,
    });
  }

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.task_id]) });
  };
  const onConfirm = async (key) => {
    await delTask(key);
    await getTasks(orgID);
    await count(orgID);
  };
  const deleteSelected = () => {
    if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  // const handleChange = (e) => {
  //     setSearchid({
  //         ...searchid,
  //         [e.target.name]: e.target.value
  //     })
  // }

  // const filter = () => {
  //     var filtered = data.filter(o => {
  //         if (searchid.min && o.task_id < searchid.min) {
  //             return false;
  //         }
  //         if (searchid.max && o.task_id > searchid.max) {
  //             return false;
  //         }
  //         return true;
  //     });
  //     setArray(filtered)
  // }

  // const Reset = () => {
  //     setSearchid({
  //         min: '',
  //         max: ''
  //     })
  //     setArray(data);
  //     filteractivity.resetFields();
  // }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  //     const children =[];
  //     for(let i = 0; i< stageDetails.stage.length; i++){
  //         children.push(
  //             <Option key={stageDetails.stage[i].stage_id}>
  // {stageDetails.stage[i].stage_name}-{stageDetails.stage[i].stage_id}
  //             </Option>
  //         )
  //     }
  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Of Task Details</div>
        <br />
        {/* <Form
                form={filteractivity}
                name="filter_activity"
                size="medium"
                className='show_project'
                initialValues={{
                    remember: true,
                }}
                onFinish={filter}
            onFinishFailed={onStageDetailsFinishFailed}
            >

                <Form.Item
                    name="Activity_ID"
                    label="Task ID"
                    className="input-field"
                >
                    <Input.Group size="large">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Input type="number" placeholder="Min TaskID" name="min" value={searchid.min} onChange={(e) => handleChange(e)} />
                            </Col>
                            <Col span={12}>
                                <Input type="number" placeholder="Max TaskID" name="max" value={searchid.max} onChange={(e) => handleChange(e)} />
                            </Col>
                        </Row>
                    </Input.Group>

                </Form.Item>

                <Form.Item className="input-field">
                    <div>
                        <Button htmlType="submit" className="btn-submit">Filter</Button>&emsp;
                        <Button onClick={Reset} className="btn-submit">Reset</Button>
                    </div>
                </Form.Item>

            </Form> */}
        <div className="btn__">
          {/* <Button className="pdf">Export to PDF</Button> */}
          <Button className="excel">
            <CSVLink
              filename={"Tasks_Table.csv"}
              data={Array.isArray(array) ? array : dataTable}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          {/* <Button className="print">Print</Button>
                <Button className="copy">Copy</Button> */}
        </div>
        <Table
          className="table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={Array.isArray(array) ? array : data}
          pagination={10}
          scroll={{ x: "100vw", y: "300px" }}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
        />
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={700}
          centered={true}
          footer={null}
        >
          <div className="edit__stage">Create a New Stage</div>
          <br />
          <Form
            form={form}
            name="master_task"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditStageDetailsFinish}
            onFinishFailed={onEditStageDetailsFinishFailed}
          >
            <Form.Item
              name="stage_name"
              label="Stage Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter the Stage Name !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
            >
              <Input placeholder="Stage Name" />
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_field} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
          <br />
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>OR</strong>
            </p>
          </div>
          <br />
          <div className="edit__task">Edit Task Details</div>
          <br />
          <Form
            form={formTaskDetails}
            name="task_details"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditTaskDetailsFinish}
            onFinishFailed={onEditTaskDetailsFinishFailed}
          >
            <Form.Item
              name="task_name"
              label="Task Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Task Name !",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
            >
              <Input placeholder="Task Name" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="stage_id"
              label="Select Stages"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select one or more Stages !",
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Stage Name"
              >
                {/* {children} */}
                {stageDetails.stage?.map((getAllStages) => (
                  <Option key={getAllStages?.stage_id}>
                    {getAllStages?.stage_name}--{getAllStages?.stage_id}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item
                            name="stage-name"
                            label="Upload Help"
                            className="input-field"
                        >
                            <Upload name="file" >
                                <Button icon={<UploadOutlined />} >Choose file</Button>
                            </Upload>
                        </Form.Item> */}

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_field2} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <br />
        {Array.isArray(array)
          ? array.length > 0 && (
              <Button
                style={{ display: activedata ? "" : "none" }}
                className="deleteallbutton copy"
                onClick={deleteSelected}
              >
                Delete All Selected
              </Button>
            )
          : data.length > 0 && (
              <Button
                style={{ display: activedata ? "" : "none" }}
                className="deleteallbutton copy"
                onClick={deleteSelected}
              >
                Delete All Selected
              </Button>
            )}
      </Spin>
    </>
  );
};

export default React.memo(TaskShow);
