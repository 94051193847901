import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import "./css/AddProjectDetailsForm.css";
import { Form, Input, Button, Upload, Select, Modal, Dropdown } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import {
  getAllProj,
  createProjDetails,
  countAction,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => getAllProj(orgId, userId),
  projDetails: (projDetails) => dispatch(createProjDetails(projDetails)),
  count: (orgId) => dispatch(countAction(orgId)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
});

const AddProjectDetailsForm = (props) => {
  const { getProj, projDetails, count,getAllProjDetails } = actionDispatch(useDispatch());
  const [formProjectDetails] = Form.useForm();
  const [imagecard, setImagecard] = useState(false);
  const children = [];
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getProj(orgId, userId);
  }, []);

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}-
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  const onProjectDetailFinish = async (values) => {
    setIsDisableInput(true);
    let data = {
      ...values,
      orgID: orgId,
      project_id:props.projectId
    };
    if (data.image_1 && data.image_1.length < 1) delete data.image_1;
    // if (data.image_2 && data.image_2.length < 1) delete data.image_2;
    // if (data.image_3 && data.image_3.length < 1) delete data.image_3;
    // if (data.image_4 && data.image_4.length < 1) delete data.image_4;
    // if (data.image_5 && data.image_5.length < 1) delete data.image_5;

    await projDetails(data);
    await clear_ProjectDetails_field();
    await count(orgId);
    getAllProjDetails(props.projectId,orgId,userId)
  };

  const onProjectDetailFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFileForImg1 = (e) => {
    if (e.fileList.length > 0) setImagecard({ ...imagecard, image_1: true });
    else setImagecard({ ...imagecard, image_1: false });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // const normFileForImg2 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_2: true });
  //   else setImagecard({ ...imagecard, image_2: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg3 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_3: true });
  //   else setImagecard({ ...imagecard, image_3: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg4 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_4: true });
  //   else setImagecard({ ...imagecard, image_4: false });

  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg5 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_5: true });
  //   else setImagecard({ ...imagecard, image_5: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  const clear_ProjectDetails_field = () => {
    setIsDisableInput(false);
    formProjectDetails.setFieldsValue({
     // project_id: [],
      block: "",
      image_1: [],
      // image_2: [],
      // image_3: [],
      // image_4: [],
      // image_5: [],
    });
    setImagecard({
      image_1: false,
      // image_2: false,
      // image_3: false,
      // image_4: false,
      // image_5: false,
    });
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.url || file.preview);
    setPreviewvisible(true);
  };

  // const modalCancel = () =>{
  //   setShowProjectTypesModal(false)
  // }

  // const [typeProject, setTypeProject] = useState("")
  // const [showProjectTypesModal, setShowProjectTypesModal] = useState(false)

  // const handleProjectTypes = (value) =>{
  //   setTypeProject(value)
  //   setShowProjectTypesModal(true)
  // }

  return (
    <>
      <Form
        form={formProjectDetails}
        name="project-details"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onProjectDetailFinish}
        onFinishFailed={onProjectDetailFinishFailed}
      >
        {/* <Form.Item
          name="project_id"
          label="Select Project"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Project !",
            },
          ]}
        >
          <Select
            placeholder="Select Project"
            allowClear
            disabled={isDisableInput}
          >
            {children}
          </Select>
        </Form.Item> */}

        <Form.Item
          className="input-field-showLevel1"
          label="Description"
          name="block"
          rules={[
            {
              required: false,
              message: "Please enter Description !",
            },
            {
              pattern: /^(.{0,300})$/,
              message: "please enter less than 300 characters only",
            },
          ]}
        >
          <Input
            placeholder="Ex: Level 1:foundation, Level2: Slab"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="image_1"
          label="Upload"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg1}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard.image_1 && uploadButton}
          </Upload>
        </Form.Item>

        {/* <Form.Item
          name="image_2"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg2}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard.image_2 && uploadButton}
          </Upload>
        </Form.Item> */}
        {/* <Form.Item
          name="image_3"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg3}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard.image_3 && uploadButton}
          </Upload>
        </Form.Item> */}

        {/* <Form.Item
          name="image_4"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg4}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard.image_4 && uploadButton}
          </Upload>
        </Form.Item> */}

        {/* <Form.Item
          name="image_5"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg5}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard.image_5 && uploadButton}
          </Upload>
        </Form.Item> */}

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_ProjectDetails_field} className="btn-cancel">
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default AddProjectDetailsForm;
