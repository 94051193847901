import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorgetAllGrnItemCodeWise,
  selectorGetItemInCurrentStock,
  selectorGetItemInCurrentStockItemCodeWise,
} from "../../../../redux-core/materials/selectors";
import {
  getCurrentStockItemCodeWise,
  updateCurrentStockItem,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const actionDispatch = (dispatch) => ({
  updateCurrentStockItem: (itemCurrentStock) =>
    dispatch(updateCurrentStockItem(itemCurrentStock)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
});

const ItemReceiptsStatement = (props) => {
  const { updateCurrentStockItem, getCurrentStockItemCodeWise } =
    actionDispatch(useDispatch());
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const getGrnItemCodeWise = useSelector(selectorgetAllGrnItemCodeWise);
  const [array, setArray] = useState("");
  const getItemInCurrentStock = useSelector(selectorGetItemInCurrentStock);
  const getShowItemInCurrentStockItemCodeWise = useSelector(
    selectorGetItemInCurrentStockItemCodeWise
  );

  useEffect(() => {
    getCurrentStockItemCodeWise(props.itemCode, props.projectID);
  }, [props.itemCode]);

  const tableData = [];
  let rcvQtySum = 0;
  let accQtySum = 0;
  let sumOfRate = 0;
  let sumOfCgst = 0;
  let sumOfSgst = 0;
  let sumOfIgst = 0;
  let sumOfTotal = 0;
  let sumOfTotalAcceptedAmount = 0;

  for (let i = 0; i < getGrnItemCodeWise.length; i++) {
    const item = getGrnItemCodeWise[i];

    const grnDateObject = new Date(item.grn_date);

    const totalItemValue = Number(item.item_rate) * Number(item.accept_qty);
    const gstAmount =
      Number(item.c_gst) + Number(item.c_gst) + Number(item.i_gst);
    const totalgst = Number(gstAmount) * Number(item.accept_qty);

    const TotalAcceptedAmount = Number(totalItemValue) + Number(totalgst);

    tableData.push({
      index: i + 1,
      item_code: item.item_code,
      material_name: item.material_name,
      po_number: item.po_number,
      po_date: item.po_date,
      grn_no: item.grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      rcv_qty: item.rcv_qty,
      accept_qty: item.accept_qty,
      item_rate: item.item_rate,
      c_gst: item.c_gst,
      s_gst: item.s_gst,
      i_gst: item.i_gst,
      total_amount: totalItemValue,
      total_accepted_amount: TotalAcceptedAmount,
    });

    rcvQtySum += item.rcv_qty;
    accQtySum += item.accept_qty;
    sumOfRate += item.item_rate;
    sumOfCgst += item.c_gst;
    sumOfSgst += item.s_gst;
    sumOfIgst += item.i_gst;
    sumOfTotal += totalItemValue;
    sumOfTotalAcceptedAmount += TotalAcceptedAmount;
  }

  // Add sum row
  tableData.push({
    index: "Total",
    rcv_qty: rcvQtySum,
    accept_qty: accQtySum,
    item_rate: sumOfRate,
    c_gst: sumOfCgst,
    s_gst: sumOfSgst,
    i_gst: sumOfIgst,
    total_amount: sumOfTotal,
    total_accepted_amount: sumOfTotalAcceptedAmount,
  });

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "index",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
    },
    {
      title: "PO Date",
      dataIndex: "po_date",
    },
    {
      title: "GRN NO.",
      dataIndex: "grn_no",
    },
    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
    {
      title: "Amount",
      dataIndex: "item_rate",
    },
    {
      title: "C GST",
      dataIndex: "c_gst",
    },
    {
      title: "S GST",
      dataIndex: "s_gst",
    },
    {
      title: "I GST",
      dataIndex: "i_gst",
    },
    {
      title: "Total Rate Of Amount",
      dataIndex: "total_amount",
    },
    {
      title: "Total",
      dataIndex: "total_accepted_amount",
    },
  ];

  const localpurchaseqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.without_po_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.without_po_qty;
  const repleaceqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.replace_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.replace_qty;
  const transferQty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_to_other_site ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_to_other_site;
  const recieveeqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.site_transfer_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.site_transfer_qty;
  const issueQty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.issue_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.issue_qty;
  const Issue_values =
    getShowItemInCurrentStockItemCodeWise?.[0]?.Issue_qty_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.Issue_qty_value;
  const localpurchaseqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.local_purchase_value ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.local_purchase_value;
  const recieveqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_value;
  const transferqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.recieve_transfer_qty ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.recieve_transfer_qty;
  const replaceqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.replace_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.replace_value;

  const HandelUpdateQtyInStore = async () => {
    let StoreData = {
      id: getItemInCurrentStock[0].id,
      project_id: props.projectID,
      // item_code: tableData[tableData.length - 2].item_code,
      Item_name: tableData[tableData.length - 2].material_name,
      rcv_qty: tableData[tableData.length - 1].rcv_qty,
      accept_qty: tableData[tableData.length - 1].accept_qty,
      total_qty:
        Number(tableData[tableData.length - 1].accept_qty) +
        Number(localpurchaseqty) +
        Number(repleaceqty) +
        Number(recieveeqty) -
        Number(issueQty) -
        Number(transferQty),
      isChecked: 1,
      orgID: orgId,
      item_rate:
        Number(tableData[tableData.length - 1].total_amount) +
        Number(localpurchaseqtyValue) +
        Number(recieveqtyValue) +
        Number(replaceqtyValue) -
        Number(Issue_values) -
        Number(transferqtyValue),
    };
    await updateCurrentStockItem(StoreData);
  };

  return (
    <>
      <div className="BtnContainer1">
        <Button className="button-61" onClick={HandelUpdateQtyInStore}>
          Quantity Update In Store
        </Button>
      </div>
      <Table
        className="custom-table"
        dataSource={Array.isArray(array) ? array : tableData}
        columns={columns}
        pagination={10}
        scroll={{ x: "90vw" }}
        bordered
        size="large"
      />
    </>
  );
};

export default ItemReceiptsStatement;
