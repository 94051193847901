import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { selectorGetAllReplacePoItems } from "../../../../redux-core/materials/selectors";

function PoReplaceItemsFrom() {
  const getAllReplacePoItems = useSelector(selectorGetAllReplacePoItems);

  const data = [];

  for (let i = 0; i < getAllReplacePoItems.length; i++) {
    data.push({
      id: i + 1,
      material_name: getAllReplacePoItems[i].material_name,
      grn_no: getAllReplacePoItems[i].grn_no,
      item_code: getAllReplacePoItems[i].item_code,
      reject_qty: getAllReplacePoItems[i].reject_qty,
      replace_qty: getAllReplacePoItems[i].replace_qty,
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Reject Qty",
      dataIndex: "reject_qty",
    },
    {
      title: "Replace Qty",
      dataIndex: "replace_qty",
    },
  ];

  return (
    <>
      <div className="add-file">
        <Table
          scroll={{ x: "90vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      </div>
    </>
  );
}

export default PoReplaceItemsFrom;
