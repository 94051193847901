import React, { useEffect, useState } from "react";
import { Form, Button, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorUploadMmpFile,
} from "../../../redux-core/settings/selectors";
import { createUploadMmpFile, getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowUploadMms } from "../../popupmodal";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";

const actionDispatch = (dispatch) => ({
  UploadMmpFile: (uploadMmp) => dispatch(createUploadMmpFile(uploadMmp)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
  dispatch(getAllAssignProjectId(orgId, userID)),

});

const UploadMsProject = () => {
  const { UploadMmpFile,getAllProj,getAllAssignProjectId } = actionDispatch(useDispatch());
  const children = [];
  const children2 = [];
  const [form] = Form.useForm();
  // const [projectData, setProjectData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(false);
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const createUploadMmp = useSelector(selectorUploadMmpFile);
  const { Option } = Select;
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  useEffect(()=>{
    getAllProj(orgId,userId)
    getAllAssignProjectId(orgId,userId)
  },[])

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjSelectorData.filter((items) => items.project_id == item.Project_id);
  });

  for (let i = 0; i < projectData.length; i++) {
    children2.push(
      <Option value={projectData[i].project_id} key={i}>
        {projectData[i].project_name}
        {projectData[i].project_id}
      </Option>
    );
  }
  // const onFinish = async (values) => {

  //   for(let i =1; i<projectData.length; i++){
  //     let obj = projectData[i];
  //      const objData = {
  //       project_id: values.Project_id,
  //       task_name:obj.Name,
  //       duration: obj.Duration,
  //       start: obj.Start,
  //       finish: obj.Finish,
  //       predecessors: obj.Predecessors,
  //       successors: obj.Outline_Level,
  //       resources_name: obj.Notes,
  //       orgID: orgId,
  //      }
  //     // await UploadMmpFile(objData)
  //   }
  // };

  const onFinish = async (values) => {
    if (values?.uploadproject_ms) {
      let uploadMmpFileBase64 = await getBase64(
        values?.uploadproject_ms?.[0]?.originFileObj
      );
      let UpdatedUploadmmpfileExcel = {
        ...values.uploadproject_ms[0],
        thumbUrl: uploadMmpFileBase64,
      };
      var UpdatedUploadMmpExcelDoc = [
        UpdatedUploadmmpfileExcel,
        ...values.uploadproject_ms.slice(1),
      ];
    }
    const data = {
      Project_id: values.Project_id,
      uploadproject_ms: UpdatedUploadMmpExcelDoc,
      orgID: orgId,
    };
    await UploadMmpFile(data);

    form.setFieldsValue({
      uploadproject_ms: [],
    });
    form.setFieldsValue({
      uploadproject_ms: [],
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  //   const customRequest = ({ file, onSuccess, onError }) => {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {

  //       try {
  //         const workbook = XLSX.read(e.target.result, { type: 'binary' });
  //         const firstSheetName = workbook.SheetNames[0];
  //         const worksheet = workbook.Sheets[firstSheetName];
  //         const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //         const headerRow = data[0];
  //         const subheaderRow = data[1];

  //         var headerObj = {};
  //         headerRow.forEach((row, rowIndex) => (headerObj[rowIndex]= rowIndex));
  //         subheaderRow.forEach((row,rowIndex) =>(headerObj[rowIndex]= rowIndex))
  //  const mainArray = [];

  //  data.map((row, rowIndex)=>{
  //   const mainData = {};
  //   mainData.ID = row[headerObj[0]];
  //   mainData.Active = row[headerObj[1]];
  //   mainData.Task_Mode = row[headerObj[2]];
  //   mainData.Name = row[headerObj[3]];
  //   mainData.Duration = row[headerObj[4]];
  //   mainData.Start = row[headerObj[5]];
  //   mainData.Finish = row[headerObj[6]];
  //   mainData.Predecessors = row[headerObj[7]];
  //   mainData.Outline_Level = row[headerObj[8]];
  //   mainData.Notes = row[headerObj[9]];

  //   mainArray.push(Object.assign({},mainData))
  //  }
  // )
  //         setProjectData(mainArray);
  //         onSuccess();
  //       } catch (error) {
  //         console.error('XLSX parsing error:', error);
  //         onError(error);
  //       }
  //     };

  //     reader.onerror = (error) => {
  //       console.error('File reader error:', error);
  //       onError(error);
  //     };

  //     reader.readAsBinaryString(file);
  //   };

  const normFile = (e) => {
    if (e && e.fileList.length > 0) {
      setSelectedFile({ ...selectedFile, uploadproject_ms: true });
    } else {
      setSelectedFile({ ...selectedFile, uploadproject_ms: false });
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

const DownloadSampleMms = () =>{
  ShowUploadMms({onConfirm:()=>onConfirm([])})
}
  
const onConfirm =  () =>{
  const fileName = "uploadScheduleSample.xlsx";
  const data = [
    {
   ID:"1",
Active:"YES/NO",
TaskMode: "Auto Scheduled",
Name: "HIGH-RISE RESIDENTIAL PROJECT",
Duration: "833days",
Start: "Fri 12/10/21",
Finish: "Tue 8/06/24",
Predecessors: "7SS+10 days / 5",
OutlineLevel: "5",
Notes: "Work commenced from 11th Septembar 2023."
    },
  ];
  const ws = XLSX?.utils?.json_to_sheet(data);
  const wb = XLSX?.utils?.book_new();
  XLSX?.utils?.book_append_sheet(wb, ws, "test");
  XLSX?.writeFile(wb, fileName);

}

  return (
    <>
     <div className="upload-file add-project">
          <div className="sub-title">Upload Schedule In Excel File </div>
          <br />
      <Form
        form={form}
        name="project-details-bulk"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      
      >
          {logindetails.user.role === "ADMIN" ? (
        <Form.Item
          name="Project_id"
          label="Select Project"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please Select The Project",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Select Project Name"
            // disabled={isDisableInput}
          >
            {children}
          </Select>
        </Form.Item>
          ):(
            <Form.Item
            name="Project_id"
            label="Select Project"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select The Project",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Select Project Name"
              // disabled={isDisableInput}
            >
              {children2}
            </Select>
          </Form.Item>
          )}
 
        <Form.Item
          //style={{ textAlign: "center",paddingBottom:20 }}
          className="input-field"
          name="uploadproject_ms"
          label="Upload File"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please Upload File in Excel format!",
            },
          ]}
          maxCount={"1"}
         help="Upload only .xlsx, .xls files"
         
        >

          <Upload
            // customRequest={customRequest}
            accept={".xlsx, .xls"}
            beforeUpload={(file, fileList) => {
              return false;
            }}
            maxCount={"1"}
          >
            <Button icon={<UploadOutlined />}>Choose File</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Upload</strong>
            </Button>
            <Button type="primary"  className="btn-submit" 
            onClick={DownloadSampleMms}>
              <strong>Sample</strong>
            </Button>
          </div>
        </Form.Item>
       
      </Form>
      </div>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default UploadMsProject;

// import React, { useState } from 'react';
// import { Form, Button, Upload } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import { parseMpp } from 'microsoft-project-parser';

// const UploadMsProject = () => {
//   const [form] = Form.useForm();
//   const [projectData, setProjectData] = useState(null);

//   const onFinish = (values) => {
//     console.log('Form values:', values);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

//   const customRequest = ({ file, onSuccess, onError }) => {
//     try {
//       const reader = new FileReader();

//       reader.onload = async (e) => {
//         try {
//           const arrayBuffer = e.target.result;
//           const parsedData = await parseMpp(arrayBuffer);
//           setProjectData(parsedData);
//           onSuccess();
//         } catch (error) {
//           console.error('Error parsing .mpp file:', error);
//           onError(error);
//         }
//       };

//       reader.readAsArrayBuffer(file);
//     } catch (error) {
//       console.error('Error reading .mpp file:', error);
//       onError(error);
//     }
//   };

//   return (
//     <>
//       <Form
//         form={form}
//         name="project-details-bulk"
//         size="large"
//         initialValues={{
//           remember: true,
//         }}
//         onFinish={onFinish}
//         onFinishFailed={onFinishFailed}
//       >
//         <Form.Item
//           style={{ textAlign: 'center' }}
//           name="projectdetails_excel"
//           label=""
//           rules={[
//             {
//               required: true,
//               message: 'Please Upload MS project File!',
//             },
//           ]}
//         >
//           <Upload customRequest={customRequest} accept=".mpp">
//             <Button icon={<UploadOutlined />}>Choose File</Button>
//           </Upload>
//         </Form.Item>

//         <Form.Item>
//           <div className="btn-project">
//             <Button type="primary" htmlType="submit" className="btn-submit">
//               <strong>Upload</strong>
//             </Button>
//           </div>
//         </Form.Item>
//       </Form>
//     </>
//   );
// };

// export default UploadMsProject;
