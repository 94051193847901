import "./css/AddProject.css";
import "antd/dist/antd.min.css";
import React, { useEffect } from "react";
import AddProjectDetailsForm from "./Forms/AddProjectDetailsForm";
import UploadProjectDetailsZipForm from "./Forms/UploadProjectDetailsZipForm";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { TypesOfProject } from "./TypesOfProject";
import { useDispatch } from "react-redux";
import { getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import UploadMsProject from "./UploadMsProject";

const actionDispatch = (dispatch) => ({
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
});

function AddProjectDetails(props) {
  console.log("props",props)
  const Spindata = useSelector((item) => item.spinreducer);
  const { projects } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);

  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
  }, []);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="upload-file add-project">
          <div className="sub-title">Types of Project</div>
          <br />
          <TypesOfProject projectId={props.projectID}/>
        </div>

        <br></br>

        <div className="add-project">
          <div className="sub-title">Add Project Location</div>
          <br />
          <AddProjectDetailsForm projectId={props.projectID}/>
        </div>

        <br></br>

        <div className="upload-file add-project">
          <div className="sub-title">
            Upload Bulk Project Details In Excel File
          </div>
          <br />
          <UploadProjectDetailsZipForm projectId={props.projectID}/>
        </div>

        <br></br>

        {/* <div className="upload-file add-project">
          <div className="sub-title">Upload Schedule In Excel File </div>
          <br />
          <UploadMsProject projectId={props.projectID}/>
        </div> */}
      </Spin>
    </>
  );
}

export default React.memo(AddProjectDetails);
