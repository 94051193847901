import React, { useState, useEffect, Children } from "react";
import { Button, Table, Modal } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import { selectorShowCurrentStock } from "../../../../redux-core/materials/selectors";
import ItemReceiptsStatement from "./ItemReceiptsStatement";
import {
  getItemCodeWiseGrn,
  getItemWiseCurrentStock,
  insertItemInCurrentStock,
  getCurrentStockItemCodeWise,
} from "../../../../redux-core/materials/actions";
import useSearch from "../../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const actionDispatch = (dispatch) => ({
  getItemCodeWiseGrn: (itemCode, approved, projectID) =>
    dispatch(getItemCodeWiseGrn(itemCode, approved, projectID)),
  getItemWiseCurrentStock: (itemCode, projectID) =>
    dispatch(getItemWiseCurrentStock(itemCode, projectID)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
  insertItemInCurrentStock: (itemCurrentStock) =>
    dispatch(insertItemInCurrentStock(itemCurrentStock)),
});

function ShowMaterialList(props) {
  const currentStockShow = useSelector(selectorShowCurrentStock);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [array, setArray] = useState("");
  const {
    getItemCodeWiseGrn,
    getItemWiseCurrentStock,
    insertItemInCurrentStock,
    getCurrentStockItemCodeWise,
  } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [itemCode, setItemCode] = useState();

  let tableData = [];
  let itemCodeMap = {};

  for (let i = 0; i < currentStockShow.length; i++) {
    const currentItem = currentStockShow[i];
    const itemCode = currentItem.item_code;

    if (itemCodeMap.hasOwnProperty(itemCode)) {
      // Item code already exists, update the quantities
      itemCodeMap[itemCode].rcv_qty += currentItem.rcv_qty;
      itemCodeMap[itemCode].accept_qty += currentItem.accept_qty;
      itemCodeMap[itemCode].issue_qty += currentItem.issue_qty;
      itemCodeMap[itemCode].total_qty += currentItem.total_qty;
    } else {
      // Item code does not exist, add it to the map
      itemCodeMap[itemCode] = {
        //id: currentItem.id,
        id: i + 1,
        item_id: currentItem.item_id,
        item_code: itemCode,
        material_name: currentItem.material_name,
        hsn_code: currentItem.hsn_code,
        uom: currentItem.uom,
        rcv_qty: currentItem.rcv_qty,
        accept_qty: currentItem.accept_qty,
        issue_qty: currentItem.issue_qty,
        total_qty: currentItem.total_qty,
      };
    }
  }

  // Convert the itemCodeMap to an array of objects
  for (const itemCode in itemCodeMap) {
    tableData.push(itemCodeMap[itemCode]);
  }

  useEffect(() => {
    getItemWiseCurrentStock(itemCode, props.projectID);
    getCurrentStockItemCodeWise(itemCode, props.projectID);
  }, [itemCode]);

  const HandelOpenItemWiseGrn = async (record) => {
    setIsModalOpen(true);
    getItemCodeWiseGrn(record, 1, props.projectID);
    getCurrentStockItemCodeWise(record, props.projectID);
    setItemCode(record);
    const StoreData = {
      item_code: record,
      orgID: orgId,
      project_id: props.projectID,
    };
    console.log("Storedata", StoreData);
    await insertItemInCurrentStock(StoreData);
    getItemWiseCurrentStock(record, props.projectID);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Receipts Statement",
      dataIndex: "item_code",
      render: (record) => {
        // //console.log("-----",record)
        return (
          <Button
            className="button-61"
            onClick={() => HandelOpenItemWiseGrn(record)}
          >
            Item Receipts Statement
          </Button>
        );
      },
      width: 200,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
      ...getColumnSearchProps("material_name"),
    },
    {
      title: "Hsn Code",
      dataIndex: "hsn_code",
      ...getColumnSearchProps("hsn_code"),
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
  ];
  const backToAllProject=()=>{
    props.backProject()
  }

return (
    <>
        <div>
        <Button onClick={() => backToAllProject()}>All Projects</Button><br/><br/>
        <br />
        <br />
        <Table
          scroll={{ x: "50vw" }}
          columns={columns} //dataSource={tableData}
          dataSource={Array.isArray(array) ? array : tableData}
          pagination={10}
        />
      </div>
      <Modal
        width={1950}
        footer={null}
        visible={isModalOpen}
        onCancel={modalClose}
      >
        <ItemReceiptsStatement
          itemCode={itemCode}
          projectID={props.projectID}
        />
      </Modal>
    </>
  );
}

export default ShowMaterialList;
