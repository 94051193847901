import React, { useState } from "react";
import { Button, DatePicker, Form, Modal, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGetDateFilterGrnList,
  selectorGetDateFilterGrnNumber,
  selectorGetGrnNumber,
  selectorGetPurchaseOrder,
} from "../../../../redux-core/materials/selectors";
import {
  displayGrnNumber,
  getDateFilterAllGrnNo,
  getGrnDateFilterWise,
  getGrnItemRcvRejQty,
} from "../../../../redux-core/materials/actions";
import { EyeOutlined } from "@ant-design/icons";
import ShowPoNumberWiseItemsForm from "./ShowPoNumberWiseItemsForm";

const actionDispatch = (dispatch) => ({
  displayGrnNumber: (poNo) => dispatch(displayGrnNumber(poNo)),
  getGrnItemRcvRejQty: (grnNo) => dispatch(getGrnItemRcvRejQty(grnNo)),
  getGrnDateFilterWise: (dateFilterData) =>
    dispatch(getGrnDateFilterWise(dateFilterData)),
  getDateFilterAllGrnNo: (dateFilterData) =>
    dispatch(getDateFilterAllGrnNo(dateFilterData)),
});

const { Option } = Select;
function ShowListOfGrnForm(props) {
  const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
  const {
    displayGrnNumber,
    getGrnItemRcvRejQty,
    getGrnDateFilterWise,
    getDateFilterAllGrnNo,
  } = actionDispatch(useDispatch());
  const getAllGrnNumber = useSelector(selectorGetGrnNumber);
  const [cardType, setCardType] = useState("");
  const [showGrnItemForm, setShowGrnItemForm] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const getDateFilterGrn = useSelector(selectorGetDateFilterGrnList);
  const getDateFilterGrnNumber = useSelector(selectorGetDateFilterGrnNumber);

  // select the type of table
  const handleCardTypeChange = (value) => {
    setCardType(value);
    displayGrnNumber();
    getGrnDateFilterWise();
    getDateFilterAllGrnNo();
  };

  // click button
  const viewGrnItems = (record) => {
    getGrnItemRcvRejQty(record.grn_no);
    setShowGrnItemForm(true);
  };
  const handelcancel = () => {
    setShowGrnItemForm(false);
  };

  //po number wise grn colunm
  const columns = [
    {
      title: "ID.",
      dataIndex: "key",
    },
    {
      title: "View Of GRN Items.",
      dataIndex: "grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button className="button-29" onClick={() => viewGrnItems(record)}>
              View GRN Items
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
      //   render: (text, record, index) => {
      //     return (
      //       <div>
      //         <Button className="button-29" onClick={() => viewGrnItems(record)}>
      //           View GRN No. {record.grn_no}{" "}
      //           <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
      //         </Button>
      //       </div>
      //     );
      //   },
    },
    {
      title: "Purchase Order No.",
      dataIndex: "po_number",
    },
    {
      title: "Po Date",
      dataIndex: "po_date",
    },

    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "vender_name",
    },
    {
      title: "Purchase Order Value",
      dataIndex: "taxable_value",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
    },
    {
      title: "Invoice Value",
      dataIndex: "invoice_amount",
    },
    {
      title: "Grn Note",
      dataIndex: "grn_note",
      width: "30%",
    },
    {
      title: "Reject Comment",
      dataIndex: "reject_comment",
      width: "30%",
    },
  ];

  // date filter wise grn list
  const columns2 = [
    {
      title: "ID.",
      dataIndex: "key",
    },
    {
      title: "View Of GRN Items.",
      dataIndex: "grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button className="button-29" onClick={() => viewGrnItems(record)}>
              View GRN Items
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
      //   render: (text, record, index) => {
      //     return (
      //       <div>
      //         <Button className="button-29" onClick={() => viewGrnItems(record)}>
      //           View GRN No. {record.grn_no}{" "}
      //           <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
      //         </Button>
      //       </div>
      //     );
      //   },
    },
    {
      title: "Purchase Order No.",
      dataIndex: "po_number",
    },
    {
      title: "Po Date",
      dataIndex: "po_date",
    },

    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "vender_name",
    },

    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
    },
    {
      title: "Taxable Amout",
      dataIndex: "taxable_amount",
    },
    {
      title: "I GST",
      dataIndex: "i_gst",
    },
    {
      title: "C GST",
      dataIndex: "c_gst",
    },
    {
      title: "S GST",
      dataIndex: "s_gst",
    },

    {
      title: "Total Value",
      dataIndex: "invoice_amount",
    },

    {
      title: "Grn Note",
      dataIndex: "grn_note",
      width: "30%",
    },
  ];

  const columns3 = [
    {
      title: "Sl Numbers",
      dataIndex: "sl_no",
    },
    {
      title: "GRN Numbers",
      dataIndex: "grn_number",
    },
    {
      title: "GRN Dates",
      dataIndex: "grn_date",
    },
    {
      title: "Prepared Names",
      dataIndex: "name",
    },
  ];

  const handlePoSelect = (value) => {
    displayGrnNumber(value);
  };
  const data = [];
  for (let i = 0; i < getAllGrnNumber?.length; i++) {
    // po date comes but show 1 day less so add 1day
    const poDateObject = new Date(getAllGrnNumber[i].po_date);
    // poDateObject.setDate(poDateObject.getDate())

    // Grn date comes but show 1 day less so add 1day
    const grnDateObject = new Date(getAllGrnNumber[i].grn_date);
    //grnDateObject.setDate(grnDateObject.getDate())grn_date
    data.push({
      id: getAllGrnNumber[i].id,
      key: i + 1,
      po_number: getAllGrnNumber[i].po_number,
      po_date: poDateObject.toLocaleDateString("en-GB"),
      grn_no: getAllGrnNumber[i].grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      vender_name: getAllGrnNumber[i].vender_name,
      taxable_value: getAllGrnNumber[i].taxable_value,
      invoice_no: getAllGrnNumber[i].invoice_no,
      invoice_amount: getAllGrnNumber[i].invoice_amount,
      grn_note: getAllGrnNumber[i].grn_note,
      reject_comment: getAllGrnNumber[i].reject_comment,
    });
  }

  const dataTable = [];
  for (let i = 0; i < getDateFilterGrn.length; i++) {
    const poDateObject = new Date(getDateFilterGrn[i].po_date);
    const grnDateObject = new Date(getDateFilterGrn[i].grn_date);
    dataTable.push({
      id: getDateFilterGrn[i].id,
      key: i + 1,
      grn_no: getDateFilterGrn[i].grn_no,
      po_number: getDateFilterGrn[i].po_number,
      invoice_no: getDateFilterGrn[i].invoice_no,
      invoice_amount: getDateFilterGrn[i].invoice_amount,
      taxable_amount: getDateFilterGrn[i].taxable_amount,
      i_gst: getDateFilterGrn[i].i_gst,
      c_gst: getDateFilterGrn[i].c_gst,
      s_gst: getDateFilterGrn[i].s_gst,
      grn_note: getDateFilterGrn[i].grn_note,
      vender_name: getDateFilterGrn[i].vender_name,
      po_date: poDateObject.toLocaleDateString("en-GB"),
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
    });
  }

  const GrnNumberData = [];
  for (let i = 0; i < getDateFilterGrnNumber.length; i++) {
    const grnDateObject = new Date(getDateFilterGrnNumber[i].grn_date);
    GrnNumberData.push({
      sl_no: i + 1,
      id: getDateFilterGrnNumber[i].id,
      grn_number: getDateFilterGrnNumber[i].grn_number,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      name: getDateFilterGrnNumber[i].name,
    });
  }

  const handleStartDate = (value, dateString) => {
    setStartDate(dateString);
  };
  const handleEndDate = (value, dateString) => {
    setEndDate(dateString);
  };

  const onFinishDate = async (value) => {
    const filterDate = {
      startDate: startDate,
      endDate: endDate,
      project_id: props.projectID,
    };
    await getGrnDateFilterWise(filterDate);
  };

  const onFinishDateWiseGrnNumber = async (value) => {
    const filterDate = {
      startDate: startDate,
      endDate: endDate,
      project_id: props.projectID,
    };

    await getDateFilterAllGrnNo(filterDate);
  };
  const backToAllProject=()=>{
    props.backProject()
  }
  return (
    <>
      <div className="title-show-project">Show All GRN List</div>
      <br /> <br />
      <Button onClick={() => backToAllProject()}>All Projects</Button>
      <br />
      <br />
      <Form.Item label="Select The Type Of Grn Report">
        <Select
          // className="input-field"
          style={{ width: "400px" }}
          placeholder="Select The Type"
          onChange={handleCardTypeChange}
        >
          <Option value="POWISEGRNLIST">Purchase Order Wise GRN List </Option>
          <Option value="DATEGRNLIST">
            Date Wise GRN List All Purchase Order{" "}
          </Option>
          <Option value="DATEGRNNUMBER">Date Wise All GRN Number </Option>
        </Select>
      </Form.Item>
      <hr></hr>
      <br />
      {cardType === "POWISEGRNLIST" && (
        <Form.Item label="Selcte Purchase Order Number">
          <Select
            // className="input-field"

            style={{ width: "250px" }}
            placeholder="Select The Purchase Order Number"
            onChange={handlePoSelect}
          >
            {getPurchaseOrder.materials.map((item) => (
              <Option key={item.id} value={item.po_number}>
                {item.po_number}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {cardType === "DATEGRNLIST" && (
        <div style={{ width: 450 }}>
          <Form form={form} onFinish={onFinishDate}>
            <Form.Item
              name="startDate"
              label="Start Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Date!",
                },
              ]}
            >
              <DatePicker
                name="startDate"
                onChange={handleStartDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter End Date!",
                },
              ]}
            >
              <DatePicker
                name="endDate"
                onChange={handleEndDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-createAdmin">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Filter</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
      {cardType === "DATEGRNNUMBER" && (
        <div style={{ width: 450 }}>
          <Form form={form2} onFinish={onFinishDateWiseGrnNumber}>
            <Form.Item
              name="startDate"
              label="Start Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Date!",
                },
              ]}
            >
              <DatePicker
                name="startDate"
                onChange={handleStartDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter End Date!",
                },
              ]}
            >
              <DatePicker
                name="endDate"
                onChange={handleEndDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-createAdmin">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Filter</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
      {cardType === "POWISEGRNLIST" && (
        <Table
          scroll={{ x: "80vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      )}
      {cardType === "DATEGRNLIST" && (
        <Table
          scroll={{ x: "80vw" }}
          columns={columns2}
          dataSource={dataTable}
          pagination={10}
        />
      )}
      {cardType === "DATEGRNNUMBER" && (
        <Table
          scroll={{ x: "80vw" }}
          columns={columns3}
          dataSource={GrnNumberData}
          pagination={10}
        />
      )}
      <Modal
        visible={showGrnItemForm}
        footer={null}
        width={1500}
        onCancel={handelcancel}
      >
        <ShowPoNumberWiseItemsForm />
      </Modal>
    </>
  );
}

export default ShowListOfGrnForm;
