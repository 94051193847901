import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Modal, Table, Tooltip, InputNumber } from "antd";
import "antd/dist/antd.min.css";
import "./Forms/css/AddProjectDetailsForm.css";
import {
  createNewTypesOfProject,
  getAllProj,
  getAllTypeOfProject,
} from "../../../redux-core/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorCreateTypeOfProject,
  selectorGetTypeOfProject,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import useSearch from "../../../hooks/useSearch";
import { DeleteOutlined } from "@ant-design/icons";
const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => getAllProj(orgId, userId),
  typeOfProject: (projectType) =>
    dispatch(createNewTypesOfProject(projectType)),
    getAllTypesProject: (project_id, orgId) =>
    dispatch(getAllTypeOfProject(project_id, orgId)),
});

export const TypesOfProject = (props) => {
  const { getProj, typeOfProject,getAllTypesProject  } = actionDispatch(
    useDispatch()
  );
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const createTypesProject = useSelector(selectorCreateTypeOfProject);
  // const getAllTypesOfProjects = useSelector(selectorGetTypeOfProject)
  const children = [];
  const [typeProject, setTypeProject] = useState("");
  const [showProjectTypesModal, setShowProjectTypesModal] = useState(false);
  const [buildArea, setBuildArea] = useState("");
  const [showTables, setShowTables] = useState(false);
  const [makeHide, setMakeHide] = useState(true);
  const [isDisableInput, setIsDisableInput] = useState(false);
  // const [addInfo, setAddInfo] = useState("");

  useEffect(() => {
    getProj(orgId, userId);
  }, []);

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}-
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  const handleProjectTypes = (value) => {
    setTypeProject(value);
    setShowProjectTypesModal(true);
    getProj(orgId, userId);
  };

  const handlebuilduparea = (values) => {
    setBuildArea(values);
  };

  const modalCancel = () => {
    setShowProjectTypesModal(false);
    form1.setFieldsValue({
      type_of_project:"select"
    })
  };

  const typesOfProjectFinish = async (value) => {
    console.log("value", value)
    // modalCancel()
    setShowTables(true);
    setShowProjectTypesModal(false);
    // setMakeHide(false)
    // setIsDisableInput(true)
    let data = {
      ...value,
      type_of_project: typeProject,
      orgID: orgId,
      createdBy: userId,
      Project_id:props.projectId
    };
    console.log("data",data)
    await typeOfProject(data);
    form.resetFields();
    setAdditionalFields([])
    getAllTypesProject(props.projectId,orgId)
    form1.setFieldsValue({
      type_of_project:""
    })
  
    
  };

  const typesOfProjectFinishFaild = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const showButton = () => {
    setShowTables(false);
    setIsDisableInput(false);
    form.setFieldsValue({
      // Project_id: "",
      buildup_area: [],
      total_buildup_area: "",
      total_saleable_area: "",
      select_UOM: "",
      basement: "",
      ground: "",
      floor: "",
    });
  };

const [addInfo, setAddInfo] = useState(false)
const [additionalFields, setAdditionalFields] = useState([]);
const maxFields = 10; 
const addMoreInfo = () =>{
  if (additionalFields.length < maxFields) {
    setAddInfo(true);
    setAdditionalFields((prevFields) => [...prevFields, {}]);
  }
}


  
  return (
    <>
      {/* {makeHide && ()} */}
      <Form
    form={form1}
      >
        <Form.Item name="type_of_project" label="Types of Project">
          <Select
            allowClear
            style={{ width: "40%" }}
            placeholder="Select Types of Project"
            onChange={handleProjectTypes}
            defaultValue={"select"}
          >
            <option value="select">Select Types of Project</option>
            <option value="building">Building</option>
            <option value="infrastructure">Infrastructure</option>
          </Select>
        </Form.Item>
        </Form>

        {typeProject === "building" && (
          <Modal
            width="90%"
            visible={showProjectTypesModal}
            onCancel={modalCancel}
            footer={null}
          >
            <Form
              form={form}
              onFinish={typesOfProjectFinish}
              onFinishFailed={typesOfProjectFinishFaild}
            >
            
              <Form.Item
                name="buildup_area"
                label="Build Up Areas"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Select Area Type",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select Type of Area"
                  onChange={handlebuilduparea}
                  // disabled={isDisableInput}
                >
                  <option value="totalbuilduparea">BuildUp Area</option>
                  <option value="totalsaleablearea">Saleable Area</option>
                </Select>
              </Form.Item>
              {buildArea === "totalbuilduparea" && (
                <Form.Item
                  className="input-field"
                  name="total_buildup_area"
                  label="Total BuilUp Area"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Total Buildup Area",
                    },
                  ]}
                >
                 <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              )}
              {buildArea === "totalsaleablearea" && (
                <Form.Item
                  className="input-field"
                  name="total_saleable_area"
                  label="Total Saleable Area"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Total Saleable Area",
                    },
                  ]}
                >
                 <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              )}

              <Form.Item
                name="select_UOM"
                label="Select UOM"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Saleable Area",
                  },
                ]}
              >
                <Select
                  placeholder="Select UOM"
                  // disabled={isDisableInput}
                >
                  <option value="sq.mt">Sq.mt</option>
                  <option value="sq.ft">Sq.ft</option>
                </Select>
              </Form.Item>
              <Form.Item
                name="basement"
                label="Basement"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Basement No",
                  },
                ]}
              >
                <InputNumber type="number" placeholder="Enter the Basement No" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="ground"
                label="Ground Level"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Ground No",
                  },
                ]}
              >
                {/* <Input placeholder="Enter Ground No" 
                // disabled={isDisableInput} 
                /> */}
                <Select>
                  <option value="ground">Ground</option>
                  <option value="stilt">Stilt</option>
                </Select>
              </Form.Item>
              <Form.Item
                name="floor"
                label="No of Floors"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Floor No",
                  },
                ]}
              >
                <InputNumber type="number" placeholder="Enter No of Floors" style={{ width: "100%" }} />
              </Form.Item>
             {addInfo && (
              <>
            {additionalFields.map((_, index) => (
  <Form.Item
    key={index}
    className="input-field"
    name={`description${index + 1}`} // Use a dynamic name to avoid conflicts
    label={`Description ${index + 1}`}
    rules={[
      {
        required: false,
        message: `Please Enter field${index + 1}`,
      },
    ]}
    
  >
    <Input placeholder={`Enter description ${index + 1}`} />

  </Form.Item>
))}

             </>
             )}
          <Form.Item
              >
               {additionalFields.length < maxFields && (
          <Button
            type="primary"
            className="btn-submit"
            onClick={addMoreInfo}
            style={{borderRadius: "10px"}}
          >
            <strong>Add More Details</strong>
          </Button>
        )}
        
               
              </Form.Item>
              <Form.Item>
                <div className="btn-project">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit"
                    // disabled={isDisableInput}
                  >
                    <strong>Submit</strong>
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {/* {showTables ? (
<Table  dataSource={data} columns={columns} style={{marginTop:"20px"}}
scroll={{x:"90vw"}} />
      ) : (
        ""
      )} */}
          </Modal>
        )}
     
    </>
  );
};
