import { Button, DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../content/css/RequestForInspection.css";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../redux-core/settings/actions";
import {
  getAllAssignProjectId,
  userDetails,
} from "../../../redux-core/userManagement/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import {
  selectorGetAllUserWiseAssignProjects,
  selectorUserDetail,
} from "../../../redux-core/userManagement/selectors";
import { getProjectIdByUsers } from "../../../redux-core/risk/actions";
import { selectorUsersProjectIdWiseState } from "../../../redux-core/risk/selectors";
import {
  countRfiDatas,
  createNewRfi,
  findStageDetailById,
  findTaskDetailById,
  getAllActivities,
} from "../../../redux-core/qcc/actions";
import {
  selectorActivityDetail,
  selectorCountRfiData,
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../redux-core/qcc/selectors";
import { getFiles } from "../../../redux-core/drawing/action";
import { selectorGetUploadFileState } from "../../../redux-core/drawing/selector";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

const actionDispatch = (dispatch) => ({
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getProjectIdByUsers: (projectId, orgID) =>
    dispatch(getProjectIdByUsers(projectId, orgID)),
  getActivities: (orgId) => dispatch(getAllActivities(orgId)),
  getFiles: (orgId, userId) => dispatch(getFiles(orgId, userId)),
  createNewRfi: (rfiData) => dispatch(createNewRfi(rfiData)),
  countRfiDatas: (projectID, orgID) =>
    dispatch(countRfiDatas(projectID, orgID)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
});
const { Option } = Select;
function RequestForInspection() {
  const Spindata = useSelector((item) => item.spinreducer);
  const [form] = Form.useForm();
  const {
    getAllProj,
    getAllAssignProjectId,
    getAllProjDetails,
    getProjectIdByUsers,
    getActivities,
    getFiles,
    createNewRfi,
    countRfiDatas,
    getUsers,
    taskById,
    getStageById
  } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const getUserProjectWise = useSelector(selectorUsersProjectIdWiseState);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const allActvityData = useSelector(selectorActivityDetail);
  const getAllUploadFile = useSelector(selectorGetUploadFileState);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectDate, setSelectDate] = useState();
  const countRfiData = useSelector(selectorCountRfiData);
  const allUsersData = useSelector(selectorUserDetail);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);


  // useEffect use For page rendering
  useEffect(() => {
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
    getFiles(orgId, userId);
    getActivities(orgId);
    getUsers(orgId);
    taskById(orgId);
  }, []);

  // push project id
  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  //user id wise assign project
  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });


  // select project Id Function
  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(projectId, orgId, userId);
    getProjectIdByUsers(projectId, orgId);
    countRfiDatas(projectId, orgId);
  };

  // search function in select dropdown
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  //project wise Drwaing file
  const DrawingFile = getAllUploadFile.filter(
    (item) => item.project_id == selectedProjectId
  );

  //when select file then buttion activate
  const handleDrawingSelect = (value) => {

    setSelectedDrawing(value);
  };
  const handelCancel = () => {
    setIsOpenModal(false);
  };

  const HandelView = () => {
    setIsOpenModal(true);
  };

  const uploadButton = (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      onClick={HandelView}
    >
      <div
        style={{
          width: "40px", // Set the width
          height: "30px", // Set the height
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px dashed #ccc", // Add a dashed border
          borderRadius: "4px", // Rounded corners
          cursor: "pointer",
        }}
      >
        <EyeOutlined style={{ fontSize: "24px", color: "#aaa" }} />{" "}
        {/* Icon size and color */}
      </div>
    </div>
  );

  const HandelDateSelect = (value, dateString) => {
    setSelectDate(dateString);
  };

  // form submit function
  const HandelOnFinish = async (value) => {
    const data = {
      ...value,
      // rfi_date: selectDate,
      orgID: orgId,
      created_by: userId,
    };
    await createNewRfi(data);
    clear_field();
  };

  // clear field
  const clear_field = () => {
    form.setFieldsValue({
      project_id: "",
      project_detail_id: "",
      qc_engineer: "",
      activity_id: "",
      rfi_date: "",
      description: "",
      drawing_file: [],
      rfi_code: "",
    });
    setSelectedDrawing();
  };

  //find project name
  const ProjectName = allProjects.find(
    (item) => item?.project_id == selectedProjectId
  );
  const projectShotName = ProjectName?.project_name.substring(0, 5);
  let countRfi =
    countRfiData?.[0]?.rfi_code_count == 0
      ? "001"
      : (Number(countRfiData?.[0]?.rfi_code_count) + 1).toString().padStart(3, '0');
  let RfiCode;
  if (ProjectName?.project_name === undefined) {
    RfiCode = "";
  } else {
    RfiCode = projectShotName + "/" + "RFI" + "/" + countRfi;
  }

  //rfi code show in field
  form.setFieldsValue({
    rfi_code: RfiCode,
  });


  const qcEngineerList = allUsersData.filter(
    (item) => item.role === "QCENGINEER"
  );

  const qcEngineerUser = getUserProjectWise.filter((qcUser) =>
    qcEngineerList.some((engineer) => engineer.admin_id == qcUser.user_id)
  );


  //  selected task wise stages
  const HandelSelectTask=(value)=>{
    getStageById(orgId,value)

  }

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="div_box">
          <div className="title-show-project">Request For Inspection</div>
          <br />
          <br />
          <Form
            name="request_for_inspection"
            form={form}
            initialValues={{ remember: true }}
            onFinish={HandelOnFinish}
          >
            <Form.Item name="rfi_code" label="RFI Code" className="input-field">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="project_id"
              label="Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                onChange={(projectId) => handelProjectId(projectId)}
                placeholder="Search or select Project"
              >
                {logindetails.user.role === "ADMIN"
                  ? allProjects.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))
                  : projectData.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="project_detail_id"
              label="Project Location"
              rules={[
                {
                  required: true,
                  message: "Please Select  Project Location !",
                },
              ]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Project Location"
              >
                {allProjDetails
                  .filter(
                    (getAllProjDetails) =>
                      getAllProjDetails.project_id === selectedProjectId
                  )
                  .map((getAllProjDetails) => (
                    <Option
                      key={getAllProjDetails.project_details_id}
                      value={getAllProjDetails.project_details_id}
                    >
                      {getAllProjDetails.block}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="qc_engineer"
              label="QC Engineer"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select QC Engineer !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select QC Engineer"
              >
                {qcEngineerUser.map((item) => (
                  <Option key={item.user_id} value={item.user_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="task_id"
              label="Task"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Task !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Task"
                onChange={HandelSelectTask}
              >
                {taskDetailsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.task}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="stage_id"
              label="Stage"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select Stage  !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Stage"
              >
                {stageDetailsData.map((item) => (
                  <Option key={item.stage_id} value={item.stage_id}>
                    {item.stage_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item
              name="activity_id"
              label="Activity"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Activity !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Activity"
              >
                {allActvityData.map((item) => (
                  <Option key={item.parameter_id} value={item.parameter_id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              name="rfi_date"
              label="Date"
              className="firstRow1"
              rules={[
                {
                  required: true,
                  message: "Please Select Date !",
                },
              ]}
            >
              <DatePicker onChange={HandelDateSelect} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              name="drawing_file"
              label="Drawing"
              className="secondRow1"
              rules={[
                {
                  required: false,
                  message: "Please Select  Drawing File !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Drawing File "
                onSelect={handleDrawingSelect}
              >
                {DrawingFile.map((item) => (
                  <Option key={item.id} value={item.file}>
                    {item.file_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="thirdRow1">
              {selectedDrawing && uploadButton}
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="input-field"
              rules={[
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 charactersonly",
                },
              ]}
            >
              <Input.TextArea
                style={{ maxHeight: "130px", minHeight: "100px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-ehs">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_field} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          title="Drawing Preview"
          visible={isOpenModal}
          footer={null}
          width={1500}
          onCancel={handelCancel}
        >
          <iframe
            className={"application/pdf"}
            width="100%"
            height="700"
            frameborder="10"
            src={selectedDrawing}
          />
        </Modal>
      </Spin>
    </>
  );
}

export default RequestForInspection;
