import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "../css/WithOutPoInvoceCreateFrom.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentStockProjectIdWise,
  insertWithOutPoInvoice,
  createNewItem,
  newInvoiceItem,
  updateWithOutPoItemQty,
  getAllInvoiceItems,
  getAllInvoiceWithOutPo,
  getCountGrnNumber,
  createGrnNumbers,
} from "../../../../redux-core/materials/actions";
import {
  selectorGetItemInCurrentStockProjectWise,
  selectorGetInvoiceItem,
  selectorCountGrnNumber,
} from "../../../../redux-core/materials/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";

const actionDispatch = (dispatch) => ({
  getCurrentStockProjectIdWise: (projeID) =>
    dispatch(getCurrentStockProjectIdWise(projeID)),
  getAllInvoiceWithOutPo: (projeID) =>
    dispatch(getAllInvoiceWithOutPo(projeID)),
  insertWithOutPoInvoice: (invoiceData) =>
    dispatch(insertWithOutPoInvoice(invoiceData)),
  createNewItem: (item) => dispatch(createNewItem(item)),
  newInvoiceItem: (invoiceitem) => dispatch(newInvoiceItem(invoiceitem)),
  updateWithOutPoItemQty: (itemQty) =>
    dispatch(updateWithOutPoItemQty(itemQty)),
  getAllInvoiceItems: (invoiceNumber) =>
    dispatch(getAllInvoiceItems(invoiceNumber)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountGrnNumber: (projeID) => dispatch(getCountGrnNumber(projeID)),
  createGrnNumbers: (grn) => dispatch(createGrnNumbers(grn)),
});
const { Option } = Select;

function WithOutPoMaterialRecieveCreateFrom(props) {
  const {
    getCurrentStockProjectIdWise,
    insertWithOutPoInvoice,
    createNewItem,
    newInvoiceItem,
    updateWithOutPoItemQty,
    getAllInvoiceItems,
    getAllInvoiceWithOutPo,
    getProjectByprojectId,
    getCountGrnNumber,
    createGrnNumbers,
  } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [selectedDoc, setSelectedDoc] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState();
  const [selectedItemId, setSelectedItemId] = useState();
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const getInvoiceItem = useSelector(selectorGetInvoiceItem);
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const [formHide, setFormHide] = useState(false);

  const data = [];
  for (let i = 0; i < getInvoiceItem?.length; i++) {
    data.push({
      id: getInvoiceItem[i].id,
      Item_name: getInvoiceItem[i].Item_name,
      item_code: getInvoiceItem[i].item_code,
      hsn_code: getInvoiceItem[i].hsn_code,
      uom: getInvoiceItem[i].uom,
      qty: getInvoiceItem[i].qty,
      taxable_value: getInvoiceItem[i].taxable_value,
      i_gst: getInvoiceItem[i].i_gst,
      c_gst: getInvoiceItem[i].c_gst,
      s_gst: getInvoiceItem[i].s_gst,
      total_value: getInvoiceItem[i].total_value,
    });
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "HSN Code",
      dataIndex: "hsn_code",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "QTY",
      dataIndex: "qty",
    },
    {
      title: "Taxable Amount(Rs.)",
      dataIndex: "taxable_value",
    },
    {
      title: "I Gst Amount(Rs.)",
      dataIndex: "i_gst",
    },
    {
      title: "C Gst Amount(Rs.)",
      dataIndex: "c_gst",
    },
    {
      title: "S Gst Amount(Rs.)",
      dataIndex: "s_gst",
    },
    {
      title: "Total Amount(Rs.)",
      dataIndex: "total_value",
    },
  ];

  useEffect(() => {
    getCountGrnNumber(props.projectID);
    getProjectByprojectId(props.projectID);
    getCurrentStockProjectIdWise(props.projectID);
  }, [props.projectID]);

  const handleInvoiceDate = (value, dateString) => {
    setInvoiceDate(dateString);
  };

  const resetValue = () => {
    form.setFieldsValue({
      invoice_number: "",
      invoice_date: "",
      suppiler_name: "",
      suppiler_number: "",
      gst_number: "",
      supplier_address: "",
      taxable_value: "",
      gst_value: "",
      total_value: "",
      payment_terms: "",
      upload_invoice: [],
    });
  };

  const normFileForInvoice = (e) => {
    if (e.fileList.length > 0)
      setSelectedDoc({ ...selectedDoc, upload_invoice: true });
    else setSelectedDoc({ ...selectedDoc, upload_invoice: false });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onAddInvoiceFinish = async (value) => {
    if (value?.upload_invoice) {
      let invoiceDocPdfBase64 = await getBase64(
        value?.upload_invoice?.[0]?.originFileObj
      );
      let updatedPdfDoc = {
        ...value.upload_invoice[0],
        thumbUrl: invoiceDocPdfBase64,
      };
      var updatedInvoicePdfDoc = [
        updatedPdfDoc,
        ...value.upload_invoice.slice(1),
      ];
    }
    setInvoiceNumber(value.invoice_number);
    let Data = {
      ...value,
      project_id: props.projectID,
      upload_invoice: updatedInvoicePdfDoc,
     
      orgID: orgId,
      created_by: userId,
    };

    const grn_Number_create = {
      grn_number: value.grn_number,
      project_id: props.projectID,
      grn_date: new Date().toISOString().slice(0, 10),
      orgID: orgId,
      created_by: userId,
    };

    let response = await insertWithOutPoInvoice(Data);
    if (response) {
      setIsDisable(true);
      getAllInvoiceWithOutPo(props.projectID);
      await createGrnNumbers(grn_Number_create);

      setFormHide(true);
    }
  };

  const clear_fields = () => {
    form.setFieldsValue({
      invoice_number: "",
      invoice_date: "",
      suppiler_name: "",
      suppiler_number: "",
      gst_number: "",
      supplier_address: "",
      taxable_value: "",
      gst_value: "",
      total_value: "",
      payment_terms: "",
      upload_invoice: [],
    });
  };

  const onAddInvoiceFailed = (err) => {
    console.log("form Error", err);
  };

  const itemCode = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.item_code);

  const qty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.without_po_qty);

  const totalQty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.total_qty);

  const Item_Rate = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.item_rate);

  const local_purchase_value = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id === selectedItemId)
    .map((item) => item.local_purchase_value);

  useEffect(() => {
    form2.setFieldsValue({
      item_code: itemCode[0],
      invoice_number: invoiceNumber,
    });
  }, [itemCode, invoiceNumber]);

  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const [cardType, setCardType] = useState("");
  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const resetAllValue = () => {
    form2.setFieldsValue({
      item_id: "",
      item_code: "",
      hsn_code: "",
      uom: "",
      qty: "",
      taxable_value: "",
      // gst_type: "",
      c_gst: "",
      s_gst: "",
      i_gst: "",
      total_value: "",
      invoice_number: "",
    });
  };
  const onAddInvoiceItemFinish = async (value) => {
    const InvoiceItemList = {
      ...value,
      invoice_number: invoiceNumber,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };
    await newInvoiceItem(InvoiceItemList);

    const updateItemQty = {
      id: value.item_id,
      without_po_qty: Number(qty[0]) + Number(value.qty),
      total_qty: Number(totalQty[0]) + Number(value.qty),
      item_rate: Number(Item_Rate[0]) + Number(value.taxable_value),
      local_purchase_value:
        Number(local_purchase_value[0]) + Number(value.taxable_value),
    };
    await updateWithOutPoItemQty(updateItemQty);
    resetAllValue();
    getAllInvoiceItems(invoiceNumber);
    setShowTable(true);
  };

  const onAddInvoiceItemFailed = (err) => {
    //console.log("form2 Error", err);
  };
  const addItemInList = () => {
    setIsModalVisible(true);
  };
  const modalClose = () => {
    setIsModalVisible(false);
  };
  const resetModalField = () => {
    form3.resetFields();
  };
  const HandelAddItems = async (value) => {
    const itemData = {
      ...value,
      project_id: props.projectID,
      orgID: orgId,
    };
    await createNewItem(itemData);
    form3.resetFields();
    getCurrentStockProjectIdWise(props.projectID);
  };
  const HandelFinalSubmit = () => {
    resetValue();
    resetAllValue();
    setShowTable(false);
    setIsDisable(false);
    setFormHide(false);

    getCountGrnNumber(props.projectID);
  };

  let count_number =
    countGrnNumber[0].no_of_grn_no === 0
      ? "001"
      : (Number(countGrnNumber[0].no_of_grn_no) + 1).toString().padStart(3, '0');
  const GRN_NUMBER =
    "GRN" + "/" + getProjectByProjId[0].project_code + "/" + count_number;
  useEffect(() => {
    form.setFieldsValue({
      grn_number: GRN_NUMBER,
    });
  }, [GRN_NUMBER]);
  return (
    <>
      <div className="add-file">
        <Form
          name="InsertWithOutPoInvoice"
          form={form}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onAddInvoiceFinish}
          onFinishFailed={onAddInvoiceFailed}
        >
          <Form.Item
            name="grn_number"
            label="GRN Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter GRN Number",
              },
            ]}
            initialValue={GRN_NUMBER}
          >
            <Input placeholder="Enter GRN Number" disabled />
          </Form.Item>
          <Form.Item
            name="invoice_number"
            label="Invoice Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Number",
              },
            ]}
          >
            <Input placeholder="Enter Invoice Number" disabled={isDisable} />
          </Form.Item>

          <Form.Item
            name="invoice_date"
            label="Invoice Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Date",
              },
            ]}
          >
            <DatePicker
              name="invoice_date"
              format="DD-MM-YYYY"
              onChange={handleInvoiceDate}
              placeholder="Enter Date"
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="suppiler_name"
            label="Supplier Name"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Supplier Name",
              },
            ]}
          >
            <Input placeholder="Enter Supplier Name" disabled={isDisable} />
          </Form.Item>
          <Form.Item
            name="suppiler_number"
            label="Mobile Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Mobile Number",
              },
              {
                pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                // pattern: /^[\d]{10}$/,
                // message: "Contact number should be 10 digits",
                message: "Please Enter The Correct Contact Number",
              },
            ]}
          >
            <Input placeholder="Enter Mobile Number" disabled={isDisable} />
          </Form.Item>

          <Form.Item
            name="gst_number"
            label="GST Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter GST Number",
              },
              {
                pattern:
                  "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                message: "Please Valid GST Value!",
              },
            ]}
          >
            <Input placeholder="Enter GST Number" disabled={isDisable} />
          </Form.Item>

          <Form.Item
            name="supplier_address"
            label="Supplier Address"
            rules={[
              { required: true, message: "Please Fill Supplier Address!" },
            ]}
            className="input-field"
          >
            <Input.TextArea
              style={{ maxHeight: "70px", minHeight: "70px" }}
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="taxable_value"
            label="Taxable Value (Rs.)"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Taxable Value",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enter Invoice Taxable Value"
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="gst_value"
            label="GST Value (Rs.)"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice GST Value",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enter Invoice GST Value"
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="total_value"
            label="Total Value (Rs.)"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Total Value",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Enter Invoice Total Value"
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item
            name="payment_terms"
            label="Payment T&C"
            rules={[
              { required: false, message: "Please Fill Payment T&C Field!" },
            ]}
            className="input-field"
          >
            <Input.TextArea
              style={{ maxHeight: "70px", minHeight: "70px" }}
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item
            name="upload_invoice"
            label="Upload Invoice"
            valuePropName="fileList"
            getValueFromEvent={normFileForInvoice}
            className="input-field"
            rules={[{ required: true, message: "Please Select File" }]}
          >
            <Upload
              name="upload_invoice"
              listType="upload_invoice"
              accept=".pdf"
              disabled={isDisable}
              beforeUpload={(file, fileList) => {
                // Do something with the file, like uploading it
                // Return false to prevent the default behavior
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Choose Invoice</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ marginLeft: 15, borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>
              <Button onClick={() => resetValue()} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      {formHide && (
        <div className="add-file">
          <div className="btn-project">
            <h5>When not available item in select dropdown you add here</h5>
            <Button
              onClick={() => addItemInList()}
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Add Item </strong>
            </Button>
          </div>
          <Form
            name="addInvoiceItem"
            form={form2}
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onAddInvoiceItemFinish}
            onFinishFailed={onAddInvoiceItemFailed}
          >
            <Form.Item
              name="item_id"
              label="Item Name"
              rules={[{ required: true, message: "Please select Item!" }]}
              className="input-field1"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Item Name"
                onChange={(itemID) => setSelectedItemId(itemID)}
                filterOption={handleSearch}
              >
                {getShowItemInCurrentStockProjectWise.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.Item_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="item_code"
              label="Item Code"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter item Code",
                },
              ]}
              initialValue={itemCode[0]}
            >
              <Input placeholder="Enter Item Code" disabled />
            </Form.Item>
            <Form.Item
              name="invoice_number"
              label="Invoice Number"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "First Submit the invoice Data",
                },
              ]}
              initialValue={invoiceNumber}
            >
              <Input placeholder="Enter Invoice Number" disabled />
            </Form.Item>
            <Form.Item
              name="hsn_code"
              label="HSN Code"
              className="input-field"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter Hsn Code"
              //   }
              // ]}
            >
              <Input placeholder="Enter Hsn Code" />
            </Form.Item>
            <Form.Item
              name="uom"
              label="UOM"
              className="input-field"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter UOM Code"
              //   }
              // ]}
            >
              <Input placeholder="Enter UOM Code" />
            </Form.Item>
            <Form.Item
              name="qty"
              label="Item QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Item Qty",
                },
              ]}
            >
              <Input type="number" placeholder="Enter Item Qty" />
            </Form.Item>
            <Form.Item
              name="taxable_value"
              label="Taxable Value (Rs.)"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Invoice Taxable Value",
                },
              ]}
            >
              <Input type="number" placeholder="Enter Invoice Taxable Value" />
            </Form.Item>
            <Form.Item
              className="input-field"
              name="gst_type"
              label="GST Type (IGst / CGst, SGst)"
              rules={[
                {
                  required: true,
                  message: "Please Select GST Type (IGst / CGst, SGst)",
                },
              ]}
            >
              <Select onChange={handleCardTypeChange}>
                <Option value="state">State Gst</Option>
                <Option value="intra">Itra State</Option>
                <Option value="None">None</Option>
              </Select>
            </Form.Item>
            {cardType === "state" && (
              <Form.Item
                className="input-field"
                name="c_gst"
                label="C GST"
                rules={[
                  {
                    required: true,
                    message: "Please Fill Gst Value (Rs.)",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {cardType === "state" && (
              <Form.Item
                className="input-field"
                name="s_gst"
                label="S GST"
                rules={[
                  {
                    required: true,
                    message: "Please Fill Gst Value (Rs.)",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {cardType === "intra" && (
              <Form.Item
                className="input-field"
                name="i_gst"
                label="I GST"
                rules={[
                  {
                    required: true,
                    message: "Please Fill Gst Value (Rs.)",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="total_value"
              label="Total Value (Rs.)"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Invoice Total Value",
                },
              ]}
            >
              <Input type="number" placeholder="Enter Invoice Total Value" />
            </Form.Item>
            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ marginLeft: 15, borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
                <Button onClick={() => resetAllValue()} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
          {showTable && (
            <Table
              scroll={{ x: "120vw" }}
              columns={columns}
              dataSource={data}
              pagination={10}
            />
          )}
          <div className="btn-project">
            <Button
              type="primary"
              htmlType="submit"
              onClick={HandelFinalSubmit}
              className="btn-submit"
              style={{ marginLeft: 15, borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>
          </div>
        </div>
      )}

      <Modal
        visible={isModalVisible}
        onCancel={modalClose}
        width={1000}
        S
        footer={null}
      >
        <Form
          name="addItem"
          form={form3}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={HandelAddItems}
        >
          <Form.Item
            name="Item_name"
            label="Item Name "
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Item Name",
              },
            ]}
          >
            <Input placeholder="Enter Item Name" />
          </Form.Item>
          <Form.Item
            name="item_code"
            label="Item Code "
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Item Code",
              },
            ]}
          >
            <Input placeholder="Enter Item Code" />
          </Form.Item>
          <Form.Item>
            <div className="btn-project">
              <Button onClick={resetModalField} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ marginLeft: 15 }}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function getBase64(file) {
  //console.log("file::", file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default WithOutPoMaterialRecieveCreateFrom;
