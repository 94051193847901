import "./css/EhsShow.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  Spin,
  DatePicker,
  Image,
  Space,
  Upload,
  Badge,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import {
  getEhs,
  deleteByEhsId,
  updateByEhsId,
  riskCountAction,
  getUpdateResponseIssueData,
} from "../../../redux-core/risk/actions";
import { selectorGetEhsState } from "../../../redux-core/risk/selectors";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../redux-core/settings/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import moment from "moment";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";

const actionDispatch = (dispatch) => ({
  getEhs: (orgId, userId) => dispatch(getEhs(orgId, userId)),
  deleteByEhsId: (deleteEhsId) => dispatch(deleteByEhsId(deleteEhsId)),
  updateByEhsId: (updateEhsId) => dispatch(updateByEhsId(updateEhsId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
  riskCountAction: (orgId) => dispatch(riskCountAction(orgId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getUpdateResponseIssueData: (responseData) =>
    dispatch(getUpdateResponseIssueData(responseData)),
});

const { Option } = Select;

const EhsShow = () => {
  const getColumnSearchProps = useSearch();
  const {
    getAllProj,
    getAllProjDetails,
    getEhs,
    deleteByEhsId,
    updateByEhsId,
    riskCountAction,
    getAllAssignProjectId,
    getUpdateResponseIssueData,
  } = actionDispatch(useDispatch());
  const getEhsData = useSelector(selectorGetEhsState);
  const logindetails = useSelector(makeSelectLoginDetail);
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [filteredData, setFilteredData] = useState([]);
  const [keys, setKeys] = useState();
  const [ehsRow, setEhsRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const children = [];
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const Spindata = useSelector((item) => item.spinreducer);
  const [activedata, setActivedata] = useState(true);
  const [responseModal, setResponeModal] = useState(false);
  const [responseDate, setResponseDate] = useState();
  const [issueId, setIssueId] = useState();
  const [responseEditDate, setResponseEditDate] = useState();
  const [targetEditDate, setTargetEditDate] = useState();
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);

  const handlePreview = async (file) => {
    // console.log("file.preview",!file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewimage(file.url || file.preview);

    setPreviewvisible(true);
  };

  useEffect(() => {
    getEhs(orgId, userId);
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  // console.log("getEhsData", getEhsData);
  // console.log("userId", userId);
  let tableData = [];
  for (let i = 0; i < getEhsData.length; i++) {
    let startDate = new Date(getEhsData[i].resolve_date);
    // const responseDate = new Date(getEhsData[i].response_date);
    // const timeZoneOffset = responseDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // responseDate.setMinutes(responseDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = responseDate.toISOString().slice(0, 10);

    // const targetDate = new Date(getEhsData[i].target_date);
    // const timeZoneOffsets = targetDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // targetDate.setMinutes(targetDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedTargetDate = targetDate.toISOString().slice(0, 10);

    if (getEhsData[i].response == userId) {
      tableData.push({
        sl: i + 1,
        key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    } else if (getEhsData[i].created_by == userId) {
      tableData.push({
        sl: i + 1,
        key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    }
  }

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };
  //---------------- modal and forms-----------------------------

  const onEditEhsFinish = async (values) => {
    //conversion of array of string to array of numbers

    let data = {
      id: ehsRow.key,
      project_id: values.project_id,
      project_detail_id: values.project_detail_id,
      problem_area: values.problem_area,
      correction_action: values.correction_action,
      // response: values.response,
      response_date: moment(values.response_date).format("YYYY-MM-DD"),
      target_date: moment(values.target_date).format("YYYY-MM-DD"),
      remark: values.remark,
      status: values.status,
    };
    // console.log("Data--", data);
    let response = await updateByEhsId(data);
    if (response) {
      await clear_fields();
      await getEhs(orgId, userId);
      await setIsModalVisible(false);
    }
    clear_fields();
  };

  const onEditEhsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log("responseEditDate", responseEditDate);
  // console.log("targetEditDate", targetEditDate);
  const showModal = (data1) => {
    setEhsRow(data1);

    setResponseEditDate(moment(data1.response_date));

    setTargetEditDate(moment(data1.target_date));

    setIsModalVisible(true);
    form.setFieldsValue({
      project_id: data1.project_id,
      project_detail_id: data1.project_detail_id,
      problem_area: data1.problem_area,
      correction_action: data1.correction_action,
      response: data1.response,
      response_date: moment(data1.response_date, "DD-MM-YYYY"),
      target_date: moment(data1.target_date, "DD-MM-YYYY"),
      remark: data1.remark,
    });
  };

  const handleCancels = () => setPreviewvisible(false);

  const handleCancel = () => {
    setIsModalVisible(false);

    setResponeModal(false);
  };
  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const clear_fields = () => {
    form.setFieldsValue({
      project_id: ehsRow.project_id,
      project_detail_id: ehsRow.project_detail_id,
      problem_area: ehsRow.problem_area,
      correction_action: ehsRow.correction_action,
      response: ehsRow.response,
      response_date: moment(ehsRow.response_date,"DD-MM-YYYY"),
      target_date: moment(ehsRow.target_date, "DD-MM-YYYY"),
      remark: ehsRow.remark,
    });
  };

  //  for Excel

  let tableExcel = [];
  for (let i = 0; i < getEhsData.length; i++) {
    let startDate = new Date(getEhsData[i].resolve_date);
    // const responseDate = new Date(getEhsData[i].response_date);
    // const timeZoneOffset = responseDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // responseDate.setMinutes(responseDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = responseDate.toISOString().slice(0, 10);

    // const targetDate = new Date(getEhsData[i].target_date);
    // const timeZoneOffsets = targetDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // targetDate.setMinutes(targetDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedTargetDate = targetDate.toISOString().slice(0, 10);

    if (getEhsData[i].response == userId) {
      tableExcel.push({
        sl: i + 1,
        // key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    } else if (getEhsData[i].created_by == userId) {
      tableExcel.push({
        sl: i + 1,
        // key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    }
  }

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "sl",
      // ...getColumnSearchProps("sl"),
    },
    {
      title: "Project ",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "Project Location ",
      dataIndex: "project_detail_id",
      //  ...getColumnSearchProps("project_detail_id"),
    },
    {
      title: "Problem ",
      dataIndex: "problem_area",
      ...getColumnSearchProps("problem_area"),
    },
    {
      title: "Problem Raised Person  ",
      dataIndex: "create_person",
      ...getColumnSearchProps("create_person"),
    },
    {
      title: "Correction ",
      dataIndex: "correction_action",
      //  ...getColumnSearchProps("correction_action"),
    },
    {
      title: "Assign Responsible Person",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      // width: '20%',
    },
    {
      title: "Response Date",
      dataIndex: "response_date",
      //...getColumnSearchProps("response_date"),
    },
    {
      title: "Target Date",
      dataIndex: "target_date",
      //  ...getColumnSearchProps("target_date"),
    },
    {
      title: "Description",
      dataIndex: "remark",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Response Date",
      dataIndex: "resolve_date",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Resolve Issue Image",
      dataIndex: "resolve_image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "7%",
      render: (text) => {
        let statusColor;
        switch (text) {
          case "OPEN":
            statusColor = "blue";
            break;
          case "PENDING":
            statusColor = "red";
            break;
          case "CLOSE":
            statusColor = "green";
            break;
          default:
            statusColor = "default";
        }

        return <Badge status={statusColor} text={text} />;
      },
    },

    {
      title: "Responses",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => HandeRespone(record)}>
              <EditOutlined />
              Response
            </Button>
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (key) => {
    await deleteByEhsId(key);
    await getEhs(orgId, userId);
    await riskCountAction(orgId);
    // count(orgID);
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(projectId, orgId, userId);
  };

  const HandeRespone = (data) => {
    // console.log("data", data.key);
    setIssueId(data.key);
    setResponeModal(true);
    form1.setFieldsValue({
      problem_area: data.problem_area,
      target_date: moment(data.target_date, "DD-MM-YYYY"),
    });
  };

  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const clear_value = () => {
    form1.setFieldsValue({
      resolve_date: "",
      resolve_image: [],
      comment: "",
    });
  };

  const handelResponceDate = (value, dateString) => {
    console.log("dateString", dateString)
    setResponseDate(dateString);
  };

  const handelSubmitResponce = async (value) => {
    //console.log("value",value)
    const data = {
      id: issueId,
      resolve_date: value.resolve_date,
      resolve_image: value.resolve_image,
      comment: value.comment,
    };
    await getUpdateResponseIssueData(data);
    // console.log("value", data);
    clear_value();
    handleCancel();
    getEhs(orgId, userId);
  };

  const options = [
    { value: "OPEN", label: "Open" },
    { value: "PENDING", label: "Pending" },
    { value: "CLOSE", label: "Close" },
  ];

  // select date YYYY-MM-DD format convert function
  const handelSelectResponseDate = (value, dateString) => {
    setResponseEditDate(dateString);
  };

  const handelSelectTargetDate = (value, dateString) => {
    setTargetEditDate(dateString);
  };
  const validateTargetDate = async (_, targetDate) => {
    const responseDate = form.getFieldValue("response_date");

    if (moment(targetDate).isSameOrBefore(responseDate)) {
      throw new Error("Target Date must be greater than Response Date");
    }
  };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">Show Site Observation</div>
        <br />
        <div className="btn__">
          {/* <Button className="pdf">Export to PDF</Button> */}
          <Button className="excel">
            <CSVLink
              filename={"Site Obseravation.csv"}
              data={tableExcel}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          {/* <Button className="print">Print</Button>
                <Button className="copy">Copy</Button> */}
        </div>
        <Table
          className="table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          pagination={10}
          scroll={{ x: "110vw" }}
        />
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={700}
          centered={true}
          footer={null}
        >
          <div className="edit-in__charge">Edit Site Observation</div>
          <br />

          <Form
            form={form}
            name="add-ehs"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditEhsFinish}
            onFinishFailed={onEditEhsFinishFailed}
          >
            {logindetails.user.role === "ADMIN" ? (
              <Form.Item
                name="project_id"
                label="Select Project"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Select Project Name !",
                  },
                ]}
              >
                <Select
                  className="input-field"
                  //   onKeyDown={(e) => onKeydown(e)}
                  // onChange={(projectId) => setSelectedProjectId(projectId)}
                  onChange={(projectId) => handelProjectId(projectId)}
                >
                  {allProjects.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="project_id"
                label="Select Project"
                className="input-field"
                rules={[
                  {
                    required: true,
                    message: "Please Select Project!",
                  },
                ]}
              >
                <Select
                  className="input-field"
                  // onChange={(projectId) => setSelectedProjectId(projectId)}
                  onChange={(projectId) => handelProjectId(projectId)}
                >
                  {projectData.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              name="project_detail_id"
              label="Project Location"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Location !",
                },
              ]}
              className="input-field"
            >
              <Select className="input-field">
                {allProjDetails
                  .filter(
                    (getAllProjDetails) =>
                      getAllProjDetails.project_id === selectedProjectId
                  )
                  .map((getAllProjDetails) => (
                    <Option
                      key={getAllProjDetails.project_details_id}
                      value={getAllProjDetails.block}
                    >
                      {getAllProjDetails.block}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="problem_area"
              label="Problem Area"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Problem Area!",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="correction_action"
              label="Correction Action"
              className="input-field"
              rules={
                [
                  // {
                  //   required: true,
                  //   message: "Please enter Currection Action!",
                  // },
                ]
              }
            >
              <Input placeholder="" />
            </Form.Item>
            {/* <Form.Item name="response" label="Response" className="input-field">
              <Input placeholder="" />
            </Form.Item> */}
            <Form.Item
              name="response_date"
              label="Response Date"
              rules={[
                {
                  required: true,
                  message: "Please select Response Date!",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
              className="input-field"
            >
              <DatePicker format="DD-MM-YYYY" onChange={handelSelectResponseDate} />
            </Form.Item>
            <Form.Item
              name="target_date"
              label="Target Date"
              rules={[
                {
                  required: true,
                  message: "Please select Target Date!",
                },
                {
                  validator: validateTargetDate, // Use the custom validation function
                },
              ]}
              className="input-field"
            >
              <DatePicker format="DD-MM-YYYY" onChange={handelSelectTargetDate} />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please Select Status!",
                },
              ]}
              className="input-field"
            >
              <Select
                placeholder="Please select Status"
                value={options.value}
                options={options}
                defaultValue={options[""]}
              ></Select>
            </Form.Item>
            <Form.Item name="remark" label="Remark" className="input-field">
              <Input placeholder="" />
            </Form.Item>

            <Form.Item>
              <div className="btn-ehs">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_fields} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={responseModal}
          onCancel={handleCancel}
          width={1200}
          centered={true}
          footer={null}
        >
          <div className="edit-in__charge">Site Observation give Response</div>
          <Form form={form1} onFinish={handelSubmitResponce}>
            <Form.Item
              name="problem_area"
              label="Problem"
              className="input-field"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="target_date"
              label="Target Date"
              className="input-field"
            >
              <DatePicker format="DD-MM-YYYY" disabled />
            </Form.Item>

            <Form.Item
              name="resolve_date"
              label="Issue Resolve Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Date!",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" onChange={handelResponceDate} />
            </Form.Item>
            <Form.Item
              name="resolve_image"
              label="Resolve Problem Image"
              className="input-field"
              valuePropName="fileList"
              getValueFromEvent={normFile}
                 rules={[
                   {
                     required: true,
                     message: "Please upload Resolve image!",
                   },
                 ]}
            >
              <Upload
                name="logo"
                listType="picture"
                accept=".jpg,.jpeg,.png"
                beforeUpload={(file, fileList) => {
                  return false;
                }}
                onPreview={handlePreview}
              >
                <Button icon={<UploadOutlined />}>Choosen File</Button>
              </Upload>
            </Form.Item>
            <Form.Item name="comment" label="Comments" className="input-field">
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
                placeholder="Comments"
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-ehs">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_value} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <br />
        {getEhsData.length > 0 && (
          <Button
            style={{ display: activedata ? "" : "none" }}
            className="deleteallbutton copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )}
        <Modal open={previewVisible} footer={null} onCancel={handleCancels}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(EhsShow);
