import React, { useCallback, useEffect, useState } from "react";
import { ContainerOutlined } from "@ant-design/icons";
import { Button, Card, Image } from "antd";
import "./css/MyProjects.css";
import {
  makeSelectAllProj,
  selectorGetProjByProjId,
} from "../../../redux-core/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import ShowMaterialStock from "./Forms/ShowMaterialStock";
import { getCurrentStockProjectIdWise } from "../../../redux-core/materials/actions";
import MaterialIssueFrom from "./Forms/MaterialIssueFrom";
import { getCountIssueNumber } from "../../../redux-core/materials/actions";
import { getProjectByprojectId } from "../../../redux-core/settings/actions";
import { selectorCountIssueNumber } from "../../../redux-core/materials/selectors";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";

const actionDispatch = (dispatch) => ({
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),

  getCurrentStockProjectIdWise: (projectID) =>
    dispatch(getCurrentStockProjectIdWise(projectID)),
  getCountIssueNumber: (projectID) => dispatch(getCountIssueNumber(projectID)),
  getProjectByprojectId: (projectID) =>
    dispatch(getProjectByprojectId(projectID)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
});

function MaterialIssueInCurrentStock() {
  const [content, setContent] = useState("1");
  const [projectId, setProjectId] = useState();
  const logindetails = useSelector(makeSelectLoginDetail);
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const {
    projects,
    getCurrentStockProjectIdWise,
    getCountIssueNumber,
    getAllAssignProjectId,
  } = actionDispatch(useDispatch());
  const countIssueNumber = useSelector(selectorCountIssueNumber);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );

  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, []);

  const onAddPurchaseBtn = useCallback((projectID) => {
    setContent("2");

    setProjectId(projectID);
    getCurrentStockProjectIdWise(projectID);
    getCountIssueNumber(projectID);
    getProjectByprojectId(projectID);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  const projectData = ProjID.flatMap((item) => {
    return allProjectsSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

const backProject=()=>{
  setContent("1")
}

  return (
    <>
      {logindetails.user.role === "ADMIN" ? (
        <span className="card-container">
          {content === "1"
            ? allProjectsSelectorData.map((data) => {
                return (
                  <Card
                    className="projectCard"
                    // hoverable
                    style={{ width: 250, borderRadius: "5%" }}
                    key={data.project_id}
                    onClick={() => onAddPurchaseBtn(data.project_id)}
                  >
                    <div className="sub-main">
                      <div className="top">
                        <span className="left__value">
                          <b>Project Name: </b>
                          {data.project_name}
                        </span>
                        <span className="right__icon">
                          <Image width={50} src={data.image} alt="No Image" />
                        </span>
                      </div>
                      <div className="bottom__title">
                        <b>Description: </b>
                        {data.description}
                      </div>
                      {/* <div className="work_order">
                        <Button onClick={()=>onAddPurchaseBtn(data.project_id)} className="work_order_btn">Material Issue In Stock</Button>
                     </div> */}
                    </div>
                  </Card>
                );
              })
            : ""}
        </span>
      ) : (
        <span className="card-container">
          {content === "1"
            ? projectData.map((data) => {
                return (
                  <Card
                    className="projectCard"
                    // hoverable
                    style={{ width: 250, borderRadius: "5%" }}
                    key={data.project_id}
                    onClick={() => onAddPurchaseBtn(data.project_id)}
                  >
                    <div className="sub-main">
                      <div className="top">
                        <span className="left__value">
                          <b>Project Name: </b>
                          {data.project_name}
                        </span>
                        <span className="right__icon">
                          <Image width={50} src={data.image} alt="No Image" />
                        </span>
                      </div>
                      <div className="bottom__title">
                        <b>Description: </b>
                        {data.description}
                      </div>
                      {/* <div className="work_order">
                        <Button onClick={()=>onAddPurchaseBtn(data.project_id)} className="work_order_btn">Material Issue In Stock</Button>
                     </div> */}
                    </div>
                  </Card>
                );
              })
            : ""}
        </span>
      )}

      {content === "2" ? <MaterialIssueFrom projectID={projectId} backProject={backProject} /> : ""}
    </>
  );
}

export default MaterialIssueInCurrentStock;
