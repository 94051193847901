/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import SideNav from "../../components/settings/sidenav/SideNav";
import "antd/dist/antd.min.css";
// eslint-disable-next-line no-unused-vars
import MediaQuery from "react-responsive";
// eslint-disable-next-line no-unused-vars
import { Card, Button, message, Tooltip } from "antd";
import "./HomePage.css";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import history from "../../utils/history";
import { countAction } from "../../redux-core/settings/actions";
import { countActionUserManagement } from "../../redux-core/userManagement/actions";
import { countActionQCC } from "../../redux-core/qcc/actions";
import { riskCountAction } from "../../redux-core/risk/actions";
import { getResourcesDashboard } from "../../redux-core/resources/actions";
import { drawingCountAction } from "../../redux-core/drawing/action";
import { makeSelectLoginDetail } from "../../redux-core/login/selectors";
import { Showlogout } from "../popupmodal";
import { SettingOutlined } from "@ant-design/icons";
import Sidebar from "../Core/Sidebar";
import TopNavbar from "../settings/topnavbar/TopNavbar";
import SideNavReports from "../reports/sidenav/SideNavReports";
import SideNavContracts from "../contracts/sidenav/SideNavContract";
const { Meta } = Card;
const actionDispatch = (dispatch) => ({
  countSettings: (orgId) => dispatch(countAction(orgId)),
  countUserManagement: (orgId) => dispatch(countActionUserManagement(orgId)),
  countQCC: (orgId) => dispatch(countActionQCC(orgId)),
  riskCountAction: (orgId) => dispatch(riskCountAction(orgId)),
  getResourcesDashboard: (orgId) => dispatch(getResourcesDashboard(orgId)),
  drawingCountAction: (orgId) => dispatch(drawingCountAction(orgId)),
});
const HomePage = (props) => {
  const {
    countSettings,
    countUserManagement,
    countQCC,
    riskCountAction,
    getResourcesDashboard,
    drawingCountAction,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await countSettings(logindetails.organisation.orgID);
    await countUserManagement(logindetails.organisation.orgID);
    await countQCC(logindetails.organisation.orgID);
    await riskCountAction(logindetails.organisation.orgID);
    await getResourcesDashboard(logindetails.organisation.orgID);
    await drawingCountAction(logindetails.organisation.orgID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate();
  const onLogOut = () => {
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("refreshToken");
    navigate("/login");
    history.push("/login");
    document.location.reload(true);
    message.success("Logout Succesfull");
  };
  const handleLogout = () => {
    Showlogout({ onConfirm: () => onConfirm([]) });
  };

  const onConfirm = () => {
    onLogOut();
  };

  const settingsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/settings");
      history.push("/settings");
    } else if (logindetails.user.role === "SUPERADMIN") {
      navigate("/settings");
      history.push("/settings");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };
  // const userManagementPageHandler = () => {
  //     navigate('/usermanagement');
  //     history.push('/usermanagement');
  // }

  const qccPageHandler = () => {
    if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "ADMIN") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "SITEENGINEER") {
      navigate("/qcc");
      history.push("/qcc");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const resourcesHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "SITEENGINEER") {
      navigate("/resources");
      history.push("/resources");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const riskHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "PLANNINGENGINEER") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "SITEENGINEER") {
      navigate("/risk");
      history.push("/risk");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const contractsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "BILLINGENGINEER") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "SITEENGINEER") {
      navigate("/contracts");
      history.push("/contracts");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const drawingPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/drawings");
      history.push("/drawings");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const materialPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "PROCUREMENTMANAGER") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "SITEENGINEER") {
      navigate("/materials");
      history.push("/materials");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const reportPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SUPERADMIN") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/reports");
      history.push("/reports");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin!!"
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  const user = (
    <p>
      User
      <br />
      Management
    </p>
  );

  const cardTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
  };

  const openSettings = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/settings");
      history.push("/settings");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const [sharedData, setSharedData] = useState("");

  // Function to update shared data
  const updateSharedData = (data) => {
    setSharedData(data);
  };

  return (
    <>
      {/* <div>
        <TopNavbar />
        <div className=" flex ">
          <Sidebar updateSharedData={updateSharedData} />
          {sharedData == "settings" && <SideNav />}
          {sharedData == "reports" && <SideNavReports />}
          {sharedData == "contracts" && <SideNavContracts />}
        </div>
      </div> */}

      <div style={{ width: "100%", height: "100va" }} className="bgimage">
        <div className="logout__button">
          {logindetails.user.role === "ADMIN" && (
            <div className="iconsetting" onClick={openSettings}>
              <Tooltip title="Settings">
                <SettingOutlined style={{ fontSize: "35px" }} />
              </Tooltip>
            </div>
            
          )}
          <div className="text">Site Management System</div>
          <div className="settingicon">
            <Tooltip title="Logout">
              <img
                className="logout_img"
                alt="logout"
                src="logout.png"
                style={{ width: 40 }}
                onClick={handleLogout}
              />
            </Tooltip>
          </div>
        </div>
        {/* main start ftrom here */}
        <div className="card__component">
          <div className="cards">
            {logindetails.user.role === "SUPERADMIN" && (
              <>
                <div
                  className="superHome"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="superCard">
                    <Card
                      // hoverable
                      onClick={settingsPageHandler}
                      style={{ width: 150, borderRadius: "5%" }}
                      className="your-card"
                      cover={<img alt="example" src="setting_color.png" />}
                    >
                      {/* <Meta title="Settings" /> */}
                      <Meta
                        title={<span style={cardTitleStyle}>Settings</span>}
                      />
                    </Card>
                  </div>
                  <div className="superCard">
                    <Card
                      // hoverable
                      onClick={reportPageHandler}
                      style={{ width: 150, borderRadius: "5%" }}
                      cover={<img alt="example" src="report_color.png" />}
                    >
                      <Meta
                        title={<span style={cardTitleStyle}>Reports</span>}
                      />
                    </Card>
                  </div>
                </div>
              </>
            )}
            {logindetails.user.role === "ADMIN" ? (
              <>
                {/* <Card
                  // hoverable
                  onClick={settingsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  className="your-card"
                  cover={
                    <img
                      alt="example"
                      src="setting_color.png"
                    />
                  }
                >
                  <Meta title="Settings" />
                  <Meta title={<span style={cardTitleStyle}>Settings</span>} />
                </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "SITEINCHARGE" ? (
              <>
                {/* <Card
                  // hoverable
                  onClick={settingsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  className="your-card"
                  cover={
                    <img
                      alt="example"
                      src="setting_color.png"
                    />
                  }
                >
                  // {/* <Meta title="Settings" /> *
                  <Meta title={<span style={cardTitleStyle}>Settings</span>} />
                </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "SUPERVISOR" ? (
              <>
                {/* <Card
                  // hoverable
                  onClick={settingsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  className="your-card"
                  cover={
                    <img
                      alt="example"
                      src="setting_color.png"
                    />
                  }
                >
                  <Meta title="Settings" />
                  <Meta title={<span style={cardTitleStyle}>Settings</span>} />
                </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "DRAWINGARCHITECTURE" ? (
              <>
                {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "BILLINGENGINEER" ? (
              <>
                {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "QCENGINEER" ? (
              <>
                {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "PROCUREMENTMANAGER" ? (
              <>
                {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "PLANNINGENGINEER" ? (
              <>
                {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                <Card
                  // hoverable
                  onClick={qccPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="qcc_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={resourcesHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="resource.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Resources</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={riskHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="Image Not Found" src="schedule_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Progress</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={drawingPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="drawing.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Documents</span>} />
                </Card>
              </>
            ) : (
              logindetails.user.role === "SITEENGINEER" && (
                <>
                  {/* <Card
                    // hoverable
                    onClick={settingsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    className="your-card"
                    cover={
                      <img
                        alt="example"
                        src="setting_color.png"
                      />
                    }
                  >
                    <Meta title="Settings" />
                    <Meta
                      title={<span style={cardTitleStyle}>Settings</span>}
                    />
                  </Card> */}

                  <Card
                    // hoverable
                    onClick={qccPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="example" src="qcc_color.png" />}
                  >
                    <Meta title={<span style={cardTitleStyle}>QCC</span>} />
                  </Card>

                  <Card
                    // hoverable
                    onClick={resourcesHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="Image Not Found" src="resource.png" />}
                  >
                    <Meta
                      title={<span style={cardTitleStyle}>Resources</span>}
                    />
                  </Card>
                  <Card
                    // hoverable
                    onClick={riskHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={
                      <img alt="Image Not Found" src="schedule_color.png" />
                    }
                  >
                    <Meta
                      title={<span style={cardTitleStyle}>Progress</span>}
                    />
                  </Card>
                  <Card
                    // hoverable
                    onClick={drawingPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="example" src="drawing.png" />}
                  >
                    <Meta
                      title={<span style={cardTitleStyle}>Documents</span>}
                    />
                  </Card>
                </>
              )
            )}
          </div>
          <div className="cards_1">
            {logindetails.user.role === "ADMIN" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "SITEINCHARGE" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "SUPERVISOR" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "DRAWINGARCHITECTURE" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "BILLINGENGINEER" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "QCENGINEER" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "PROCUREMENTMANAGER" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : logindetails.user.role === "PLANNINGENGINEER" ? (
              <>
                <Card
                  // hoverable
                  onClick={contractsPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="contract_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Contracts</span>} />
                </Card>
                <Card
                  // hoverable
                  onClick={materialPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="material_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Materials</span>} />
                </Card>

                <Card
                  // hoverable
                  onClick={reportPageHandler}
                  style={{ width: 150, borderRadius: "5%" }}
                  cover={<img alt="example" src="report_color.png" />}
                >
                  <Meta title={<span style={cardTitleStyle}>Reports</span>} />
                </Card>
              </>
            ) : (
              logindetails.user.role === "SITEENGINEER" && (
                <>
                  <Card
                    // hoverable
                    onClick={contractsPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="example" src="contract_color.png" />}
                  >
                    <Meta
                      title={<span style={cardTitleStyle}>Contracts</span>}
                    />
                  </Card>
                  <Card
                    // hoverable
                    onClick={materialPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="example" src="material_color.png" />}
                  >
                    <Meta
                      title={<span style={cardTitleStyle}>Materials</span>}
                    />
                  </Card>

                  <Card
                    // hoverable
                    onClick={reportPageHandler}
                    style={{ width: 150, borderRadius: "5%" }}
                    cover={<img alt="example" src="report_color.png" />}
                  >
                    <Meta
                      title={
                        <span className="  " style={cardTitleStyle}>
                          Reports
                        </span>
                      }
                    />
                  </Card>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
