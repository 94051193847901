import React, { Suspense, useCallback, useState } from "react";
import { Layout, Menu, Spin } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  LineChartOutlined,
  ContainerOutlined,
  ClockCircleOutlined,
  FileAddOutlined,
  FormOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import "./SideNavQCC.css";
import "antd/dist/antd.min.css";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import MediaQuery from "react-responsive";
import Dashboard from "../content/Dashboard";
import Checklist from "../content/Checklist";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import RequestForInspection from "../content/RequestForInspection";
import ShowRfiTable from "../content/Forms/ShowRfiTable";

const MasterTask = React.lazy(() => import("../content/MasterTask"));
const MasterActivitiesCreate = React.lazy(() =>
  import("../content/MasterActivitiesCreate")
);
const MasterActivitiesShow = React.lazy(() =>
  import("../content/MasterActivitiesShow")
);
const TaskCreate = React.lazy(() => import("../content/TaskCreate"));
const TaskShow = React.lazy(() => import("../content/TaskShow"));
const ActivityCreate = React.lazy(() => import("../content/ActivityCreate"));
const ActivityShow = React.lazy(() => import("../content/ActivityShow"));
const AssignTask = React.lazy(() => import("../content/AssignTask"));
const ChecklistReport = React.lazy(() => import("../content/CheckListReport"));

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNav() {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("1");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
        >
          <div className="logo">
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ paddingTop: 18 }}
                className="trigger"
                onClick={toggle}
              />
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src= "cognisie_logo_new.png"
                alt={logindetails.organisation.orgNam}
              />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
          >
            {/* <Link to="/"> */}
            <Menu.Item
              key="1"
              icon={
                <img src="Dashboard.png" className="icon_img" alt="dashboard" />
              }
              style={{ textAlign: "center" }}
            >
              <div>Dashboard</div>
            </Menu.Item>
            {/* </Link> */}

            {/* <SubMenu key="sub1" icon={<img src="Site_incharge.png" className='icon_img' alt='siteincharge' />} title="Master Task">
                            <Menu.Item key="2">Master Task Create</Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub2" icon={<img src="master_Activity.png" className='icon_img' alt='masteractivity' />} title="Master Activities">
                            <Menu.Item key="3">Master Activities Create</Menu.Item>
                            <Menu.Item key="4">Master Activities Show</Menu.Item>
                        </SubMenu> */}

            <SubMenu
              key="sub3"
              icon={
                <img src="task_create.png" className="icon_img" alt="task" />
              }
              title="Task"
            >
              <Menu.Item key="5">Task Create</Menu.Item>
              <Menu.Item key="6">Task Show</Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub4"
              icon={
                <img
                  src="activity_create.png"
                  className="icon_img"
                  alt="activity"
                />
              }
              title="Activity"
            >
              <Menu.Item key="7">Activity Create</Menu.Item>
              <Menu.Item key="8">Activity Show</Menu.Item>
            </SubMenu>
            {/* <SubMenu
              key="sub7"
              icon={
                <img
                  src="activity_create.png"
                  className="icon_img"
                  alt="activity"
                />
              }
              title="RFI"
            >
              <Menu.Item key="12">Request Generate</Menu.Item>
              <Menu.Item key="13">Show Request</Menu.Item>
            </SubMenu> */}

            {/* <SubMenu key="sub5" icon={<img src="assignment_Add.png" className='icon_img' alt='assignment'  />} title="Assign Task">
                            <Menu.Item key="9">Assign Task</Menu.Item>
                        </SubMenu> */}

            {/* <SubMenu key="sub6" icon={<LineChartOutlined />} title="Reports">
                            <Menu.Item key="10">Checklist</Menu.Item>
                        </SubMenu> */}
            <SubMenu key="sub6" icon={<LineChartOutlined />} title="Check List Reports">
              <Menu.Item key="11">Checklist</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt="logo"
                />
              )}
            </div>
          </MediaQuery>
          {/* <TopNavbar name="QCC" collapsed={!collapsed} toggle={toggle} /> */}

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {content === "1" ? <Dashboard /> : <></>}
            {content === "2" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <MasterTask />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "3" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <MasterActivitiesCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "4" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <MasterActivitiesShow />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "5" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <TaskCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "6" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <TaskShow />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "7" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ActivityCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "8" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ActivityShow />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "9" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AssignTask />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "11" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ChecklistReport />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "12" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <RequestForInspection />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "13" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ShowRfiTable />
              </Suspense>
            ) : (
              <></>
            )}

            {/* {content === "10" ? <Checklist /> : <></>} */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default SideNav;
