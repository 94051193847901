import React, { useEffect, useState } from 'react'
import {
   Form,
   Input,
   Button,
   Upload,
   Modal,
   InputNumber,
   Space,
   message,
   DatePicker,
   Card,
   Select,
 } from "antd";
import AddCreateIntendForm from './AddCreateIntendForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectorAllWorkOrderDetails, selectorAllWorkOrderDocuments } from '../../../../redux-core/contracts/selectors';
import { selectorCountIndentNumber, selectorGetPurchaseOrder, selectorNewIndentMaterial } from '../../../../redux-core/materials/selectors';
import { getAllWorkOrders, getBOQSheetNames } from '../../../../redux-core/contracts/actions';
import { countIndentNumberForm, getAllPurchaseOrders, getCurrentStockProjectIdWise, newIndentMaterial } from '../../../../redux-core/materials/actions';
import { makeSelectLoginDetail } from '../../../../redux-core/login/selectors';
import moment from 'moment';
import { getAllProj } from '../../../../redux-core/settings/actions';
import { makeSelectAllProj } from '../../../../redux-core/settings/selectors';

const actionDispatch= (dispatch) =>({
  getBOQSheetNames: (workorderId) => dispatch(getBOQSheetNames(workorderId)),
  getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
  getCurrentStockProjectIdWise: (projectID) => dispatch(getCurrentStockProjectIdWise(projectID)),
  createIndentMaterial:(data) => dispatch(newIndentMaterial(data)),
  getAllPurchaseOrders: (projectID) =>
    dispatch(getAllPurchaseOrders(projectID)),
    countIndentNumberForm:(projectId,orgId)=>dispatch(countIndentNumberForm(projectId,orgId)),
    projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
})

const CreateIndentForm = (props) => {
  const { getAllWorkOrders, getBOQSheetNames, createIndentMaterial, getAllPurchaseOrders,countIndentNumberForm,projects} = actionDispatch(useDispatch());
   const [form] = Form.useForm();
   const workOrderDetails = useSelector(selectorAllWorkOrderDetails);
   // States //
   const [showForm, setShowForm] = useState(false);
   const [storeIndent, setStoreIndent] = useState();
   const [sheetName,setSheetName]=useState();
   const [selectPurchaseDate, setselectPurchaseDate] = useState();
   const [sendResponse,setSendResponse]=useState()
   const [isDiable, setIsDisable] = useState(false);
   const[ openPurchaseDate, setOpenPurchaseDate] = useState(false)
   // selectors //
   const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
const createIndent = useSelector (selectorNewIndentMaterial)
   const logindetails = useSelector(makeSelectLoginDetail);
   const orgId = logindetails.organisation.orgID;
   const userId = logindetails.user.id;
   const countIndentNumber=useSelector(selectorCountIndentNumber)
   const allProjectsSelectorData = useSelector(makeSelectAllProj);

   //console.log("allProjectsSelectorData",allProjectsSelectorData)
   console.log("countIndentNumber",countIndentNumber?.[0]?.no_of_Indent)

   const findProject=allProjectsSelectorData.find((item)=>item.project_id==props.projectID)
   //console.log("findProject",findProject?.project_name)

   const projectName=findProject?.project_name.substring(0, 5).toUpperCase();

   let count_number =
   countIndentNumber?.[0]?.no_of_Indent === 0
     ? "001"
     : (Number(countIndentNumber?.[0]?.no_of_Indent) + 1).toString().padStart(3, '0');
        console.log("count_number",count_number)

   const indent_code="SM/"+projectName+"/"+"INDENT/"+count_number
   console.log("indent_code",indent_code)


   //console.log("workOrderDetails",workOrderDetails.project)
   //console.log("allPurchaseOrder",getPurchaseOrder)

   useEffect(()=>{
    getAllWorkOrders(props.projectID)
    getCurrentStockProjectIdWise(props.projectID)
    countIndentNumberForm(props.projectID,orgId)
    projects(orgId,userId)

    form.setFieldsValue({
      last_purchase_order_date:moment(selectPurchaseDate?.po_date),
      indent_no:indent_code
    });
   },[props.projectID, selectPurchaseDate,indent_code])

const children = [];
for (let i=0; i< workOrderDetails.project.length; i++){
  children.push(
    <option key={workOrderDetails.project[i].id} value={workOrderDetails.project[i].work_order_number}>
{workOrderDetails.project[i].work_order_number}
    </option>
  )
}



const onAddIndentFinish = async (value) => {
let data = {
  ...value,
   project_id: props.projectID,
  orgID: orgId,
  created_by: userId,
}
let response = await createIndentMaterial(data)
if(response){
  setShowForm(true)
  setIsDisable(true)
   setSendResponse(response?.materials)
}
   setStoreIndent(value)
  //  //console.log("value", value)
   
}

const onAddIndentFinishFailed = (error) => {
  //console.log("failed", error)
}

const clear_Indent_field = () =>{
  setShowForm(false)
  setIsDisable(false)
  setOpenPurchaseDate(false)
  countIndentNumberForm(props.projectID,orgId)
  form.setFieldsValue({
   
    indent_date: "",
    work_order_no: "",
    last_purchase_order_no: "",
    last_purchase_order_date: "",

  })
}

   const backToAllProject=()=>{
      props.backProject()
    }

    const handelSelectWorkOrder = (values) =>{
//console.log("values", values)
setSheetName(values)
 getBOQSheetNames(values)
    }

   

const handelPurchaseDate = (value) =>{
const purchaseDate = getPurchaseOrder?.materials.find((items) => items.po_number == value)
setselectPurchaseDate(purchaseDate)
setOpenPurchaseDate(true)
}

  return (
   <>
     <Button 
      onClick={() => backToAllProject()}
      >All Projects</Button>
        <br /> <br />
     <div className="title-show-project">Indent of Material</div> <br />
<Card
hoverable
>
   <Form
     form={form}
     name="add-project"
     size="large"
     initialValues={{
       remember: true,
     }}
     // onFocus={focusHandler}
     onFinish={onAddIndentFinish}
     onFinishFailed={onAddIndentFinishFailed}
   >
     <Form.Item
       name="indent_no"
       label="Indent No"
       className="input-field"
       rules={[
         {
           required: true,
           message: "Please enter Indent No!",
         },
       ]}
       initialValue={indent_code}
     >
       <Input placeholder="Indent No" disabled  />
     </Form.Item>
     <Form.Item
          name="indent_date"
          label="Date"
          className="firstRow"
          rules={[
            {
              required: true,
              message: "Please select Date !",
            },
          ]}
          
        >
          <DatePicker
            format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            // onChange={handleStartDateChange}
            disabled={isDiable}
          />
        </Form.Item>

        <Form.Item
  name="work_order_no"
  label="Work Order No"
  className="input-field"
  rules={[
    {
      required: true,
      message: "Please enter Work Order No !",
    },
  ]}
   
>
  <Select
    // placeholder="Select Work Order No"
    allowClear
    style={{ width: "100%" }}
     onChange={(work_order_number) => handelSelectWorkOrder(work_order_number)}
     disabled={isDiable}
  >
    {/* Map over the data to generate options */}
    {/* {workOrderDetails?.project.map((item) => (
      <option key={item.id} value={item.work_order_number}>
        {item.work_order_number}
      </option>
    ))} */}
    {children}
  </Select>
</Form.Item>


        <Form.Item
          name="last_purchase_order_no"
          label="Last Purchase Order No"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter Last Purchase Order No !",
            },
          ]}
        >
       <Select
    // placeholder="Select Work Order No"
    allowClear
    style={{ width: "100%" }}
    onChange={(po_number)=>handelPurchaseDate(po_number)}
    disabled={isDiable}
  >
    {/* Map over the data to generate options */}
    {getPurchaseOrder?.materials.map((items) => (
      <option key={items.id} value={items.po_number}>
        {items.po_number}
      </option>
    ))}
  </Select>
        </Form.Item>
    {openPurchaseDate &&(
        <Form.Item
          name="last_purchase_order_date"
          label="Last Purchase Order Date"
          className="firstRow"
          rules={[
            {
              required: false,
              message: "Please select Date !",
            },
          ]}
          initialValue={moment(selectPurchaseDate?.po_date)}
          
        >
        
          <DatePicker
            format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            // onChange={handleStartDateChange}
            style={{width:"250%"}}
            disabled={isDiable}
          />
        </Form.Item>
        )}

     <Form.Item>
       <div className="btn-project">
         <Button type="primary" htmlType="submit" className="btn-submit" disabled={isDiable}>
           <strong>Submit</strong>
         </Button>

         <Button 
         onClick={clear_Indent_field} 
         className="btn-cancel">
           <strong>Reset</strong>
         </Button>
       </div>
     </Form.Item>
   </Form>
   </Card>
   <div>
        {" "}
        {showForm ? (
          <AddCreateIntendForm
           data={storeIndent}
           projectId={props.projectID}
           sendResponse={sendResponse}
          />
        ) : (
          ""
        )}
      </div>
 </>
  )
}

export default CreateIndentForm