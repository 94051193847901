import "./css/ShowProjectDetails.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Input,
  notification,
  Image,
  Modal,
  Upload,
  Select,
  Form,
  message,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import {
  countAction,
  deleteProjDetailsById,
  getAllProjDetails,
  updateProjDetailsById,
} from "../../../redux-core/settings/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  delProjDetailsById: (id) => dispatch(deleteProjDetailsById(id)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
  updateProjDetails: (newProjDetails) =>
    dispatch(updateProjDetailsById(newProjDetails)),
  count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const ShowProjectDetails = (props) => {
  const getColumnSearchProps = useSearch();
  const [projRow, setProjRow] = useState({});
  const [selectedKeys, setSelectedKeys] = useState();
  const [imagecard, setImagecard] = useState({
    image_1: false,
    // image_2: false,
    // image_3: false,
    // image_4: false,
    // image_5: false,
  });
  const [img1, setImg1] = useState(false);
  // const [img2, setImg2] = useState(false);
  // const [img3, setImg3] = useState(false);
  // const [img4, setImg4] = useState(false);
  // const [img5, setImg5] = useState(false);
  const [activedata, setActivedata] = useState(true);

  const { delProjDetailsById, getAllProjDetails, updateProjDetails, count } =
    actionDispatch(useDispatch());
  const [formProjectDetails] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const children = [];
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const allProjDetailsSelectorData = useSelector(makeSelectGetAllProjDetails);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const Spindata = useSelector((item) => item.spinreducer);

  useEffect(() => {
    getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, [props.projectID, logindetails.organisation.orgID, logindetails.user.id]);

  const onProjectDetailFinish = async (values) => {
    projRow.project_id = values.project_id;
    projRow.description = values.block;
    projRow.orgID = logindetails.organisation.orgID;

    if (values.image_1 && values.image_1.length < 1) delete projRow.image_1;
    else
      projRow.image_1 = values.image_1[0].size
        ? values.image_1
        : values.image_1[0].thumbUrl;
    // if (values.image_2 && values.image_2.length < 1) delete projRow.image_2;
    // else
    //   projRow.image_2 = values.image_2[0].size
    //     ? values.image_2
    //     : values.image_2[0].thumbUrl;
    // if (values.image_3 && values.image_3.length < 1) delete projRow.image_3;
    // else
    //   projRow.image_3 = values.image_3[0].size
    //     ? values.image_3
    //     : values.image_3[0].thumbUrl;
    // if (values.image_4 && values.image_4.length < 1) delete projRow.image_4;
    // else
    //   projRow.image_4 = values.image_4[0].size
    //     ? values.image_4
    //     : values.image_4[0].thumbUrl;
    // if (values.image_5 && values.image_5.length < 1) delete projRow.image_5;
    // else
    //   projRow.image_5 = values.image_5[0].size
    //     ? values.image_5
    //     : values.image_5[0].thumbUrl;
    //     if (values.image_1 === undefined) values.image_1 = projRow.image_1;
    //     else projRow.image_1 = values.image_1;
    // if (values.image_2 === undefined) values.image_2 = projRow.image_2;
    // else projRow.image_2 = values.image_2;
    // if (values.image_3 === undefined) values.image_3 = projRow.image_3;
    // else projRow.image_3 = values.image_3;
    // if (values.image_4 === undefined) values.image_4 = projRow.image_4;
    // else projRow.image_4 = values.image_4;
    // if (values.image_5 === undefined) values.image_5 = projRow.image_5;
    // else projRow.image_5 = values.image_5;
    await setProjRow(projRow);
    await updateProjDetails(projRow);
    await setIsModalVisible(false);
    await getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  };

  const onProjectDetailFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_ProjectDetails_field = async () => {
    formProjectDetails.setFieldsValue({
      project_id: projRow.project_id,
      block: projRow.description,
      image_1: projRow.image.image_1
        ? [{ thumbUrl: projRow.image.image_1 }]
        : [],
      // image_2: projRow.image.image_2
      //   ? [{ thumbUrl: projRow.image.image_2 }]
      //   : [],
      // image_3: projRow.image.image_3
      //   ? [{ thumbUrl: projRow.image.image_3 }]
      //   : [],
      // image_4: projRow.image.image_4
      //   ? [{ thumbUrl: projRow.image.image_4 }]
      //   : [],
      // image_5: projRow.image.image_5
      //   ? [{ thumbUrl: projRow.image.image_5 }]
      //   : [],
    });
    {
      projRow.image.image_1 == null ||
      projRow.image.image_1 == undefined ||
      projRow.image.image_1 == ""
        ? setImagecard((prevstate) => ({ ...prevstate, image_1: false }))
        : setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    }
    // {
    //   projRow.image.image_2 == null ||
    //   projRow.image.image_2 == undefined ||
    //   projRow.image.image_2 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_2: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
    // }
    // {
    //   projRow.image.image_3 == null ||
    //   projRow.image.image_3 == undefined ||
    //   projRow.image.image_3 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_3: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
    // }
    // {
    //   projRow.image.image_4 == null ||
    //   projRow.image.image_4 == undefined ||
    //   projRow.image.image_4 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_4: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
    // }
    // {
    //   projRow.image.image_5 == null ||
    //   projRow.image.image_5 == undefined ||
    //   projRow.image.image_5 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_5: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
    // }
  };

  const normFileForImg1 = (e) => {
    if (e.fileList.length > 0)
      setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    else setImagecard((prevstate) => ({ ...prevstate, image_1: false }));
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  // const normFileForImg2 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_2: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg3 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_3: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg4 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_4: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg5 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_5: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  const showModal = (data) => {
    setProjRow(data);
    setIsModalVisible(true);
    formProjectDetails.setFieldsValue({
      project_id: data.project_id,
      block: data.description,
      image_1: data.image.image_1 ? [{ thumbUrl: data.image.image_1 }] : [],
      // image_2: data.image.image_2 ? [{ thumbUrl: data.image.image_2 }] : [],
      // image_3: data.image.image_3 ? [{ thumbUrl: data.image.image_3 }] : [],
      // image_4: data.image.image_4 ? [{ thumbUrl: data.image.image_4 }] : [],
      // image_5: data.image.image_5 ? [{ thumbUrl: data.image.image_5 }] : [],
    });
    {
      data.image.image_1 == null ||
      data.image.image_1 == undefined ||
      data.image.image_1 == ""
        ? setImagecard((prevstate) => ({ ...prevstate, image_1: false }))
        : setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    }
    // {
    //   data.image.image_2 == null ||
    //   data.image.image_2 == undefined ||
    //   data.image.image_2 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_2: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
    // }
    // {
    //   data.image.image_3 == null ||
    //   data.image.image_3 == undefined ||
    //   data.image.image_3 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_3: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
    // }
    // {
    //   data.image.image_4 == null ||
    //   data.image.image_4 == undefined ||
    //   data.image.image_4 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_4: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
    // }
    // {
    //   data.image.image_5 == null ||
    //   data.image.image_5 == undefined ||
    //   data.image.image_5 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_5: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
    // }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
      width: "40%",
    },
    // {
    //   title: "Project Details ID",
    //   dataIndex: "project_details_id",
    //   ...getColumnSearchProps("project_details_id"),
    //   width:"10%",
    // },
    // {
    //   title: "Project ID",
    //   dataIndex: "project_id",
    //   ...getColumnSearchProps("project_id"),
    //   width:"10%",
    // },
    // {
    //   title: "Project Name",
    //   dataIndex: "project_name",
    //   ...getColumnSearchProps("project_name"),
    //   width: "30%",
    // },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      width: "50%",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => {
        return (
          <>
            <Image
              src={image.image_1}
              style={{ width: "20px", height: "20px" }}
            />
            {/* <Image
              src={image.image_2}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_3}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_4}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_5}
              style={{ width: "20px", height: "20px" }}
            /> */}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  
  };

  const deleteRow = (record) => {
    ShowDeleteConfirm({
      onConfirm: () => onConfirm([record.project_details_id]),
    });
  };
  const onConfirm = async (key) => {
    await delProjDetailsById(key);
    await getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
    await count(logindetails.organisation.orgID);
  };
  const deleteSelected = () => {
    if (selectedKeys === undefined)
      notification.open({
        message: "Select items to delete.",
      });
    else if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  for (let i = 0; i < allProjectsSelectorData.length; i++) {
    children.push(
      <Option value={allProjectsSelectorData[i].project_id} key={i}>
        {allProjectsSelectorData[i].project_name}-
        {allProjectsSelectorData[i].project_id}
      </Option>
    );
  }

  const data = [];
  for (let i = 0; i < allProjDetailsSelectorData.length; i++) {
    data.push({
      sl_no: i + 1,
      // key: allProjDetailsSelectorData[i].project_details_id,
      project_id: allProjDetailsSelectorData[i].project_id,
      project_details_id: allProjDetailsSelectorData[i].project_details_id,
      project_name: allProjDetailsSelectorData[i].project_name,
      // project_name_Id: allProjDetailsSelectorData[i].project_name + "-" + allProjDetailsSelectorData[i].project_id,
      description: allProjDetailsSelectorData[i].block,
      image: {
        image_1: allProjDetailsSelectorData[i].image_1,
        // image_2: allProjDetailsSelectorData[i].image_2,
        // image_3: allProjDetailsSelectorData[i].image_3,
        // image_4: allProjDetailsSelectorData[i].image_4,
        // image_5: allProjDetailsSelectorData[i].image_5,
      },
    });
  }
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancelmodal = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Of Project Location</div>
        <br />
        <div className="btn__">
          {/* <Button className="pdf"> Export to PDF</Button> */}
          <Button className="excel">
            <CSVLink
              filename={"Project_Details_Table.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          {/* <Button type="primary" 
            style={{backgroundColor:"teal", borderRadius:"5px"}}
            onClick={() => window.print()}>
              {" "}
              <PrinterOutlined />
              Print
            </Button> */}
          {/* <Button className="print">Print</Button>
                <Button className="copy">Copy</Button> */}
        </div>
        <Table
          className="table"
          scroll={{ x: "20vw" }}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={10}
          rowKey="project_details_id"
        />
        <br />
        {/* onOk={handleOk} */}
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          width={700}
          centered={true}
        >
          <div className="edit-project__details">Edit Project Location</div>
          <br />
          <Form
            form={formProjectDetails}
            name="project-details"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onProjectDetailFinish}
            onFinishFailed={onProjectDetailFinishFailed}
            //autoComplete="off"
          >
            <Form.Item
              name="project_id"
              label="Select Project"
              className="input-field"
              rules={[{ required: true, message: "Please select one project" }]}
            >
              <Select placeholder="Select Project" allowClear>
                {children}
              </Select>
            </Form.Item>

            <Form.Item
              className="input-field-showLevel1"
              label="Description"
              name="block"
              rules={[
                {
                  required: true,
                  message: "Please enter the description",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
            >
              <Input
                placeholder="Ex: Level1:foundation, Level2:Slab"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="image_1"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFileForImg1}
              className="input-field-images"
            >
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
                beforeUpload={(file, fileList) => {
                  return false;
                }}
              >
                {!imagecard.image_1 && uploadButton}
              </Upload>
            </Form.Item> 

            {/* <Form.Item
              name="image_2"
              valuePropName="fileList"
              getValueFromEvent={normFileForImg2}
              className="input-field-images"
            >
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {!imagecard.image_2 && uploadButton}
              </Upload>
            </Form.Item> */}

            {/* <Form.Item
              name="image_3"
              valuePropName="fileList"
              getValueFromEvent={normFileForImg3}
              className="input-field-images"
            >
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {!imagecard.image_3 && uploadButton}
              </Upload>
            </Form.Item> */}

            {/* <Form.Item
              name="image_4"
              valuePropName="fileList"
              getValueFromEvent={normFileForImg4}
              className="input-field-images"
            >
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {!imagecard.image_4 && uploadButton}
              </Upload>
            </Form.Item> */}

            {/* <Form.Item
              name="image_5"
              valuePropName="fileList"
              getValueFromEvent={normFileForImg5}
              className="input-field-images"
            >
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                onPreview={handlePreview}
              >
                {!imagecard.image_5 && uploadButton}
              </Upload>
            </Form.Item> */}

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button
                  onClick={clear_ProjectDetails_field}
                  className="btn-cancel"
                >
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        {data.length > 0 && (
          <Button
            type="primary"
            style={{
              display: activedata ? "" : "none",
              borderRadius: "5px",
              backgroundColor: "teal",
            }}
            className="deleteallbutton_copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )}
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ShowProjectDetails);
