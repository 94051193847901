// import { Route, Routes } from "react-router-dom";
import { Layout, Menu, Spin, Tooltip, Space } from "antd";
import {
  MenuUnfoldOutlined,
  // MenuFoldOutlined,
  // DesktopOutlined,
  // UsergroupAddOutlined,
  // FileProtectOutlined,
  // UserOutlined,
  // ArrowLeftOutlined,
  // ArrowRightOutlined,
} from "@ant-design/icons";
// import './SideNav.css';
import "./SideNav.css";
import "antd/dist/antd.min.css";
import TopNavbar from "../topnavbar/TopNavbar";
import MediaQuery from "react-responsive";
import Dashboard from "../contents/Dashboard";
import CreateAdmin from "../contents/createAdmin";
import ShowAdmin from "../contents/showAdmin";
import React, { useCallback, useState, Suspense } from "react";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import SignUpRequest from "../contents/SignUpRequest";

const AddProject = React.lazy(() => import("../contents/AddProject"));
const AddProjectDetails = React.lazy(() =>
  import("../contents/AddProjectDetails")
);
// const ShowProjectDetails = React.lazy(() => import('../contents/ShowProjectDetails'));
const ShowProjects = React.lazy(() => import("../contents/ShowProjects"));
const SiteInChargeCreate = React.lazy(() =>
  import("../contents/SiteInChargeCreate")
);
const SiteInChargeShow = React.lazy(() =>
  import("../contents/SiteInChargeShow")
);
const SupervisorCreate = React.lazy(() =>
  import("../contents/SupervisorCreate")
);
const SupervisorShow = React.lazy(() => import("../contents/SupervisorShow"));
const CreateDrawingUser = React.lazy(() =>
  import("../contents/CreateDrawingUser")
);
const ListDrawingUser = React.lazy(() => import("../contents/ListDrawingUser"));
const UserCreate = React.lazy(() => import("../contents/UserCreate"));
const UsersShow = React.lazy(() => import("../contents/UsersShow"));
const CreateOrganization = React.lazy(() =>
  import("../contents/createOrganization")
);
const ShowOrganization = React.lazy(() =>
  import("../contents/ShowOrganization")
);

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNav() {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  // logindetails.user.role="SuperAdmin"
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("1");
  const selectMenu = useCallback((value) => {
    setContent(value);
  }, []);

  return (
    <>
      <Layout className="  font-inter " style={{ height: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "none",
            // height: '100vh',
            // position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            minWidth: "100px",
          }}
        >
          <div className="logo">
            {collapsed ? (
              <Space>
                <Tooltip title="Maximize">
                  <MenuUnfoldOutlined
                    style={{ paddingTop: 18 }}
                    className="trigger"
                    onClick={toggle}
                  />
                </Tooltip>
                {/* <span style={{fontSize:"10px"}}>Maximize</span> */}
              </Space>
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src="cognisie_logo_new.png"
                alt={logindetails.organisation.orgName}
              />
            )}
            {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                          
                           
                        })} */}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => selectMenu(e.key)}
          >
            <Menu.Item
              key="1"
              icon={
                <img src="Dashboard.png" className="icon_img" alt="dashboard" />
              }
              style={{ textAlign: "center" }}
            >
              <div>Dashboard</div>
            </Menu.Item>
            {logindetails.user.role === "ADMIN" && (
              <>
                <SubMenu
                  key="sub1"
                  icon={
                    <img
                      src="AddProject.png"
                      className="icon_img"
                      alt="addproject"
                    />
                  }
                  title="Project"
                >
                  <Menu.Item key="2">Add Project</Menu.Item>
                  {/* <Menu.Item key="3">Add Project Details</Menu.Item> */}
                  <Menu.Item key="4">Show Project</Menu.Item>
                  {/* <Menu.Item key="19">Types of Projects</Menu.Item> */}
                </SubMenu>

    

                <SubMenu
                  key="sub7"
                  icon={
                    <img
                      src="AddProject.png"
                      className="icon_img"
                      alt="drawinguser"
                    />
                  }
                  title="User"
                >
                  <Menu.Item key="17">Create User</Menu.Item>
                  <Menu.Item key="18">Show User</Menu.Item>
                  {/* <Menu.Item key="18">List Project Directory</Menu.Item> */}
                </SubMenu>
              </>
            )}
            {logindetails.user.role === "SUPERADMIN" && (
              <>
                <SubMenu
                  key="sub5"
                  icon={
                    <img
                      src="create_Organization.png"
                      className="icon_img"
                      alt="create"
                    />
                  }
                  title="Organization"
                >
                  <Menu.Item key="13">Create Organization</Menu.Item>
                  <Menu.Item key="14">Show Organization</Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub6"
                  icon={
                    <img
                      src="create_Admin.png"
                      className="icon_img"
                      alt="createAdmin"
                    />
                  }
                  title="Admin"
                >
                  <Menu.Item key="15">Create Admin</Menu.Item>
                  <Menu.Item key="16">Show Admin</Menu.Item>
                  <Menu.Item key="21">Signup Request</Menu.Item>
                </SubMenu>
              </>
            )}
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn ">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger "
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt={logindetails.organisation.orgName}
                />
              )}
            </div>
          </MediaQuery>
          {/* <TopNavbar name="Settings" collapsed={!collapsed} toggle={toggle} /> */}
          <Content
            className="site-layout-background"
            style={{
              margin: "15px",
              padding: 20,
              minHeight: 100,
              overflow: "scroll",
            }}
          >
            {content === "1" ? <Dashboard /> : <></>}
            {content === "2" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddProject />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "3" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddProjectDetails />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "19" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                {/* <TypesOfProject /> */}
              </Suspense>
            ) : (
              <></>
            )}

            {/* {content === "3" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><ShowProjectDetails /></Suspense> : <></>}    */}
            {content === "4" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ShowProjects />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "5" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <SiteInChargeCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "6" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <SiteInChargeShow />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "7" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <SupervisorCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "8" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <SupervisorShow />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "9" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <CreateDrawingUser />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "10" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListDrawingUser />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "17" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <UserCreate />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "18" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <UsersShow />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "13" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <CreateOrganization />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "14" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ShowOrganization />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "15" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin_component"
                  />
                }
              >
                <CreateAdmin />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "16" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin_component"
                  />
                }
              >
                <ShowAdmin />
              </Suspense>
            ) : (
              <></>
            )}
            {/* {content === "17" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin_component"
                  />
                }
              >
                <CreateUser />
              </Suspense>
            ) : (
              <></>
            )} */}
            {content === "21" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin_component"
                  />
                }
              >
                <SignUpRequest />
              </Suspense>
            ) : (
              <></>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default SideNav;
