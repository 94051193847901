import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  message,
  notification,
} from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import { selectorGetItemInCurrentStockProjectWise } from "../../../../redux-core/materials/selectors";
import useSearch from "../../../../hooks/useSearch";
import {
  updateItemIssueQty,
  getCurrentStockProjectIdWise,
  insertItemIssueData,
  getAllIssueData,
  getCountIssueNumber,
  createIssueNumbers,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import ShowIssueReportForm from "./ShowIssueReportForm";
import { selectorCountIssueNumber } from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";
import moment from "moment";
import { materialsApi, settingsapi } from "../../../../cognisite-api";
import { getAllWorkOrders } from "../../../../redux-core/contracts/actions";
import { selectorAllWorkOrderDetails } from "../../../../redux-core/contracts/selectors";

const { showProjectByProjId } = settingsapi;
const { getIssueNumberCount } = materialsApi;

const actionDispatch = (dispatch) => ({
  updateItemIssueQty: (issueItem) => dispatch(updateItemIssueQty(issueItem)),
  getCurrentStockProjectIdWise: (projId) =>
    dispatch(getCurrentStockProjectIdWise(projId)),
  getAllIssueData: (projId) => dispatch(getAllIssueData(projId)),
  getCountIssueNumber: (projId) => dispatch(getCountIssueNumber(projId)),
  getProjectByprojectId: (projId) => dispatch(getProjectByprojectId(projId)),
  insertItemIssueData: (issueData) => dispatch(insertItemIssueData(issueData)),
  createIssueNumbers: (issueData) => dispatch(createIssueNumbers(issueData)),
  getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
});
function MaterialIssueFrom(props) {
  
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );

  const {
    updateItemIssueQty,
    getCurrentStockProjectIdWise,
    insertItemIssueData,
    getAllIssueData,
    getCountIssueNumber,
    createIssueNumbers,
    getAllWorkOrders,
  } = actionDispatch(useDispatch());
  const [array, setArray] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [issueDate, setIssueDate] = useState();
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueNumberCreateModal, setIssueNumberCreateModal] = useState(false);
  const [issueNumberCreateDate, setIssueNumberCreateDate] = useState();
  const countIssueNumber = useSelector(selectorCountIssueNumber);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const [issueNumber, setIssueNumber] = useState();
  const [agencyName, setAgencyName] = useState();
  const [recieverName, setRecieverName] = useState();
  const [passIssueDate, setPassIssueDate] = useState();
  const [projectCode, setProjectCode] = useState([]);
  const [countsIssueNumber, setCountsIssueNumber] = useState([]);
  const workOrderDetails = useSelector(selectorAllWorkOrderDetails);
  const [storeWorkOrderNos,setWorkOrderNos]=useState()
  const [workOrderid,setWorkOrderId]=useState(null)
  console.log("passIssueDate",passIssueDate)

  useEffect(() => {
    getCountIssueNumber(props.projectID);
    getProjectByprojectId(props.projectID);

    getProjectCode(props.projectID);
    getNoOfIssue(props.projectID);
    getAllWorkOrders(props.projectID);
  }, [props.projectID]);

  // fetch api for Getting Project code
  const getProjectCode = async (projectID) => {
    const responce = await showProjectByProjId(projectID);

    if (responce.status === 200) {
      const result = responce.project.map((item) => item.project_code);
      setProjectCode(result);
    } else {
      setProjectCode([]);
    }
  };

  const getNoOfIssue = async (projectID) => {
    const Response = await getIssueNumberCount(projectID);

    if (Response.status === 200) {
      const Results = Response.materials.map((items) => items.no_of_Issue_no);
      setCountsIssueNumber(Results);
    } else {
      setCountsIssueNumber([]);
    }
  };

  const tableData = [];
  for (let i = 0; i < getShowItemInCurrentStockProjectWise.length; i++) {
    tableData.push({
      sl: i + 1,
      id: getShowItemInCurrentStockProjectWise[i].id,
      item_code: getShowItemInCurrentStockProjectWise[i].item_code,
      Item_name: getShowItemInCurrentStockProjectWise[i].Item_name,
      issue_qty: getShowItemInCurrentStockProjectWise[i].issue_qty,
      total_qty: getShowItemInCurrentStockProjectWise[i].total_qty,
      item_rate: getShowItemInCurrentStockProjectWise[i].item_rate,
      Issue_qty_value: getShowItemInCurrentStockProjectWise[i].Issue_qty_value,
    });
  }
  const handleIssueDate = (value, dateString) => {
    setIssueDate(dateString);
  };
  useEffect(() => {
    form.setFieldsValue({
      issue_number: issueNumber,
      issued_agency_name: agencyName,
      reciever_name: recieverName,
      issue_date: moment(passIssueDate,"DD-MM-YYYY"),
    });
  }, [issueNumber, agencyName, recieverName, passIssueDate]);
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "sl",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
      width: 150,
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
      ...getColumnSearchProps("Item_name"),
      width: 380,
    },
    {
      title: "Current Stock",
      dataIndex: "total_qty",
      width: 150,
    },
    {
      title: "Issue Number",
      dataIndex: "issue_number",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`issue_number`} initialValue={issueNumber}>
              <Input
                style={{ width: 200 }}
                placeholder="Issue Number"
                disabled
              />
            </Form.Item>
          );
        }
      },
      width: 150,
    },
    {
      title: "Issue Qty",
      dataIndex: "issue_qty",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
             name={`issue_qty`}
             rules={[
              
              {
                validator: (_, value) => {
                  // Convert value to a number
                  const issueQty = parseInt(value, 10);
                  const totalQty = record.total_qty;
  
                  if (isNaN(issueQty) || issueQty < 0) {
                    return Promise.reject("Issue Qty must be a positive number.");
                  }
  
                  if (issueQty > totalQty) {
                    return Promise.reject("Issue Qty cannot exceed Total Qty.");
                  }
  
                  return Promise.resolve();
                },
              },
            ]}
             >
              <Input style={{ width: 100 }} placeholder="Issue Qty" />
            </Form.Item>
          );
        }
      },
      width: 150,
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name="issue_date" initialValue={moment(passIssueDate,"DD-MM-YYYY")}>
              <DatePicker
                name="issue_date"
                format="DD-MM-YYYY"
                // defaultValue={moment()}
                onChange={handleIssueDate}
                placeholder="Enter Date"
                style={{ width: 150 }}
                disabled
              />
            </Form.Item>
          );
        }
      },
      width: 200,
    },

    {
      title: "Item issued to (Agency Name)",
      dataIndex: "issued_agency_name",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`issued_agency_name`} initialValue={agencyName}>
              <Input
                style={{ width: 200 }}
                placeholder="Item issued to (Agency Name) "
                disabled
              />
            </Form.Item>
          );
        }
      },
    },
    {
      title: "Receiver  Name",
      dataIndex: "reciever_name",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`reciever_name`} initialValue={recieverName}>
              <Input
                style={{ width: 200 }}
                placeholder="Enter Reciever Name "
                disabled
              />
            </Form.Item>
          );
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              onClick={() => {
                setEditingRow(record.id);
                form.resetFields();
              }}
            >
              Edit
            </Button>
            <Button
              htmlType="submit"
              onClick={() => {
                setEditingRow();
                form.submit();
                onFinish(form.getFieldsValue(), record);
              }}
            >
              Save
            </Button>
          </div>
        );
      },
    },
  ];

  const onFinish = async (value, record) => {

    const work_order_number=workOrderDetails?.project?.find((item)=>item.work_order_id==workOrderid)
    
    const Value = Number(record.item_rate) / Number(record.total_qty);

    const issueQtyValue = Number(Value) * Number(value.issue_qty);

    const itemValue = Number(record.item_rate) - Number(issueQtyValue);

    const DataIssue = {
      id: record.id,
      issue_qty: Number(record.issue_qty) + Number(value.issue_qty),
      total_qty: Number(record.total_qty) - Number(value.issue_qty),
      item_rate: itemValue,
      Issue_qty_value: Number(record.Issue_qty_value) + Number(issueQtyValue),
    };

    const issueData = {
      project_id: props.projectID,
      item_id: record.id,
      issue_date: value.issue_date,
      issue_qty: value.issue_qty,
      issued_agency_name: value.issued_agency_name,
      reciever_name: value.reciever_name,
      issue_number: value.issue_number,
      orgID: orgId,
      created_by: userId,
      work_order_id:work_order_number.work_order_number
    };

    if (issueData.issue_number === undefined) {
      notification.error({
        message: "New Issue Number Create first",

        onClick: () => {},
      });
    } else {
      const Response = await insertItemIssueData(issueData);
      if (Response) {
        if (Response.success === true) {
          await updateItemIssueQty(DataIssue);
        }
        getCurrentStockProjectIdWise(props.projectID);
      }
    }
  };

  // open issue report modal
  const HandelShowIssueModal = () => {
    setIsModalOpen(true);
    getAllIssueData(props.projectID);
  };

  const ModalClose = () => {
    setIsModalOpen(false);
    setIssueNumberCreateModal(false);
  };

  const openIssueModal = () => {
    setIssueNumberCreateModal(true);
  };

  const handleIssuesDate = (value, dateString) => {
    setIssueNumberCreateDate(dateString);
  };

  const HandelNewIssueNumberSubmit = async (value) => {
    ModalClose();
    const data = {
      issue_numbers: value.issue_numbers,
      issues_date: value.issues_date,
      agency_name: value.agency_name,
      work_order_number: value.work_order_number,
      reciever: value.reciever,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };

    const response = await createIssueNumbers(data);

    if (response) {
      if (response.success === true) {
        setIssueNumber(response.materials.issue_numbers);
        setAgencyName(response.materials.agency_name);
        setRecieverName(response.materials.reciever);
        setWorkOrderId(response.materials.work_order_number);
        setPassIssueDate(moment(response.materials.issues_date).format("DD-MM-YYYY"));
        getCountIssueNumber(props.projectID);
      }
      form2.setFieldsValue({
        issues_date:"",
        agency_name:"",
        work_order_number:"",
        reciever:""
      })
      getCountIssueNumber(props.projectID)
    }
  };

  let count_number =
    countsIssueNumber[0] === 0 ? 1 : Number(countsIssueNumber[0]) + 1;

  const ISSUE_NUMBER = "ISP" + "/" + projectCode[0] + "/" + count_number;

  useEffect(() => {
    form2.setFieldsValue({
      issue_numbers: ISSUE_NUMBER,
    });
  }, [ISSUE_NUMBER]);

const backToAllProject=()=>{
  props.backProject()
}

const workOrderNo=[]
const HandelSelectContarctor=(value)=>{
  console.log("value",value)
  const workOrderNo=workOrderDetails?.project?.filter((item)=>item?.contractor_name===value)
console.log("workOrderNo",workOrderNo)
setWorkOrderNos(workOrderNo)
}

  return (
    <>
      <div className="btn__">
        <Button className="button-61" onClick={HandelShowIssueModal}>
          View Issue Report
        </Button>
        <Button
          className="button-61"
          style={{ float: "right" }}
          onClick={openIssueModal}
        >
          Create Issue Number
        </Button>
      </div>
      <Button onClick={() => backToAllProject()}>All Projects</Button> <br /> <br />
      <div>
        <Form form={form}>
          <Table
            scroll={{ x: "100vw" }}
            columns={columns} //dataSource={tableData}
            dataSource={Array.isArray(array) ? array : tableData}
            pagination={10}
          />
        </Form>
      </div>
      <Modal
        visible={isModalOpen}
        width={2000}
        footer={null}
        onCancel={ModalClose}
      >
        <ShowIssueReportForm />
      </Modal>
      <Modal
        visible={issueNumberCreateModal}
        footer={null}
        onCancel={ModalClose}
        width={1200}
      >
        <Form
          form={form2}
          name="create_Issue_number"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={HandelNewIssueNumberSubmit}
        >
          <Form.Item
            name="issue_numbers"
            label="Issue Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Issue Number !",
              },
            ]}
            initialValue={ISSUE_NUMBER}
          >
            <Input placeholder="Issue Number" disabled />
          </Form.Item>
          <Form.Item
            name="issues_date"
            label="Issue Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter GRN Date !",
              },
            ]}
          >
            <DatePicker
              name="issues_date"
              format="DD-MM-YYYY"

              onChange={handleIssuesDate}
              placeholder="Enter Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item
            name="agency_name"
            label="Item issued to (Agency Name)"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Item issued to (Agency Name)!",
              },
            ]}
            //initialValue={GRN_NUMBER}
          >
           <Select
           onChange={(contractorName)=>HandelSelectContarctor(contractorName)}
           >
            {workOrderDetails?.project?.map((item)=>(
              <option key={item.contractor_name} value={item.contractor_name}>{item.contractor_name}</option>
            ))}
  
  </Select>
           {/* <Input placeholder="Item issued to (Agency Name)" /> */}
          </Form.Item>
          <Form.Item
          label="Work Order Number"
          name="work_order_number"
          className="input-field"
          >
            <Select>
              {storeWorkOrderNos?.map((item)=>(
                <Select.Option key={item?.work_order_id} value={item?.work_order_id}>
                {item?.work_order_number}
              </Select.Option>
              ))}
            </Select>

          </Form.Item>
          <Form.Item
            name="reciever"
            label="Receiver  Name"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Receiver  Name!",
              },
            ]}
            //initialValue={GRN_NUMBER}
          >
            <Input placeholder="Receiver  Name" />
          </Form.Item>
          <Form.Item>
            <div className="btn-createAdmin">
              <Button type="primary" htmlType="submit" className="btn-submit">
                <strong>Submit</strong>
              </Button>

              {/* <Button onClick={clear_fields} className="btn-cancel"><strong>Reset</strong></Button> */}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default MaterialIssueFrom;
